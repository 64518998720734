import instance from "@/api/business";
import { ApiResponse } from "./useEstimates";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

export type BusinessType = {
  SK_EMPRESA: string | number;
  COD_GRUPO_DE_EMPRESA: string | number;
  DESCRICAO_EMPRESA: string;
  ABREVIATURA_EMPRESA: string;
  CNPJ: string;
  COD_EMPRESA_LEGADO: string | number;
  CREATED_AT: string;
  UPDATED_AT: string;
};

async function fetchBusiness() {
  const response = await instance.post<ApiResponse<BusinessType>>(
    "/planningbeer/business"
  );
  return response.data;
}

export default function useBusiness() {
  return useQuery({
    queryKey: ["business"],
    queryFn: () => fetchBusiness(),
    placeholderData: keepPreviousData,
    gcTime: 1000 * 60 * 60 * 24, //86400000ms 24h
  });
}

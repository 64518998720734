import Can from "@/components/Can";
import Col from "@/components/Col";
import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Separator } from "@/components/ui/separator";
import { TEXT_COLOR } from "@/constants";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import ProgrammedRealProductionTableHeader from "../Header/ProgrammedRealProductionTableHeader";
import ProgrammedRealProductionForm from "../Form/ProgrammedRealProductionForm";
import dayjs from "dayjs";
import { ProgrammedRealProductionFormType } from "../Form/ProgrammedRealProductionForm/zod";
import {
  Products,
  TotalByVersion,
  TotalRealProduction,
  useProgrammedRealProductionMutation,
} from "@/hooks/useProgrammedRealProductionMutation";
import { validationError } from "@/utils/api";
import { useState } from "react";
import { quantityFormater } from "@/utils/number";

type ProgrammedRealProductionModalProps = {
  fkFactory: number;
  fkProductionPlan: number;
};

export default function ProgrammedRealProductionModal({
  fkFactory,
  fkProductionPlan,
}: ProgrammedRealProductionModalProps) {
  const [items, setItems] = useState<Products[]>();
  const [totalByVersion, setTotalByVersion] = useState<TotalByVersion>();
  const [totalRealProduction, setTotalRealProduction] =
    useState<TotalRealProduction>();
  const { mutateAsync, isPending } = useProgrammedRealProductionMutation();

  async function handleProgrammedRealProductionFormSubmit(
    formData: ProgrammedRealProductionFormType
  ) {
    const initialPeriod = dayjs(formData.initialPeriod).format("YYYY-MM-DD");
    const finalPeriod = dayjs(formData.finalPeriod).format("YYYY-MM-DD");

    const body = {
      initialPeriod,
      finalPeriod,
      fkFactory,
      fkProductionPlan,
      fkProductionPlanOld: formData.fkProductionPlanOld,
    };

    try {
      if (isPending) return;
      if (formData.fkProductionPlanOld.length == 0) return;
      const response = await mutateAsync(body);
      const { data } = response || {};
      const { produtos, total_por_versao, total_producao_real } = data;

      setItems(produtos);
      setTotalByVersion(total_por_versao);
      setTotalRealProduction(total_producao_real);
    } catch (error) {
      validationError(error);
    }
  }

  return (
    <Dialog>
      <DialogTrigger>
        <Button variant="outline">
          <AiOutlineFundProjectionScreen color={TEXT_COLOR} size={20} />
          <Text className="mt-1 ml-1">Progamado/Real</Text>
        </Button>
      </DialogTrigger>
      <DialogContent className="flex flex-col max-w-full h-full w-11/12 px-8 py-8">
        <Text className="font-bold text-lg">
          Produção Programada x Produção Real
        </Text>
        <Separator />
        <Row align="center">
          <ProgrammedRealProductionForm
            fkProductionPlan={fkProductionPlan}
            onSubmit={handleProgrammedRealProductionFormSubmit}
          />
        </Row>
        <Separator />
        <Col className="h-full  overflow-y-scroll">
          {items?.map(
            ({ produto, descricao, plano_producao, producao_real }, key) => {
              return (
                <Row className="w-full gap-2" key={key}>
                  <Col className="w-full items-center justify-center">
                    <Can
                      condition={key == 0}
                      onTrue={
                        <ProgrammedRealProductionTableHeader
                          title={
                            <>
                              <Row
                                justify="center"
                                align="center"
                                className="mb-14"
                              >
                                <Text className="mr-1 text-sm">Produtos</Text>
                              </Row>
                            </>
                          }
                          rowTitle={{ left: "Código", right: "Descrição" }}
                        />
                      }
                    />
                    <Col className="w-full border-1 border items-center justify-center">
                      <Text className="text-sm w-full p-3">
                        {produto} - {descricao}
                      </Text>
                    </Col>
                  </Col>
                  {plano_producao.map(
                    (
                      { quantidade, quantidade_hectolitros, versao },
                      productionPlanKey
                    ) => {
                      return (
                        <Col className="w-full h-full" key={productionPlanKey}>
                          <Can
                            condition={key == 0}
                            onTrue={
                              <ProgrammedRealProductionTableHeader
                                title={
                                  <>
                                    <Row justify="center" align="center">
                                      <Text className="mr-1 text-sm">
                                        Versão:{" "}
                                      </Text>
                                      <Text>
                                        <strong>v{versao}</strong>
                                      </Text>
                                    </Row>
                                    <Text className="mt-2">
                                      <strong>Quantidade: </strong>
                                      {quantityFormater(
                                        totalByVersion?.[versao]?.quantidade ||
                                          0
                                      )}
                                    </Text>
                                    <Text className="mt-2">
                                      <strong>Quantidade HL: </strong>
                                      {quantityFormater(
                                        totalByVersion?.[versao]
                                          ?.quantidade_hectolitros || 0
                                      )}
                                    </Text>
                                  </>
                                }
                              />
                            }
                          />
                          <Row
                            className="w-full border-1 border h-full"
                            align="center"
                            justify="center"
                          >
                            <Col className="w-full items-center border-r-2 h-full justify-center">
                              <Text>{quantityFormater(quantidade)}</Text>
                            </Col>
                            <Col className="w-full items-center">
                              <Text>
                                {quantityFormater(quantidade_hectolitros)}
                              </Text>
                            </Col>
                          </Row>
                        </Col>
                      );
                    }
                  )}
                  <Col className="w-full">
                    <Can
                      condition={key == 0}
                      onTrue={
                        <ProgrammedRealProductionTableHeader
                          title={
                            <>
                              <Row justify="center" align="center">
                                <Text className="mr-1 text-sm">
                                  Produção Real
                                </Text>
                              </Row>
                              <Text className="mt-2">
                                <strong>Quantidade: </strong>
                                {quantityFormater(
                                  totalRealProduction?.quantidade || 0
                                )}
                              </Text>
                              <Text className="mt-2">
                                <strong>Quantidade HL: </strong>
                                {quantityFormater(
                                  totalRealProduction?.quantidade_hectolitros ||
                                    0
                                )}
                              </Text>
                            </>
                          }
                        />
                      }
                    />
                    <Row
                      className="w-full border-1 border h-full"
                      align="center"
                      justify="center"
                    >
                      <Col className="w-full items-center border-r-2 h-full justify-center">
                        <Text>
                          {quantityFormater(producao_real.quantidade)}
                        </Text>
                      </Col>
                      <Col className="w-full items-center  h-full justify-center">
                        <Text>
                          {quantityFormater(
                            producao_real.quantidade_hectolitros
                          )}
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            }
          )}
        </Col>
      </DialogContent>
    </Dialog>
  );
}

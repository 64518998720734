import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { resourceFactoryProductsKeys } from "./useResourceFactoryProducts";

export default function useResourceFactoryProductMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: any) => {
      return instance.put("/planningbeer/resourceFactoryProduct", { ...data });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: resourceFactoryProductsKeys.all,
      }),
  });
}

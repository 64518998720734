import { SelectProps } from "@radix-ui/react-select";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import Each from "../Each";
import { STATE_OPTIONS } from "@/constants";

export default function StateSelect(props: SelectProps) {
  return (
    <Select
      onValueChange={props.onValueChange}
      defaultValue={props.defaultValue}
      value={props.value}
    >
      <SelectTrigger>
        <SelectValue placeholder="" />
      </SelectTrigger>
      <SelectContent>
        <Each
          array={STATE_OPTIONS}
          renderItem={({ key }) => <SelectItem value={key}>{key}</SelectItem>}
        />
      </SelectContent>
    </Select>
  );
}

import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Row from "@/components/Row";
import CreatePackagingSupplierDialog from "./components/Dialog/CreatePackagingSupplierDialog";
import { useSuppliers } from "@/hooks/useSuppliers";
import usePackagings from "@/hooks/usePackagings";
import useFactories from "@/hooks/useFactories";
import { usePackagingSuppliers } from "@/hooks/usePackagingSuppliers";
import { ColumnOrderState, createColumnHelper } from "@tanstack/react-table";
import { TableData } from "@/components/NewTable/type";
import Table from "@/components/NewTable";
import { getTableColumnOrder } from "@/utils/table";
import { useState } from "react";
import ActionCell from "@/components/Cell/ActionCell";
import ActionDropdown from "./components/Dropdown/ActionDropdown";
import EditPackagingSupplierDialog from "./components/Dialog/EditPackagingSupplierDialog";
import usePackagingSupplierDeleteMutation from "@/hooks/usePackagingSupplierDeleteMutation";
import { usePackagingSupplierContext } from "./context";
import { DeleteDialogAlert } from "@/components/DialogAlert/DeleteDialogAlert";
import { toast } from "sonner";
import { validationError } from "@/utils/api";

export type PackagingSupplierTableProps = {
  FK_fabrica: number;
  FK_fornecedor: number;
  SK_embalagem_fornecedor: number;
  nome_fornecedor: string;
  codigo_fornecedor: string;
  composicao: string;
  descricao_composicao: string;
  abreviatura_empresa: string;
};

type PackagingSuppliersTableType = TableData<PackagingSupplierTableProps>;

const columnHelper = createColumnHelper<PackagingSuppliersTableType>();
const TABLE_ORDER_KEY = "packagingSuppliersColumnOrder";

export default function PackagingSuppliers() {
  useSuppliers();
  usePackagings();
  useFactories();
  const {
    packagingSupplier: { SK_embalagem_fornecedor },
    dialogType,
    visibleDialog,
    clear,
  } = usePackagingSupplierContext();
  const { mutateAsync, isPending } = usePackagingSupplierDeleteMutation();
  const { data } = usePackagingSuppliers();
  const { data: packagingSuppliers = [] } = data || {};

  const columns = [
    columnHelper.accessor("SK_embalagem_fornecedor", {
      id: "SK_embalagem_fornecedor",
      size: 90,
      header: "ID",
    }),
    columnHelper.accessor("nome_fornecedor", {
      id: "nome_fornecedor",
      size: 300,
      header: "Fornecedor",
      meta: {
        row: {
          isGrouped: true,
        },
      },
    }),
    columnHelper.accessor("codigo_fornecedor", {
      id: "codigo_fornecedor",
      header: "Código do Fornecedor",
      size: 250,
    }),
    columnHelper.accessor("composicao", {
      id: "composicao",
      header: "Código do Produto",
      size: 250,
    }),
    columnHelper.accessor("descricao_composicao", {
      id: "descricao_composicao",
      header: "Descrição do Produto",
      size: 400,
    }),
    columnHelper.accessor("abreviatura_empresa", {
      id: "abreviatura_empresa",
      header: "Filial de Destino",
      size: 200,
    }),
    columnHelper.display({
      header: "Ações",
      id: "Ações",
      cell: ({ row }) => {
        if (row.depth != 0)
          return <ActionCell items={<ActionDropdown {...row.original} />} />;
      },
    }),
  ];

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    getTableColumnOrder({ column: columns, key: TABLE_ORDER_KEY })
  );

  function handleColumnOrder(column: ColumnOrderState) {
    setColumnOrder(column);
    localStorage.setItem(TABLE_ORDER_KEY, JSON.stringify(column));
  }

  async function handleDelete() {
    if (isPending) return;
    try {
      await mutateAsync({ id: Number(SK_embalagem_fornecedor) });
      clear();
      return toast.success("Fornecedor - Embalagem excluída com sucesso!");
    } catch (error) {
      return validationError(error);
    }
  }

  const dataSubRows = packagingSuppliers.map((packagingSupplier) => {
    return {
      nome_fornecedor: packagingSupplier.nome_fornecedor,
      codigo_fornecedor: packagingSupplier.codigo_fornecedor,
      subRows: [
        ...packagingSupplier.embalagens.map((packaging) => {
          return {
            FK_fabrica: packaging.FK_fabrica,
            FK_fornecedor: packaging.FK_fornecedor,
            SK_embalagem_fornecedor: packaging.SK_embalagem_fornecedor,
            nome_fornecedor: packagingSupplier.nome_fornecedor,
            codigo_fornecedor: packagingSupplier.codigo_fornecedor,
            composicao: packaging.composicao,
            descricao_composicao: packaging.descricao_composicao,
            abreviatura_empresa: packaging.abreviatura_empresa,
          };
        }),
      ],
    };
  });

  return (
    <AuthenticatedLayout>
      <Row justify="end">
        <CreatePackagingSupplierDialog />
      </Row>
      <Table<PackagingSuppliersTableType>
        columns={columns}
        data={dataSubRows as unknown as PackagingSuppliersTableType[]}
        onColumnOrderStateChange={handleColumnOrder}
        columnOrderState={columnOrder}
        meta={{
          layout: "stretch",
        }}
      />
      <EditPackagingSupplierDialog />
      <DeleteDialogAlert
        onConfirm={handleDelete}
        visibleDialog={visibleDialog}
        handleVisibleDialog={clear}
        onCancel={clear}
        type={dialogType}
        alertDialogTitle={`Deseja excluir a relação fornecedor/embalagem, "${SK_embalagem_fornecedor}"?. Essa ação é irreversível.`}
      />
    </AuthenticatedLayout>
  );
}

import { ConsumptionType } from "@/hooks/usePackagingSimulationMutation";
import { getConsumption } from "../../utils/consumption";
import { quantityFormater } from "@/utils/number";
import Row from "@/components/Row";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { TEXT_COLOR } from "@/constants";
import Text from "@/components/Text/Text";
import { usePackagingSimulationContext } from "../../context";
import Can from "@/components/Can";

type ConsumptionForecastCellProps = {
  composition: string;
  day: string;
  consumptionForecast: Array<ConsumptionType>;
};

export default function ConsumptionForecastCell({
  composition,
  consumptionForecast,
  day,
}: ConsumptionForecastCellProps) {
  const {
    icons: { visible },
  } = usePackagingSimulationContext();
  const { consumption, isSimulated, productionTotal } = getConsumption({
    composition,
    consumptionForecast,
    day,
  });

  if (consumption == 0) return <span>0</span>;

  if (isSimulated)
    return (
      <Row align="center" justify="space-between" className="w-full">
        <span>{quantityFormater(consumption)}</span>
        <Can
          condition={!visible}
          onTrue={
            <Popover>
              <PopoverTrigger>
                <IoMdInformationCircleOutline
                  size={20}
                  color={TEXT_COLOR}
                  className="font-bold"
                />
              </PopoverTrigger>
              <PopoverContent className="w-fit">
                <Text>
                  Produção simulada: {quantityFormater(productionTotal || 0)}
                </Text>
              </PopoverContent>
            </Popover>
          }
        />
      </Row>
    );

  return <span>{quantityFormater(consumption)}</span>;
}

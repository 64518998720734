import { useMutation, useQueryClient } from "@tanstack/react-query";
import { EstimatePatchMutationType } from "./useEstimatePatchMutation";
import { estimates } from "./useEstimates";
import instance from "@/api/business";

type EstimateRejectDemandPatchType = {
  id: number;
} & EstimatePatchMutationType;

export default function useEstimateRejectDemandPatchMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: EstimateRejectDemandPatchType) => {
      return instance.patch("/planningbeer/forecast/reject", { ...data });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: estimates.all });
    },
  });
}

import Empty from "@/components/Empty";
import {
  SALDO_INICIAL_ST,
  COLETA_ACUMULADA_ST,
  COLETA_PROG_ST,
  COLETA_REAL_ST,
  CONSUMO_ACUMULADO_ST,
  CONSUMO_REAL_ST,
  PREV_CONSUMO_ST,
  SALDO_FINAL_ST,
} from "../../constants";
import UpdateCellValue from "./UpdateCellValue";
import ProgrammedCollectionCell from "./ProgrammedCollectionCell";
import InitialBalanceCell from "./InitialBalanceCell";
import { InventoryType } from "../../types";
import { getPackagingInventory } from "../../utils/inventory";
import FinalBalanceCell from "./FinalBalanceCell";
import ConsumptionForecastCell from "./ConsumptionForecastCell";
import {
  ConsumptionType,
  RealCollectionAccumulatedType,
  RealCollectionType,
  RealConsumptionAccumulatedType,
  RealConsumptionType,
} from "@/hooks/usePackagingSimulationMutation";
import {
  getRealConsumption,
  getRealConsumptionAccumulated,
} from "../../utils/realConsumption";
import RealConsumptionCell from "./RealConsumptionCell";
import RealConsumptionAccumulatedCell from "./RealConsumptionAccumulatedCell";
import RealCollectionCell from "./RealCollectionCell";
import {
  getRealCollection,
  getRealCollectionAccumulated,
} from "../../utils/realCollection";
import RealCollectionAccumulatedCell from "./RealCollectionAccumulatedCell";

type CellProps = {
  isEditable: boolean;
  day: string;
  st: string;
  composition: string;
  inventory: InventoryType;
  cell: {
    packagingSupplierRow: {
      skPackagingSupplier?: number;
    };
    consumptionForecastRow: {
      consumption: Array<ConsumptionType>;
    };
    realConsumptionRow: {
      realConsumption: Array<RealConsumptionType>;
    };
    realConsumptionAccumulatedRow: {
      realConsumptionAccumulated: RealConsumptionAccumulatedType;
    };
    realCollectionRow: {
      realCollection: Array<RealCollectionType>;
    };
    realCollectionAccumulatedRow: {
      realCollectionAccumulated: RealCollectionAccumulatedType;
    };
  };
};

export default function Cell({
  isEditable,
  day,
  composition,
  st,
  inventory,
  cell: {
    packagingSupplierRow: { skPackagingSupplier },
    consumptionForecastRow: { consumption },
    realConsumptionRow: { realConsumption },
    realConsumptionAccumulatedRow: { realConsumptionAccumulated },
    realCollectionRow: { realCollection },
    realCollectionAccumulatedRow: { realCollectionAccumulated },
  },
}: CellProps) {
  const status = {
    isInitialBalance: SALDO_INICIAL_ST == st.toUpperCase(),
    isConsumptionForecast: PREV_CONSUMO_ST == st.toUpperCase(),
    isRealConsumption: CONSUMO_REAL_ST == st.toUpperCase(),
    isAccumulatedConsumption: CONSUMO_ACUMULADO_ST == st.toUpperCase(),
    isProgrammedCollection: COLETA_PROG_ST == st.toUpperCase(),
    isRealCollection: COLETA_REAL_ST == st.toUpperCase(),
    isAccumulatedCollection: COLETA_ACUMULADA_ST == st.toUpperCase(),
    isFinalBalance: SALDO_FINAL_ST == st.toUpperCase(),
  };

  const { atual, restante } = getPackagingInventory({
    composition,
    day,
    inventory,
  });

  const { realConsumptionValue } = getRealConsumption({
    composition,
    day,
    realConsumption,
  });

  const { realConsumptionAccumulatedValue } = getRealConsumptionAccumulated({
    composition,
    day,
    realConsumptionAccumulated,
  });

  const { realCollectionTotal } = getRealCollection({
    composition,
    day,
    realCollection,
  });

  const { realCollectionAccumulatedValue } = getRealCollectionAccumulated({
    composition,
    day,
    realCollectionAccumulated,
  });

  if (status.isInitialBalance) {
    return <InitialBalanceCell value={atual} />;
  }

  if (status.isConsumptionForecast) {
    return (
      <ConsumptionForecastCell
        composition={composition}
        day={day}
        consumptionForecast={consumption}
      />
    );
  }

  if (status.isRealConsumption) {
    return <RealConsumptionCell value={realConsumptionValue} />;
  }

  if (status.isAccumulatedConsumption) {
    return (
      <RealConsumptionAccumulatedCell value={realConsumptionAccumulatedValue} />
    );
  }

  if (isEditable && skPackagingSupplier)
    return (
      <UpdateCellValue
        skPackagingSupplier={skPackagingSupplier}
        day={day}
        composition={composition}
      />
    );

  if (status.isProgrammedCollection) {
    return <ProgrammedCollectionCell composition={composition} day={day} />;
  }

  if (status.isRealCollection) {
    return <RealCollectionCell value={realCollectionTotal} />;
  }

  if (status.isAccumulatedCollection) {
    return (
      <RealCollectionAccumulatedCell value={realCollectionAccumulatedValue} />
    );
  }

  if (status.isFinalBalance) {
    return <FinalBalanceCell value={restante} />;
  }

  return <Empty />;
}

import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { productionPlanSimulatorsKey } from "./useProductionPlanSimulators";
import { productionPlanKeys } from "./useProductionPlanMutation";
import { productionSimulationKey } from "./useProductionSimulation";

type ProductionPlanAddSimulationType = {
  fkSimulation: number;
  fkProductionPlan: number;
  lineValues: string;
  oldLineValues?: string;
  revision: number | string;
  observation?: string;
};

export function useProductionPlanAddSimulationMutation({ id }: { id: number }) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: ProductionPlanAddSimulationType) => {
      await instance.put("/planningbeer/productionPlan/simulators", {
        ...data,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          productionPlanKeys.all,
          productionPlanSimulatorsKey.show(id),
        ],
      });
      queryClient.invalidateQueries({
        queryKey: productionSimulationKey.show,
      });
    },
  });
}

import useProduct from "@/hooks/useProduct";
import ProductTitleText, {
  ProductTitleTextProps,
} from "../../Text/ProductTitleText";
import Col from "@/components/Col";
import Row from "@/components/Row";
import InformationText from "../../Text/InformationText";
import Text from "@/components/Text/Text";
import FactoryCard from "../../Card/FactoryCard";
import Each from "@/components/Each";
import ChartProduction from "../../Chart";
import Can from "@/components/Can";

type InformationContentProps = ProductTitleTextProps;

export default function InformationContent({
  product,
}: InformationContentProps) {
  const { data } = useProduct(product.code);
  const { data: productInformation } = data || {};
  const {
    information,
    factoriesAndResources = [],
    productionPerMonth = [],
  } = productInformation || {};
  const {
    tipo_produto,
    grupo,
    classe_produto,
    classificacao,
    litragem,
    tipo_liquido,
    volume,
    formacao,
    camadas,
    lastro,
  } = information || {};

  return (
    <div className="w-full h-full">
      <ProductTitleText product={product} />
      <Row className="h-full w-full">
        <Row className="2xl:w-3/12 2xl:max-w-3/12 2xl:min-w-2/12 max-w-3/12 w-3/12 min-w-3/12 shadow-md">
          <Col className="mt-4 items-start gap-4 w-full overflow-hidden px-4">
            <Text className="font-bold">Informações do Produto</Text>
            <InformationText label="Tipo de Produto" value={tipo_produto} />
            <InformationText label="Grupo" value={grupo} />
            <InformationText label="Classe do Produto" value={classe_produto} />
            <InformationText label="Classificação" value={classificacao} />
            <InformationText label="Litragem" value={litragem} />
            <InformationText label="Tipo de Líquido" value={tipo_liquido} />
            <InformationText label="Volume" value={volume} />
            <InformationText label="Formação" value={formacao} />
            <InformationText label="Camadas" value={camadas} />
            <InformationText label="Lastro" value={lastro} />
          </Col>
        </Row>
        <Col className="mt-4 gap-6 w-full mx-4">
          <Text className="font-bold">Fabricado em</Text>
          <Each
            array={factoriesAndResources}
            renderItem={(props) => <FactoryCard {...props} />}
            wrapper={{
              className:
                "flex flex-col rounded-md h-fit w-fit p-4 shadow-md border border-solid",
            }}
          />
        </Col>
        <Can
          condition={productionPerMonth.length > 0}
          onTrue={
            <Row className="shadow-md">
              <Col className="mt-4 px-4">
                <Text className="font-bold mb-2">Últimas Produções</Text>
                <ChartProduction chartData={productionPerMonth} />
              </Col>
            </Row>
          }
        />
      </Row>
    </div>
  );
}

import instance from "@/api/business";
import { ApiResponse } from "./useEstimates";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

export type IndisponibilityResourceType = {
  SK_indisponibilidade_programada: number;
  FK_fabrica_recurso: number | string;
  motivo?: string;
  vigencia_inicio?: string;
  vigencia_fim?: string;
  dia_todo?: number;
  abreviatura_empresa?: string;
  cnpj?: string;
  codigo?: string;
  tipo?: string;
  descricao?: string;
  tipo_de_embalagem?: string;
};

export const indisponibilityResourceKey = {
  all: ["indisponibilityResource"] as const,
  show: (id: number) => [indisponibilityResourceKey.all, "show", id],
};

async function fetchIndisponibilityResources() {
  const response = await instance.post<
    ApiResponse<IndisponibilityResourceType>
  >("/planningbeer/indisponibilityResource");

  return response.data;
}

export function useIndisponibilityResources() {
  return useQuery({
    queryKey: indisponibilityResourceKey.all,
    queryFn: fetchIndisponibilityResources,
    placeholderData: keepPreviousData,
  });
}

import { useForm } from "react-hook-form";
import ProductionPlanForm from "..";
import { ProductionPlanFormType, schema } from "../zod";
import useProductionPlanMutation from "@/hooks/useProductionPlanMutation";
import { validationError } from "@/utils/api";
import dayjs from "dayjs";
import { toast } from "sonner";
import { zodResolver } from "@hookform/resolvers/zod";

type CreateProductionPlanFormProps = {
  handleDialog: () => void;
};

export default function CreateProductionPlanForm({
  handleDialog,
}: CreateProductionPlanFormProps) {
  const { mutateAsync, isPending } = useProductionPlanMutation();
  const form = useForm<ProductionPlanFormType>({
    resolver: zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  async function handleSubmit(data: ProductionPlanFormType) {
    if (isPending) return;

    try {
      await mutateAsync({
        FK_fabrica: data.FK_fabrica,
        periodo_inicio: dayjs(data.periodo_inicio).format("YYYY-MM-DD"),
        periodo_fim: dayjs(data.periodo_fim).format("YYYY-MM-DD"),
        descricao: data.descricao,
      });
      toast.success("Plano de produção cadastrado com sucesso!");
      return handleDialog();
    } catch (error) {
      return validationError(error);
    }
  }

  return (
    <ProductionPlanForm
      form={form}
      onSubmit={handleSubmit}
      button={{ disabled: isPending, title: "Cadastrar" }}
    />
  );
}

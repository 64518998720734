import { ProductionPlanType } from "@/hooks/useProductionPlans";
import { SimulationFormType } from "@/pages/Simulacoes/components/Form/zod";
import { ReactChildrenType } from "@/types";
import { createContext, useContext, useState } from "react";

type DialogTypeType = "edit" | "delete" | "nonSelected";

type ProductionPlanContextProps = {
  handleDialog: () => void;
  handleDialogType: (type: DialogTypeType) => void;
  handleProductPlan: (factory: ProductionPlanType) => void;
  handleFilterProductionPlan: (filter: SimulationFormType) => void;
  clear: () => void;
  visibleDialog: boolean;
  dialogType: DialogTypeType;
  productionPlan: ProductionPlanType;
  filterProductionPlan: SimulationFormType;
};

const ProductionPlanContext = createContext({} as ProductionPlanContextProps);

export default function ProductionPlanProvider({
  children,
}: ReactChildrenType) {
  const [dialogType, setDialogType] = useState<DialogTypeType>("nonSelected");
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [productionPlan, setProductionPlan] = useState<ProductionPlanType>(
    {} as ProductionPlanType
  );
  const [filterProductionPlan, setFilterProductionPlan] =
    useState<SimulationFormType>({} as SimulationFormType);

  function handleDialog() {
    setVisibleDialog((prev) => !prev);
  }

  function handleDialogType(value: DialogTypeType) {
    setDialogType(value);
  }

  function handleProductPlan(value: ProductionPlanType) {
    setProductionPlan(value);
  }

  function handleFilterProductionPlan(filter: SimulationFormType) {
    setFilterProductionPlan(filter);
  }

  function clear() {
    setVisibleDialog(false);
    setProductionPlan({} as ProductionPlanType);
    setFilterProductionPlan({} as SimulationFormType);
    setDialogType("nonSelected");
  }

  return (
    <ProductionPlanContext.Provider
      value={{
        clear,
        handleDialogType,
        handleDialog,
        handleProductPlan,
        handleFilterProductionPlan,
        filterProductionPlan,
        productionPlan,
        visibleDialog,
        dialogType,
      }}
    >
      {children}
    </ProductionPlanContext.Provider>
  );
}

export function useProductionPlanContext() {
  return useContext(ProductionPlanContext);
}

import { cn } from "@/lib/utils";
import { ReactChildrenType } from "@/types";

type TableContainerProps = {
  className?: string;
} & ReactChildrenType;

export default function TableContainer({
  children,
  className,
}: TableContainerProps) {
  return (
    <div className={cn("max-h-[calc(100vh-150px)] h-full", className)}>
      <div className="h-full flex flex-col">{children}</div>
    </div>
  );
}

import Row from "@/components/Row";
import { EyeIcon, EyeOffIcon } from "lucide-react";
import { useSimulationProductionContext } from "../../context";
import Can from "@/components/Can";
import { Button } from "@/components/ui/button";
import { TEXT_COLOR } from "@/constants";
import Text from "@/components/Text/Text";

export default function TableActionRow() {
  const {
    icons: { handleDisabled, disabled },
  } = useSimulationProductionContext();
  return (
    <Row align="center">
      <Can
        condition={!disabled}
        onTrue={
          <Button variant="outline" onClick={handleDisabled}>
            <EyeIcon size={20} color={TEXT_COLOR} />
            <Text className="mt-1 ml-2">Esconder Ícones</Text>
          </Button>
        }
        onFalse={
          <Button variant="outline" onClick={handleDisabled}>
            <EyeOffIcon size={20} color={TEXT_COLOR} />
            <Text className="mt-1 ml-2">Exibir Ícones</Text>
          </Button>
        }
      />
    </Row>
  );
}

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { PackagingSimulationFormType } from "./zod";
import { UseFormReturn } from "react-hook-form";
import ProductionSimulatorSelect from "@/components/Select/ProductionSimulatorSelect";
import Row from "@/components/Row";
import { Button } from "@/components/ui/button";
import Text from "@/components/Text/Text";

type PackagingSimulationFormProps = {
  form: UseFormReturn<PackagingSimulationFormType, any, undefined>;
  onSubmit: (data: PackagingSimulationFormType) => void;
  button?: {
    disabled?: boolean;
    title?: string;
  };
};

export default function PackagingSimulationForm({
  form,
  button = { title: "Gerar" },
  onSubmit,
}: PackagingSimulationFormProps) {
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name={"SK_simulacao"}
          render={({ field }) => (
            <FormItem>
              <FormLabel>ID Simulador Produto Acabado</FormLabel>
              <FormControl>
                <ProductionSimulatorSelect
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Row justify="end" align="center" className="gap-2 mt-4">
          <Button type="submit" disabled={button?.disabled}>
            <Text color="white">{button?.title}</Text>
          </Button>
        </Row>
      </form>
    </Form>
  );
}

import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { resourceKeys, ResourcesType } from "./useResources";

type ResourceMutationType = Omit<ResourcesType, "SK_recurso">;

export default function useResourceMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ResourceMutationType) => {
      return instance.put("/planningbeer/resource", { ...data });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: resourceKeys.all });
    },
  });
}

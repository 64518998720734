import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { IoMenu } from "react-icons/io5";
import SidebarItems from "./Items/SidebarItems";
import { Button } from "../ui/button";

export default function MenuSideBar() {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="ghost">
          <IoMenu size={22} />
        </Button>
      </SheetTrigger>
      <SheetContent side="left">
        <SidebarItems />
      </SheetContent>
    </Sheet>
  );
}

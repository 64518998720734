import { SelectProps } from "@radix-ui/react-select";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import {
  factoryResourceKey,
  FactoryResourceType,
} from "@/hooks/useFactoriesResources";
import { useQueryClient } from "@tanstack/react-query";
import Each from "../Each";

export type FactoryResourceData = {
  data: Array<FactoryResourceType>;
};

export default function FactoryResourceSelect(props: SelectProps) {
  const queryClient = useQueryClient();

  const factoryResourceData: FactoryResourceData | undefined =
    queryClient.getQueryData(factoryResourceKey.all);
  const { data: factoryResource } = factoryResourceData || {};
  if (!factoryResource) return null;

  return (
    <Select
      onValueChange={props.onValueChange}
      defaultValue={props.defaultValue}
      value={props.value}
    >
      <SelectTrigger>
        <SelectValue placeholder="" />
      </SelectTrigger>
      <SelectContent>
        <Each
          array={factoryResource}
          renderItem={(props) => {
            return (
              <SelectItem value={String(props.SK_fabrica_recurso)}>
                {props.SK_fabrica_recurso} {" - "}
                {props.abreviatura_empresa}, {props.descricao}
              </SelectItem>
            );
          }}
        />
      </SelectContent>
    </Select>
  );
}

import Row from "@/components/Row";
import { usePackagingSimulationContext } from "../../context";
import Text from "@/components/Text/Text";
import { Link } from "react-router-dom";
import { ROUTES, TEXT_COLOR } from "@/constants";
import { LuExternalLink } from "react-icons/lu";
import dayjs from "dayjs";
import Col from "@/components/Col";

export default function ProductionSimulationRow() {
  const { packagingSimulationData } = usePackagingSimulationContext();
  const { simulacao_producao } = packagingSimulationData || {};
  const { periodo_fim, periodo_inicio, descricao, SK_simulacao } =
    simulacao_producao || {};

  return (
    <Col>
      <Row align="center">
        <Text className="font-2xl mr-2">Simulação Produto Acabado</Text>
        <Link
          to={"/" + ROUTES.simulators.index + "editar/" + SK_simulacao}
          target="_blank"
        >
          <LuExternalLink color={TEXT_COLOR} />
        </Link>
        <Text className="font-bold ml-2">{SK_simulacao}</Text>
        <Text className="font-bold font-2xl">, {descricao}</Text>
      </Row>
      <Text>
        {dayjs(periodo_inicio).format("DD/MM/YYYY")}
        {" - "}
        {dayjs(periodo_fim).format("DD/MM/YYYY")}
      </Text>
    </Col>
  );
}

export const DEMAND_PLANNING_ST = "PREV DEMANDA";
export const ACCUMULATE_SALE_ST = "VENDA ACUMULADA";
export const SD_INICIAL_ST = "SD INICIAL";
export const SD_FINAL_ST = "SD FINAL";
export const LINE_CELL = "LINHA";
export const AVERAGE_SALE_ST = "VENDA MÉDIA";
export const GRID_SALE = "VENDA GRADE";
export const REAL_PRODUCTION_ST = "PROD REAL";
export const ACCUMULATED_PRODUCION_ST = "PROD ACUM";

export const COLORS = {
  transparent: "transparent",
  green: "green",
  red: "red",
  orange: "orange",
  blue: "blue",
};

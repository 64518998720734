import Can from "@/components/Can";
import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import DotsLoading from "@/components/Loading/DotsLoading";
import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { usePackagingSimulator } from "@/hooks/usePackagingSimulator";
import usePackagingSimulatorPatchMutation from "@/hooks/usePackagingSimulatorPatchMutation";
import { VisibleIconButton } from "@/pages/SimulacoesEmbalagem/components/Button/VisibleIconButton";
import PackagingSimulationTable from "@/pages/SimulacoesEmbalagem/components/Table";
import { usePackagingSimulationContext } from "@/pages/SimulacoesEmbalagem/context";
import SimulationError from "@/pages/Simuladores/components/Error/SimulationError";
import { validationError } from "@/utils/api";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "sonner";

export default function EditPackagingSimulator() {
  const { id } = useParams();
  const {
    data,
    error,
    isPending: isPendingPackagingSimulator,
  } = usePackagingSimulator({
    id: String(id),
  });
  const { data: packagingSimulator } = data || {};
  const { meta } = packagingSimulator || {};
  const { valores_fornecedores: supplierValues = [] } = meta || {};
  const {
    handlePackagingSimulationData,
    packagingSimulationData,
    clear,
    packagingSupplier: {
      handlePackagingSupplierCellValue,
      packagingSupplierCellValue,
    },
  } = usePackagingSimulationContext();
  const { simulacao_producao } = packagingSimulationData || {};
  const { SK_simulacao } = simulacao_producao || {};
  const { mutateAsync, isPending } = usePackagingSimulatorPatchMutation(
    Number(id)
  );

  async function handleUpdatePackagingSimulator() {
    if (isPending) return;
    try {
      await mutateAsync({
        valores_fornecedores: packagingSupplierCellValue,
        FK_simulacao_embalagem: Number(id),
        FK_simulacao_producao: SK_simulacao,
      });
      toast.success("Simulação de embalagem atualizada com sucesso!");
      return;
    } catch (error) {
      return validationError(error);
    }
  }

  useEffect(() => {
    if (!packagingSimulator) return;
    handlePackagingSimulationData(packagingSimulator);
    handlePackagingSupplierCellValue(supplierValues);
  }, [packagingSimulator]);

  useEffect(() => {
    return () => {
      clear();
    };
  }, []);

  if (isPendingPackagingSimulator)
    return (
      <AuthenticatedLayout className="h-full">
        <DotsLoading />
      </AuthenticatedLayout>
    );

  return (
    <AuthenticatedLayout>
      <Can
        condition={!error}
        onTrue={
          <React.Fragment>
            <Can
              condition={!isPendingPackagingSimulator}
              onTrue={
                <React.Fragment>
                  <Row justify="end" className="gap-4">
                    <VisibleIconButton />
                  </Row>
                  <PackagingSimulationTable />
                  <Row justify="end" className="mt-4 gap-4">
                    <Link to="/simuladoresEmbalagem">
                      <Button variant="ghost">Voltar</Button>
                    </Link>
                    <Button
                      disabled={isPending}
                      onClick={handleUpdatePackagingSimulator}
                    >
                      <Text color="white">Salvar</Text>
                    </Button>
                  </Row>
                </React.Fragment>
              }
            />
          </React.Fragment>
        }
        onFalse={<SimulationError />}
      />
    </AuthenticatedLayout>
  );
}

import Can from "@/components/Can";
import Col from "@/components/Col";
import Each from "@/components/Each";
import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { PRIMARY_COLOR, TEXT_COLOR } from "@/constants";
import { useProductionPlanOld } from "@/hooks/useProductionPlanOld";
import dayjs from "dayjs";
import { FaCircle, FaCodeBranch, FaMapPin } from "react-icons/fa";
import { FaStar } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import { useProductionPlanContext } from "../../context";
import VersionDropdown from "../Dropdown/VersionDropdown";
const FIRST_VERSION = 1;

const EVENTS_MESSAGE = {
  VERSION_CHANGE:
    "Alteração de versão do plano de produção, realizada manualmente",
};

export default function VersionModal() {
  const {
    productionPlan: { revisao: productionPlanVersion },
  } = useProductionPlanContext();
  const { id } = useParams();
  const { data } = useProductionPlanOld({ id: Number(id) });
  const { data: productionPlanOld } = data || {};
  const { anteriores } = productionPlanOld || {};
  return (
    <Dialog>
      <DialogTrigger>
        <Button size="icon" variant="outline">
          <FaCodeBranch size={20} color={TEXT_COLOR} />
        </Button>
      </DialogTrigger>
      <DialogContent className="flex flex-col max-w-full h-full w-6/12 px-8 py-8 overflow-y-scroll">
        <Text className="font-bold text-lg">Controle de Versão</Text>
        <Each
          array={anteriores || []}
          renderItem={({
            revisao,
            data,
            edicao,
            valores_linhas,
            deletado,
            evento,
            observacao,
          }) => {
            return (
              <Row
                className="w-full border"
                justify="space-between"
                align="center"
              >
                <Col
                  className={`justify-center h-full p-4 ${deletado ? "opacity-75" : ""}`}
                >
                  <Row align="center" className="gap-2">
                    <div>
                      <Can
                        condition={revisao == productionPlanVersion}
                        onTrue={<FaStar size={18} color={PRIMARY_COLOR} />}
                        onFalse={
                          <Can
                            condition={revisao == FIRST_VERSION}
                            onTrue={
                              <FaMapPin
                                color={TEXT_COLOR}
                                size={18}
                                className="p-0 m-0"
                              />
                            }
                            onFalse={<FaCircle size={9} color={TEXT_COLOR} />}
                          />
                        }
                      />
                    </div>
                    <Text
                      className={`text-base font-bold ${deletado ? "line-through" : ""}`}
                      style={{
                        color:
                          revisao == productionPlanVersion
                            ? PRIMARY_COLOR
                            : TEXT_COLOR,
                      }}
                    >
                      Versão {revisao}
                    </Text>
                  </Row>
                  <Row className="mt-2 ml-4" justify="space-between">
                    <Col>
                      <Text
                        className={
                          deletado ? "line-through text-sm" : "text-sm"
                        }
                      >
                        {dayjs(data).format("DD/MM/YYYY HH:mm:ss")}
                      </Text>
                      <Can
                        condition={revisao != FIRST_VERSION}
                        onTrue={
                          <Text
                            className={
                              deletado ? "line-through text-sm" : "text-sm"
                            }
                          >
                            <Can
                              condition={!!evento}
                              onTrue={
                                <Text className="bg-gray-200 p-1 px-2 rounded-full">
                                  {EVENTS_MESSAGE[evento || ""] || ""}
                                </Text>
                              }
                              onFalse={
                                <>
                                  {edicao ? (
                                    <Text className="p-1 px-2 rounded-full bg-yellow-100">
                                      Edição no Plano de Produção
                                    </Text>
                                  ) : (
                                    <Text className="p-1 px-2 rounded-full bg-lime-200	">
                                      Inclusão de Simulador
                                    </Text>
                                  )}
                                </>
                              }
                            />
                          </Text>
                        }
                        onFalse={<Text>Plano criado</Text>}
                      />
                    </Col>
                  </Row>
                  <Can
                    condition={observacao?.length ?? 0 > 0 ? true : false}
                    onTrue={
                      <Row className="ml-4 mt-2">
                        <Text className="font-bold text-sm">Observação:</Text>
                        <Text className="ml-1 text-sm">{observacao}</Text>
                      </Row>
                    }
                  />
                </Col>
                <Can
                  condition={!deletado && revisao !== productionPlanVersion}
                  onTrue={
                    <VersionDropdown
                      data={data}
                      edicao={edicao}
                      revisao={revisao}
                      valores_linhas={valores_linhas}
                    />
                  }
                />
              </Row>
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

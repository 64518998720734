import { FaEdit } from "react-icons/fa";
import Row from "../Row";
import { DropdownMenuItem } from "../ui/dropdown-menu";
import Text from "../Text/Text";
import { TEXT_COLOR } from "@/constants";

type EditDropdownItemProps = {
  onClick: () => void;
};

export default function EditDropdownItem({ onClick }: EditDropdownItemProps) {
  return (
    <DropdownMenuItem onClick={onClick}>
      <Row>
        <FaEdit className="mr-2" color={TEXT_COLOR} />
        <Text>Editar</Text>
      </Row>
    </DropdownMenuItem>
  );
}

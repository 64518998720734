import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { TEXT_COLOR } from "@/constants";
import { FactoryColumnsType } from "@/pages/Factory";
import { useFactoryContext } from "@/pages/Factory/context";
import { FaGear } from "react-icons/fa6";

export default function ManagerFactoryDropdownItem(props: FactoryColumnsType) {
  const { handleDialogType, handleFactory, handleDialog } = useFactoryContext();

  function handleManageFactory() {
    handleDialog();
    handleDialogType("manageFactory");
    handleFactory({ ...props });
  }

  return (
    <DropdownMenuItem onClick={handleManageFactory}>
      <Row>
        <FaGear className="mr-2" color={TEXT_COLOR} />
        <Text>Gerenciar Fábrica</Text>
      </Row>
    </DropdownMenuItem>
  );
}

import { quantityFormater } from "@/utils/number";
import {
  ACCUMULATE_SALE_ST,
  ACCUMULATED_PRODUCION_ST,
  AVERAGE_SALE_ST,
  DEMAND_PLANNING_ST,
  GRID_SALE,
  REAL_PRODUCTION_ST,
  SD_FINAL_ST,
  SD_INICIAL_ST,
} from "../../constants";
import { lazy, Suspense } from "react";
import Empty from "@/components/Empty";

const DefaultCell = lazy(() => import("./DefaultCell"));
const GridSaleCell = lazy(() => import("./GridSaleCell"));
const InitialBalanceCell = lazy(() => import("./InitialBalanceCell"));
const FinalBalanceCell = lazy(() => import("./FinalBalanceCell"));
const RealProductionCell = lazy(() => import("./RealProductionCell"));

type CellProps = {
  fkProduct: string;
  st: string;
  balance: {
    isNew: boolean;
    initial: number;
    final: number;
  };
  gridSale: number;
  acumulatedSale: number;
  demandPlanning: number;
  averageSale: number;
  realProduction: number;
  accumulatedProduction: number;
};

type StatusType = {
  isInitialBalance: boolean;
  isFinalBalance: boolean;
  isGridSale: boolean;
  isAccumulatedSale: boolean;
  isAvergeSaleType: boolean;
  isDemandPlanning: boolean;
  isRealProduction: boolean;
  isAccumulatedProduction: boolean;
};

export default function Cell({
  st,
  acumulatedSale,
  balance: { final, initial, isNew },
  demandPlanning,
  gridSale,
  averageSale,
  fkProduct,
  realProduction,
  accumulatedProduction,
}: CellProps) {
  const status: StatusType = {
    isGridSale: st.toUpperCase() == GRID_SALE.toUpperCase(),
    isInitialBalance: st.toUpperCase() == SD_INICIAL_ST.toUpperCase(),
    isFinalBalance: st.toUpperCase() == SD_FINAL_ST.toUpperCase(),
    isAccumulatedSale: st.toUpperCase() == ACCUMULATE_SALE_ST.toUpperCase(),
    isDemandPlanning: st.toUpperCase() == DEMAND_PLANNING_ST.toUpperCase(),
    isAvergeSaleType: st.toUpperCase() == AVERAGE_SALE_ST.toUpperCase(),
    isRealProduction: st.toUpperCase() == REAL_PRODUCTION_ST.toUpperCase(),
    isAccumulatedProduction:
      st.toUpperCase() == ACCUMULATED_PRODUCION_ST.toUpperCase(),
  };

  if (status.isAvergeSaleType)
    return (
      <Suspense
        fallback={<Empty />}
        children={<DefaultCell value={averageSale} />}
      />
    );
  if (status.isAccumulatedSale)
    return (
      <Suspense
        fallback={<Empty />}
        children={<DefaultCell value={acumulatedSale} />}
      />
    );
  if (status.isDemandPlanning)
    return (
      <Suspense
        fallback={<Empty />}
        children={<DefaultCell value={demandPlanning} />}
      />
    );

  if (status.isAccumulatedProduction)
    return (
      <Suspense
        fallback={<Empty />}
        children={<DefaultCell value={accumulatedProduction} />}
      />
    );
  if (status.isGridSale)
    return (
      <Suspense
        fallback={<Empty />}
        children={<GridSaleCell value={gridSale} />}
      />
    );

  if (status.isInitialBalance)
    return (
      <Suspense
        fallback={<Empty />}
        children={
          <InitialBalanceCell
            fkProduct={fkProduct}
            cellValue={quantityFormater(initial)}
            isNewInventory={isNew}
          />
        }
      />
    );

  if (status.isFinalBalance)
    return (
      <Suspense
        fallback={<Empty />}
        children={
          <FinalBalanceCell
            fkProduct={fkProduct}
            cellValue={quantityFormater(final)}
          />
        }
      />
    );
  if (status.isRealProduction)
    return (
      <Suspense
        fallback={<Empty />}
        children={<RealProductionCell value={realProduction} />}
      />
    );

  return <Empty />;
}

import ResetPasswordForm from "@/pages/RecuperarSenha/components/Form/ResetPasswordForm";
import Card from "./Card";

export default function ForgotPasswordCard() {
  return (
    <Card width={400}>
      <ResetPasswordForm />
    </Card>
  );
}

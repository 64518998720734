import Can from "@/components/Can";
import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { TEXT_COLOR } from "@/constants";
import { EyeIcon, EyeOffIcon } from "lucide-react";
import { usePackagingSimulationContext } from "../../context";

export function VisibleIconButton() {
  const {
    icons: { handleVisible, visible },
  } = usePackagingSimulationContext();

  return (
    <Row align="center">
      <Can
        condition={!visible}
        onTrue={
          <Button variant="outline" onClick={handleVisible}>
            <EyeIcon size={20} color={TEXT_COLOR} />
            <Text className="mt-1 ml-2">Esconder Ícones</Text>
          </Button>
        }
        onFalse={
          <Button variant="outline" onClick={handleVisible}>
            <EyeOffIcon size={20} color={TEXT_COLOR} />
            <Text className="mt-1 ml-2">Exibir Ícones</Text>
          </Button>
        }
      />
    </Row>
  );
}

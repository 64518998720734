import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { resourceFactoryProductsKeys } from "./useResourceFactoryProducts";

type ResourceFactoryProductPatchMutation = {
  FK_fabrica_recurso: number;
  FK_produto: string;
  SK_recurso_fabrica_produto: number;
  capacidade: string;
};

export default function useResourceFactoryProductPatchMutation() {
  const queryClent = useQueryClient();
  return useMutation({
    mutationFn: (data: ResourceFactoryProductPatchMutation) => {
      return instance.patch("/planningbeer/resourceFactoryProduct", {
        ...data,
      });
    },
    onSuccess: () => {
      queryClent.invalidateQueries({
        queryKey: resourceFactoryProductsKeys.all,
      });
    },
  });
}

import { CONFIGURATION_TABLES_CODE } from "@/constants";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ResourceTypeFormType, schema } from "../zod";
import { toast } from "sonner";
import { validationError } from "@/utils/api";
import ResourceTypeForm from "..";
import { useEffect } from "react";
import { useResourceTypeContext } from "@/pages/TipoRecurso/context";
import useConfigurationPatchMutation from "@/hooks/useConfigurationPatchMutation";

export default function EditResourceTypeForm() {
  const { clear, resourceType } = useResourceTypeContext();
  const { mutateAsync, isPending } = useConfigurationPatchMutation({
    tabela_codigo: CONFIGURATION_TABLES_CODE.dim_tipo_recursos.codigo,
  });
  const form = useForm<ResourceTypeFormType>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
  });

  async function handleSubmit(formData: ResourceTypeFormType) {
    if (isPending) return;
    try {
      await mutateAsync({
        SK_configuracao: resourceType.SK_configuracao,
        tabela_codigo: CONFIGURATION_TABLES_CODE.dim_tipo_recursos.codigo,
        tabela_nome: CONFIGURATION_TABLES_CODE.dim_tipo_recursos.nome,
        chave: formData.valor.toUpperCase(),
        valor: formData.valor.toUpperCase(),
      });
      toast.success("Tipo de recurso editado com sucesso!");
      return clear();
    } catch (error) {
      return validationError(error);
    }
  }

  useEffect(() => {
    form.reset({ valor: resourceType.valor });
  }, [resourceType]);

  return (
    <ResourceTypeForm
      form={form}
      onSubmit={handleSubmit}
      button={{ disabled: isPending, title: "Salvar" }}
    />
  );
}

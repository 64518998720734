import instance from "@/api/business";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { factoryKeys } from "./useFactoryMutation";
import { ApiResponse } from "./useEstimates";
import { FactoryColumnsType } from "@/pages/Factory";

async function fetchFactories() {
  const response = await instance.post<ApiResponse<FactoryColumnsType>>(
    "/planningbeer/factory/"
  );
  return response.data;
}

export default function useFactories() {
  return useQuery({
    queryKey: factoryKeys.all,
    queryFn: () => fetchFactories(),
    placeholderData: keepPreviousData,
  });
}

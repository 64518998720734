export type Filter<T> = {
  fieldToFilter?: keyof T;
  valueToFilter?: string | number | boolean | (string | number | boolean)[];
};

export function filterItems<T>(items?: T[], filter?: Filter<T>) {
  if (!items) return [];
  return items.filter((item) => {
    if (
      !filter ||
      filter.valueToFilter === undefined ||
      filter.fieldToFilter === undefined
    ) {
      return true;
    }

    const fieldValue = item[filter.fieldToFilter];
    const valueToFilter = filter.valueToFilter;

    if (Array.isArray(valueToFilter)) {
      return valueToFilter.includes(fieldValue as string | number | boolean);
    } else {
      return fieldValue == valueToFilter;
    }
  });
}

export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number
): (...args: Parameters<T>) => Promise<ReturnType<T>> {
  let timeout: ReturnType<typeof setTimeout> | undefined;
  return function (
    this: ThisParameterType<T>,
    ...args: Parameters<T>
  ): Promise<ReturnType<T>> {
    if (timeout) {
      clearTimeout(timeout);
    }
    return new Promise<ReturnType<T>>((resolve) => {
      timeout = setTimeout(() => {
        const result = func.apply(this, args);
        resolve(result);
      }, wait);
    });
  };
}

import Row from "@/components/Row";
import Text from "@/components/Text/Text";

type SalesTextProps = {
  icon: React.ReactNode;
  value: string | number;
  label: string;
};

export default function SalesText({ icon, value, label }: SalesTextProps) {
  return (
    <Row align="center">
      {icon}
      <Text className="ml-1 mr-1 text-sm">{label}: </Text>
      <Text className="text-sm font-bold">{value}</Text>
    </Row>
  );
}

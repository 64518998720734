import {
  CellLineValuesType,
  ProductionSimulationResponseType,
} from "@/hooks/useProductionSimulationMutation";
import { ReactChildrenType } from "@/types";
import { createContext, useContext, useState } from "react";

type ProductStockPolicyType = {
  [key: string]: {
    estoque_maximo: string;
    estoque_minimo: string;
    estoque_reabastecimento: string;
  };
};

type ProductionPlanType = {
  SK_plano_producao: number;
  FK_fabrica: number;
  revisao: number | string;
};

type SimulationProductionProps = {
  productionPlan: {
    handleProductionPlan: (data: ProductionPlanType) => void;
    productionPlan: ProductionPlanType;
  };
  lines: {
    handleLineValuesArray: (lineValues: CellLineValuesType[]) => void;
    handleLineValues: (lineValue: CellLineValuesType) => void;
    lineValues: CellLineValuesType[];
  };
  simulationData: ProductionSimulationResponseType;
  handleSimulationData: (data: ProductionSimulationResponseType) => void;
  clear: () => void;
  icons: {
    disabled: boolean;
    handleDisabled: () => void;
  };
  productStockPolicy: ProductStockPolicyType;
};

const SimulationProductionContext = createContext(
  {} as SimulationProductionProps
);

export function SimulationProductionProvider({ children }: ReactChildrenType) {
  const [productionPlan, setProductionPlan] = useState(
    {} as ProductionPlanType
  );
  const [productStockPolicy, setProductStockPolicy] =
    useState<ProductStockPolicyType>({});
  const [disabled, setDisabled] = useState(false);
  const [simulationData, setSimulationData] = useState(
    {} as ProductionSimulationResponseType
  );
  const [lineValues, setLineValues] = useState<Array<CellLineValuesType>>([]);

  function handleLineValues({
    dia,
    valor,
    FK_fabrica_recurso,
    FK_produto,
  }: {
    dia: string;
    valor?: string;
    FK_fabrica_recurso: string | number;
    FK_produto: string;
  }) {
    setLineValues((prev) => {
      const newCellLineValues = prev.filter(
        (cellLineValue) =>
          cellLineValue.dia !== dia ||
          cellLineValue.FK_fabrica_recurso !== FK_fabrica_recurso ||
          cellLineValue.FK_produto !== FK_produto
      );

      if (valor) {
        newCellLineValues.push({ dia, valor, FK_fabrica_recurso, FK_produto });
      }

      return newCellLineValues;
    });
  }

  function handleSimulationData(data: ProductionSimulationResponseType) {
    setSimulationData(data);
    const productsPolicy = data.previsao_demanda.reduce((acc, product) => {
      acc[product.FK_produto] = { ...product.politica_estoque };
      return acc;
    }, {});
    setProductStockPolicy(productsPolicy);
  }

  function handleLineValuesArray(data: CellLineValuesType[]) {
    setLineValues(data);
  }

  function handleDisabled() {
    setDisabled((prev) => !prev);
  }

  function handleProductionPlan(data: ProductionPlanType) {
    setProductionPlan(data);
  }

  function clear() {
    setSimulationData({} as any);
    // setProductionPlan({} as any);
    setLineValues([]);
    setDisabled(false);
  }

  return (
    <SimulationProductionContext.Provider
      value={{
        productStockPolicy,
        icons: {
          disabled,
          handleDisabled,
        },
        productionPlan: {
          handleProductionPlan,
          productionPlan,
        },
        simulationData,
        handleSimulationData,
        clear,
        lines: { handleLineValues, lineValues, handleLineValuesArray },
      }}
    >
      {children}
    </SimulationProductionContext.Provider>
  );
}

export function useSimulationProductionContext() {
  return useContext(SimulationProductionContext);
}

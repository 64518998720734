import { Input } from "@/components/ui/input";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useForm } from "react-hook-form";
import Row from "@/components/Row";
import StateSelect from "@/components/Select/StateSelect";
import BusinessSelect from "@/components/Select/BusinessSelect";
import { BusinessType } from "@/hooks/useBusiness";
import { useEffect } from "react";
import { clearCnpjFormat, formatCnpj } from "@/utils/cnpj";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { zodResolver } from "@hookform/resolvers/zod";
import { schema } from "../CreateFactoryForm/zod";
import { CreateFactoryFormType } from "../CreateFactoryForm";
import useFactoryPatchMutation from "@/hooks/useFactoryPatchMutation";
import { validationError } from "@/utils/api";
import { FactoryColumnsType } from "@/pages/Factory";
import { toast } from "sonner";

type EditFactoryFormProps = {
  data?: FactoryColumnsType;
  handleDialog: () => void;
};

export default function EditFactoryForm({
  data,
  handleDialog,
}: EditFactoryFormProps) {
  const { mutateAsync, isPending } = useFactoryPatchMutation();
  const form = useForm<CreateFactoryFormType>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
  });
  const empresa = form.watch("empresa");

  async function onSubmit(formData: CreateFactoryFormType) {
    if (isPending) return;
    const apiData: Omit<CreateFactoryFormType, "empresa"> = {
      abreviatura_empresa: formData.abreviatura_empresa,
      bairro: formData.bairro,
      cidade: formData.cidade,
      cnpj: formData.cnpj,
      contato: formData.contato,
      email: formData.email,
      endereco: formData.endereco,
      estado: formData.estado,
      numero: formData.numero,
      codigo: formData.codigo,
    };
    try {
      await mutateAsync({ SK_fabrica: Number(data?.SK_fabrica), ...apiData });
      toast.success("Fabrica editada com sucesso!");
      return handleDialog();
    } catch (error) {
      return validationError(error);
    }
  }

  useEffect(() => {
    const parsedEmpresa: BusinessType = JSON.parse(empresa || "{}");
    form.setValue("codigo", String(parsedEmpresa.SK_EMPRESA || ""));
    form.setValue("cnpj", formatCnpj(String(parsedEmpresa.CNPJ || "")));
    form.setValue(
      "abreviatura_empresa",
      String(parsedEmpresa.ABREVIATURA_EMPRESA || "")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresa]);

  useEffect(() => {
    const businessJson = {
      SK_EMPRESA: data?.codigo || "",
      ABREVIATURA_EMPRESA: data?.abreviatura_empresa || "",
      CNPJ: clearCnpjFormat(data?.cnpj || "") || "",
    };
    form.reset({ empresa: JSON.stringify(businessJson), ...data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, form]);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-4"
      >
        <FormField
          control={form.control}
          name="empresa"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Código *</FormLabel>
              <FormControl>
                <BusinessSelect
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Row justify="space-between" className="gap-2">
          <FormField
            control={form.control}
            name="codigo"
            render={({ field }) => (
              <FormItem className="w-1/2">
                <FormLabel>Código *</FormLabel>
                <FormControl>
                  <Input required {...field} disabled={true} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="cnpj"
            render={({ field }) => (
              <FormItem className="w-1/2">
                <FormLabel>Cnpj *</FormLabel>
                <FormControl>
                  <Input required {...field} disabled={true} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Row>
        <FormField
          control={form.control}
          name="abreviatura_empresa"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Abreviatura Empresa *</FormLabel>
              <FormControl>
                <Input required {...field} disabled={true} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Row className="gap-2">
          <FormField
            control={form.control}
            name="endereco"
            render={({ field }) => (
              <FormItem className="w-5/6">
                <FormLabel>Endereço</FormLabel>
                <FormControl>
                  <Input {...field} maxLength={200} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="numero"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Número</FormLabel>
                <FormControl>
                  <Input {...field} maxLength={200} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Row>
        <FormField
          control={form.control}
          name="bairro"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Bairro</FormLabel>
              <FormControl>
                <Input {...field} maxLength={200} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Row className="gap-2" justify="space-between">
          <FormField
            control={form.control}
            name="estado"
            render={({ field }) => (
              <FormItem className="w-1/2">
                <FormLabel>Estado</FormLabel>
                <FormControl>
                  <StateSelect
                    onValueChange={field.onChange}
                    value={field.value}
                    defaultValue={field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="cidade"
            render={({ field }) => (
              <FormItem className="w-1/2">
                <FormLabel>Cidade</FormLabel>
                <FormControl>
                  <Input {...field} maxLength={200} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Row>
        <Row className="gap-2">
          <FormField
            control={form.control}
            name="contato"
            render={({ field }) => (
              <FormItem className="w-1/2">
                <FormLabel>Contato</FormLabel>
                <FormControl>
                  <Input {...field} maxLength={200} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="w-1/2">
                <FormLabel>E-mail</FormLabel>
                <FormControl>
                  <Input {...field} maxLength={200} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Row>
        <Row justify="end" align="center" className="gap-2 mt-4">
          <Button type="submit" disabled={isPending}>
            <Text color="white">Editar</Text>
          </Button>
        </Row>
      </form>
    </Form>
  );
}

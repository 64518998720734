import { DemandEstimateResponseType } from "@/hooks/useProductionSimulationMutation";

export function getProduct(
  productArray: Array<DemandEstimateResponseType>,
  fkProduct: string
): DemandEstimateResponseType {
  const product = productArray.find(
    (product) => product.FK_produto == fkProduct
  );

  return product as DemandEstimateResponseType;
}

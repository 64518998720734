import { Button } from "@/components/ui/button";
import { TEXT_COLOR } from "@/constants";
import { CellLineValuesType } from "@/hooks/useProductionSimulationMutation";
import { useProductionSimulationPatchLineValueMutation } from "@/hooks/useProductionSimulationPatchLineValueMutation";
import { validationError } from "@/utils/api";
import { GrUpdate } from "react-icons/gr";
import { toast } from "sonner";
import { useProductionPlanContext } from "../../context";

type SyncSimulatorButtonProps = {
  productionPlanLineValues: CellLineValuesType[];
  productionSimulationLineValues: CellLineValuesType[];
  skProductionSimulation: number;
  revision: string;
};

function assingProductionLineValues(
  sourceLineValue: CellLineValuesType[],
  targetLineValue: CellLineValuesType[]
) {
  const targetMap = new Map();

  targetLineValue.forEach((item) => {
    const key = `${item.dia}-${item.FK_fabrica_recurso}-${item.FK_produto}`;
    targetMap.set(key, item);
  });

  sourceLineValue.forEach((sourceItem) => {
    const key = `${sourceItem.dia}-${sourceItem.FK_fabrica_recurso}-${sourceItem.FK_produto}`;

    if (targetMap.has(key)) {
      targetMap.set(key, { ...targetMap.get(key), valor: sourceItem.valor });
    } else {
      targetMap.set(key, sourceItem);
    }
  });
  return Array.from(targetMap.values());
}

export default function SyncSimulatorButton({
  productionPlanLineValues,
  productionSimulationLineValues,
  skProductionSimulation,
  revision,
}: SyncSimulatorButtonProps) {
  const {
    productionPlan: { FK_fabrica },
  } = useProductionPlanContext();
  const { mutateAsync } = useProductionSimulationPatchLineValueMutation({
    factoryKey: FK_fabrica,
  });

  async function handleSyncSimulator() {
    const assignedProductionLineValues = assingProductionLineValues(
      productionPlanLineValues,
      productionSimulationLineValues
    );

    try {
      await mutateAsync({
        fkProductionSimulation: skProductionSimulation,
        lineValues: JSON.stringify(assignedProductionLineValues),
        revision,
      });
      return toast.success("Simulação sincronizada com sucesso!");
    } catch (err) {
      return validationError(err);
    }
  }

  return (
    <Button size="icon" variant="outline" onClick={handleSyncSimulator}>
      <GrUpdate size={20} color={TEXT_COLOR} />
    </Button>
  );
}

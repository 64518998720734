import instance from "@/api/business";
import { useQuery } from "@tanstack/react-query";
import { ApiObjectResponse } from "./useEstimates";

export type ProductionPlanOldType = {
  anteriores: {
    sk_plano_producao_old: number;
    data: string;
    revisao: number;
    edicao: boolean;
    deletado?: string;
    evento?: "VERSION_CHANGE";
    observacao?: string;
    valores_linhas: {
      dia: string;
      valor: string;
      FK_fabrica_recurso: string;
      FK_produto: string;
    }[];
  }[];
};

export const productionPlanOldKey = {
  show: (id) => ["productionPlanOld", id] as const,
};

async function fetchProductionPlanOld({ id }: { id: number }) {
  const response = await instance.post<
    ApiObjectResponse<ProductionPlanOldType>
  >("/planningbeer/productionPlan/old", {
    id,
  });
  return response.data;
}

export function useProductionPlanOld({ id }: { id: number }) {
  return useQuery({
    queryFn: () => fetchProductionPlanOld({ id }),
    queryKey: productionPlanOldKey.show(id),
    gcTime: 0,
    staleTime: 0,
  });
}

import { ProductRealProductionType } from "@/hooks/useProductionSimulationMutation";
import {
  AccumulatedProductionType,
  GetAccumulatedRealProductionProps,
  HasRealProductionProps,
} from "../../types";

export function hasRealProduction({
  date,
  product,
  productRealProduction,
}: HasRealProductionProps) {
  if (!productRealProduction) return { isRealProduction: false };

  const hasRealProduction = productRealProduction.filter(
    (realProduction) =>
      realProduction.dia == date && realProduction.FK_produto == product
  );

  if (hasRealProduction.length > 0)
    return { isRealProduction: true, realProduction: hasRealProduction[0] };

  return { isRealProduction: false };
}

export function calculateAccumulatedRealProduction({
  dates,
  productRealProduction,
}: {
  dates: Array<string>;
  productRealProduction: Array<ProductRealProductionType>;
}): Array<AccumulatedProductionType> {
  const accumulatedProductRealProductionPerDay: Array<AccumulatedProductionType> =
    [];

  productRealProduction.forEach(({ FK_produto }) => {
    if (
      !accumulatedProductRealProductionPerDay.some(
        (item) => item.produto === FK_produto
      )
    ) {
      let accumulatedRealProduction = 0;
      const producao_acumulada = dates.map((date) => {
        const productionValue = productRealProduction.find(
          (prod) => prod.dia === date && prod.FK_produto === FK_produto
        )?.valor;
        accumulatedRealProduction += Number(productionValue) || 0;
        return {
          [date]: {
            valor: accumulatedRealProduction,
          },
        };
      });
      accumulatedProductRealProductionPerDay.push({
        produto: FK_produto,
        producao_acumulada,
      });
    }
  });

  return accumulatedProductRealProductionPerDay;
}

export function getAccumulatedRealProduction({
  accumulatedRealProduction,
  productFk,
  day,
}: GetAccumulatedRealProductionProps) {
  const accumulatedRealProduct = accumulatedRealProduction.find(
    (item) => item.produto == productFk
  );
  if (!accumulatedRealProduct) return 0;
  const accumulatedSaleForDays = accumulatedRealProduct.producao_acumulada.find(
    (item) => item[day]
  );
  const accumulatedProductionForDay = accumulatedSaleForDays?.[day]?.valor || 0;

  return accumulatedProductionForDay;
}

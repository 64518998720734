import { useForm } from "react-hook-form";
import IndisponibilityResourceForm from "..";
import { IndisponibilityResourceFormType, schema } from "../zod";
import useIndisponibilityResourceMutation, {
  IndisponibilityResourceMutationType,
} from "@/hooks/useIndisponibilityResourceMutation";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { validationError } from "@/utils/api";
import { toast } from "sonner";

type CreateIndisponibilityResourceFormProps = {
  handleDialog: () => void;
};

export default function CreateIndisponibilityResourceForm({
  handleDialog,
}: CreateIndisponibilityResourceFormProps) {
  const { isPending, mutateAsync } = useIndisponibilityResourceMutation();
  const form = useForm<IndisponibilityResourceFormType>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
  });

  async function onSubmit(data: IndisponibilityResourceFormType) {
    const { dia_todo } = data;

    const initialValidity = dayjs(data.vigencia_inicio)
      .hour(data.hora_inicio || 0)
      .minute(data.minuto_inicio || 0)
      .second(0);
    const finalValidity = dayjs(data.vigencia_fim)
      .hour(data.hora_fim || 0)
      .minute(data.minuto_fim || 0)
      .second(0);

    const correctedData: IndisponibilityResourceMutationType = {
      FK_fabrica_recurso: data.FK_fabrica_recurso,
      motivo: data.motivo,
      dia_todo: data.dia_todo ? 1 : 0,
      vigencia_inicio: initialValidity.format("YYYY-MM-DD HH:mm:ss"),
      vigencia_fim: dia_todo
        ? finalValidity.hour(23).minute(59).format("YYYY-MM-DD HH:mm:ss")
        : finalValidity.format("YYYY-MM-DD HH:mm:ss"),
    };

    try {
      if (isPending) return;
      await mutateAsync(correctedData);
      toast.success("Indisponibilidade cadastrada com sucesso!");
      return handleDialog();
    } catch (error) {
      return validationError(error);
    }
  }

  return (
    <IndisponibilityResourceForm
      form={form}
      onSubmit={onSubmit}
      button={{ disabled: isPending, title: "Cadastrar" }}
    />
  );
}

import instance from "..";
import { getHeaders } from "@/api/utils";
export interface AuthenticateResponse {
  nomeUsuario: string;
  token: string;
}

interface ValidateResponse {
  token: {
    exp: number;
    iat: number;
    usuario: {
      cod_filial: number;
      cod_usuario: number;
      email: string;
      matricula: string;
      nome: string;
    };
  };
}

export const authenticateUser = async (user: string, password: string) => {
  const response = await instance.post<AuthenticateResponse>(
    "/perfil/usuario",
    {
      usuario: user,
      senha: password,
    },
    { headers: getHeaders() }
  );
  return response.data;
};

export const validateUserToken = async () => {
  const response = await instance.post<ValidateResponse>(
    "/tokenvalidation",
    {},
    {
      headers: {
        "x-funcionalidade": "Carregamento inicial",
        "x-acao": "Carregamento inicial",
        ...getHeaders(),
      },
    }
  );
  return response.data;
};

export const recoverPassword = async (email: string) => {
  const response = await instance.post("/restorepassword", {
    email,
  });

  return response.data;
};

export const changePassword = async (password: string) => {
  const response = await instance.post(
    "/changepassword",
    {
      password,
    },
    { headers: getHeaders() }
  );

  return response.data;
};

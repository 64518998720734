import Text from "@/components/Text/Text";
import dayjs from "dayjs";
import { useSimulationProductionContext } from "../../context";

export default function PeriodSimulationRow() {
  const { simulationData } = useSimulationProductionContext();
  const { simulador_producao } = simulationData;
  const { periodo_fim, periodo_inicio } = simulador_producao;

  return (
    <Text className="font-bold text-sm">
      {dayjs(periodo_inicio).format("DD/MM/YYYY")}
      {" - "}
      {dayjs(periodo_fim).format("DD/MM/YYYY")}
    </Text>
  );
}

import { DialogTypeType, ReactChildrenType } from "@/types";
import { createContext, useContext, useState } from "react";
import { PackagingSupplierTableProps } from "..";

type PackagingSupplierContextProps = {
  visibleDialog: boolean;
  handleDialog: () => void;
  dialogType: DialogTypeType;
  handleDialogType: (type: DialogTypeType) => void;
  packagingSupplier: PackagingSupplierTableProps;
  handlePackagingSupplier: (data: PackagingSupplierTableProps) => void;
  clear: () => void;
};

const PackagingSupplierContext = createContext(
  {} as PackagingSupplierContextProps
);

export default function PackagingResourceProvider({
  children,
}: ReactChildrenType) {
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [packagingSupplier, setPackagingSupplier] =
    useState<PackagingSupplierTableProps>({} as PackagingSupplierTableProps);
  const [dialogType, setDialogType] = useState<DialogTypeType>("nonSelected");

  function handleDialog() {
    setVisibleDialog((prev) => !prev);
  }

  function handleDialogType(value: DialogTypeType) {
    setDialogType(value);
  }

  function handlePackagingSupplier(value: PackagingSupplierTableProps) {
    setPackagingSupplier(value);
  }

  function clear() {
    setPackagingSupplier({} as PackagingSupplierTableProps);
    setDialogType("nonSelected");
    setVisibleDialog(false);
  }

  return (
    <PackagingSupplierContext.Provider
      value={{
        visibleDialog,
        dialogType,
        packagingSupplier,
        clear,
        handlePackagingSupplier,
        handleDialog,
        handleDialogType,
      }}
    >
      {children}
    </PackagingSupplierContext.Provider>
  );
}

export function usePackagingSupplierContext() {
  return useContext(PackagingSupplierContext);
}

import { REQUIRED_FIELD } from "@/constants";
import { z } from "zod";

export const schema = z.object({
  initialPeriod: z.date({ required_error: REQUIRED_FIELD }),
  finalPeriod: z.date({ required_error: REQUIRED_FIELD }),
  fkProductionPlanOld: z.array(z.string(), { required_error: REQUIRED_FIELD }),
});

export type ProgrammedRealProductionFormType = z.infer<typeof schema>;

import instance from "@/api/business";
import { ApiResponse } from "./useEstimates";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

export type ProductType = {
  SK_produto: string;
  descricao?: string;
  ativo?: number;
  tipo_planejador?: string;
  fabricas_recursos?: string;
  fabricas_recursos_array?: Array<string>;
};

async function fetchProducts() {
  const response = await instance.post<ApiResponse<ProductType>>(
    "/planningbeer/product"
  );
  return response.data;
}

export default function useProducts() {
  return useQuery({
    queryKey: ["products"],
    queryFn: () => fetchProducts(),
    placeholderData: keepPreviousData,
  });
}

import { FactoryResourceType } from "@/hooks/useFactoriesResources";
import { ReactChildrenType } from "@/types";
import { createContext, useContext, useState } from "react";

type DialogTypeType = "edit" | "delete" | "detail" | "nonSelected";

type FactoryContextProps = {
  visibleDialog: boolean;
  handleDialog: () => void;
  dialogType: DialogTypeType;
  handleDialogType: (type: DialogTypeType) => void;
  factoryResource: FactoryResourceType;
  handleFactoryResource: (factory: FactoryResourceType) => void;
  clear: () => void;
};

const FactoryResourceContext = createContext({} as FactoryContextProps);

export default function FactoryResourceProvider({
  children,
}: ReactChildrenType) {
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [factoryResource, setFactoryResource] = useState<FactoryResourceType>(
    {} as FactoryResourceType
  );
  const [dialogType, setDialogType] = useState<DialogTypeType>("nonSelected");

  function handleDialog() {
    setVisibleDialog((prev) => !prev);
  }

  function handleDialogType(value: DialogTypeType) {
    setDialogType(value);
  }

  function handleFactoryResource(value: FactoryResourceType) {
    setFactoryResource(value);
  }

  function clear() {
    setFactoryResource({} as FactoryResourceType);
    setDialogType("nonSelected");
    setVisibleDialog(false);
  }

  return (
    <FactoryResourceContext.Provider
      value={{
        clear,
        handleDialogType,
        handleDialog,
        handleFactoryResource,
        factoryResource,
        visibleDialog,
        dialogType,
      }}
    >
      {children}
    </FactoryResourceContext.Provider>
  );
}

export function useFactoryResourceContext() {
  return useContext(FactoryResourceContext);
}

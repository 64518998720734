import { quantityFormater } from "@/utils/number";

type RealConsumptionAccumulatedCellProps = {
  value: number;
};

export default function RealConsumptionAccumulatedCell({
  value,
}: RealConsumptionAccumulatedCellProps) {
  return <span>{quantityFormater(value)}</span>;
}

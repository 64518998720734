import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { productionSimulationsKey } from "./useProductionSimulators";

type ProductionSimulatorStatusMutation = {
  skProductionSimulator: number;
  status: string;
};

export function useProductionSimulatorStatusMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ProductionSimulatorStatusMutation) => {
      return await instance.put("/planningbeer/simulator/status", {
        ...data,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: productionSimulationsKey.all });
    },
  });
}

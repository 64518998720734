import Can from "@/components/Can";
import Col from "@/components/Col";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ROUTES } from "@/constants";
import { BiMessageSquareDetail } from "react-icons/bi";
import { Link } from "react-router-dom";

type DemandPlanningPopoverProps = {
  description: string;
  type: string;
  name: string;
  isAverage?: boolean;
  skForecast: string;
};

export default function DemandPlanningPopover({
  description,
  name,
  type,
  isAverage = false,
  skForecast,
}: DemandPlanningPopoverProps) {
  return (
    <Popover>
      <PopoverTrigger>
        <BiMessageSquareDetail size={18} />
      </PopoverTrigger>
      <PopoverContent className="w-fit">
        <Col className="gap-1">
          <Text>
            Descrição: <strong>{description}</strong>
          </Text>
          <Text>
            Nome: <strong>{name}</strong>
          </Text>
          <Text>
            Tipo: <strong>{type}</strong>
          </Text>
          <Can
            condition={!isAverage}
            onTrue={
              <Link
                to={"/" + ROUTES.forecast.index + "visualizar/" + skForecast}
                className="w-full"
                target="_blank"
              >
                <Button className="mt-2 w-full" variant="outline">
                  <Text>Visualizar</Text>
                </Button>
              </Link>
            }
          />
        </Col>
      </PopoverContent>
    </Popover>
  );
}

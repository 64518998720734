import Can from "@/components/Can";
import EditLineCellPopover from "../Popover/EditLineCellPopover";
import { useSimulationProductionContext } from "../../context";

export type LineCellProps = {
  cellValue: string;
  onChange?: (value?: string) => void;
  disabled?: boolean;
};

export default function LineCell({
  cellValue = "0",
  onChange,
  disabled: disabledCell = false,
}: LineCellProps) {
  const {
    icons: { disabled },
  } = useSimulationProductionContext();

  function onValueChange(value?: string) {
    onChange?.(value);
  }

  return (
    <div className="w-full h-full flex justify-between items-center gap-2">
      <div className="overflow-x-hidden text-ellipsis">{cellValue}</div>
      <div>
        <Can
          condition={!disabled && !disabledCell}
          onTrue={
            <EditLineCellPopover value={cellValue} onChange={onValueChange} />
          }
        />
      </div>
    </div>
  );
}

import DeleteDropdownItem from "@/components/DropdownItem/DeleteDropdownItem";
import EditDropdownItem from "@/components/DropdownItem/EditDropdownItem";
import React from "react";
import { useFactoryResourceContext } from "../../context";
import { FactoryResourceType } from "@/hooks/useFactoriesResources";

export default function ActionDropdown(props: FactoryResourceType) {
  const { handleDialog, handleDialogType, handleFactoryResource } =
    useFactoryResourceContext();

  function handleEdit() {
    handleDialog();
    handleDialogType("edit");
    handleFactoryResource({ ...props });
  }

  function handleDelete() {
    handleDialog();
    handleDialogType("delete");
    handleFactoryResource({ ...props });
  }

  return (
    <React.Fragment>
      <EditDropdownItem onClick={handleEdit} />
      <DeleteDropdownItem onClick={handleDelete} />
    </React.Fragment>
  );
}

import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useResourceContext } from "../../context";
import EditResourceForm from "../Form/EditResourceForm";
import useResource from "@/hooks/useResource";

export default function EditResourceDialog() {
  const { visibleDialog, clear, handleDialog, dialogType, resource } =
    useResourceContext();
  const { data, isFetching } = useResource({ id: Number(resource.SK_recurso) });
  const { data: resourceArrayData } = data || {};
  const resourceDetail = resourceArrayData?.[0];

  function handleEditDialog() {
    handleDialog();
    clear();
  }

  if (dialogType !== "edit" || !resourceDetail || isFetching) return;

  return (
    <Dialog onOpenChange={handleEditDialog} open={visibleDialog}>
      <DialogContent>
        <EditResourceForm
          dialogVisible={handleEditDialog}
          data={resourceDetail}
        />
      </DialogContent>
    </Dialog>
  );
}

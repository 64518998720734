import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { schema } from "./zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Row from "@/components/Row";
import { Button } from "@/components/ui/button";
import Text from "@/components/Text/Text";
import ResourceTypeSelect from "@/components/Select/ResourceTypeSelect";
import useResourceMutation from "@/hooks/useResourceMutation";
import { validationError } from "@/utils/api";
import { toast } from "sonner";
import PackingSelect from "@/components/Select/PackingSelect";

export type ResourceFormType = z.infer<typeof schema>;

export type CreateResourceFormProps = {
  dialogVisible: () => void;
};

export default function CreateResourceForm({
  dialogVisible,
}: CreateResourceFormProps) {
  const { mutateAsync, isPending } = useResourceMutation();
  const form = useForm<ResourceFormType>({
    resolver: zodResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  async function onSubmit(data: ResourceFormType) {
    if (isPending) return;
    try {
      await mutateAsync(data);
      toast.success("Recurso cadastrado com sucesso!");
      return dialogVisible();
    } catch (error) {
      return validationError(error);
    }
  }

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Row className="gap-2">
          <FormField
            control={form.control}
            name="tipo"
            render={({ field }) => (
              <FormItem className="w-1/2">
                <FormLabel>Tipo *</FormLabel>
                <FormControl>
                  <ResourceTypeSelect
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="tipo_de_embalagem"
            render={({ field }) => (
              <FormItem className="w-1/2">
                <FormLabel>Tipo de Embalagem*</FormLabel>
                <FormControl>
                  <PackingSelect
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Row>
        <FormField
          control={form.control}
          name="descricao"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Descrição *</FormLabel>
              <FormControl>
                <Input {...field} maxLength={200} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="definicao"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Abreviatura *</FormLabel>
              <FormControl>
                <Input {...field} maxLength={200} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="codigo_centro_de_custo"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Código do Centro de Custo</FormLabel>
              <FormControl>
                <Input {...field} maxLength={200} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {/* <FormField
            control={form.control}
            name="codigo_linha_de_producao"
            render={({ field }) => (
              <FormItem className="w-1/2">
                <FormLabel>Código da Linha de Produção</FormLabel>
                <FormControl>
                  <Input {...field} maxLength={200} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          /> */}
        <Row justify="end" align="center" className="gap-2 mt-4">
          <Button type="submit" disabled={isPending}>
            <Text color="white">Cadastrar</Text>
          </Button>
        </Row>
      </form>
    </Form>
  );
}

import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { useState } from "react";
import CreateResourceButton from "../Button/CreateResourceButton";
import CreateResourceForm from "../Form/CreateResourceForm";

export default function CreateResourceDialog() {
  const [open, setOpen] = useState(false);

  function handleDialog() {
    setOpen((prev) => !prev);
  }

  return (
    <Dialog onOpenChange={handleDialog} open={open}>
      <DialogTrigger>
        <CreateResourceButton />
      </DialogTrigger>
      <DialogContent>
        <CreateResourceForm dialogVisible={handleDialog} />
      </DialogContent>
    </Dialog>
  );
}

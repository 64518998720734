import { quantityFormater } from "@/utils/number";

type RealConsumptionCellProps = {
  value: string | number;
};

export default function RealConsumptionCell({
  value,
}: RealConsumptionCellProps) {
  if (value == 0) return <span>{value}</span>;
  return <span className="font-bold">{quantityFormater(Number(value))}</span>;
}

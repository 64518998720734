import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  indisponibilityResourceKey,
  IndisponibilityResourceType,
} from "./useIndisponibilityResources";

export type IndisponibilityResourceMutationType = Omit<
  IndisponibilityResourceType,
  "SK_indisponibilidade_programada"
>;

export default function useIndisponibilityResourceMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IndisponibilityResourceMutationType) => {
      return instance.put("/planningbeer/indisponibilityResource", { ...data });
    },

    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: indisponibilityResourceKey.all,
      });
    },
  });
}

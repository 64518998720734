import { quantityFormater } from "@/utils/number";
import { MappingRowsType } from "../types";

export default function mappingRows({
  row,
  composicao,
  descricao,
}: MappingRowsType) {
  return row.reduce((_, { options, st }) => {
    return {
      composicao: composicao || "",
      descricao: descricao || "",
      st: st,
      options: options,
      total: options.total ? quantityFormater(Number(options.total)) : "",
    };
  }, {});
}

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { packagingsKey } from "./usePackagings";
import instance from "@/api/business";

type PackagingPatchType = {
  skPackaging: Array<string>;
};

export default function usePackagingPatchMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: PackagingPatchType) => {
      return instance.patch("/planningbeer/packaging", { ...data });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: packagingsKey.all });
    },
  });
}

import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { ROUTES } from "@/constants";
import { Link } from "react-router-dom";
import { useProductionPlanContext } from "../../context";
import { useSimulationProductionContext } from "@/pages/Simulacoes/context";

export default function ProductionPlanError() {
  const { clear: productionPlanClear } = useProductionPlanContext();
  const { clear: productionSimulationClear } = useSimulationProductionContext();

  return (
    <div className="flex h-full items-center justify-center">
      <div className="flex flex-col items-center">
        <Text className="text-lg font-bold">
          Ocorreu um problema durante a busca do plano de produção
        </Text>
        <Link
          className="mt-4"
          to={"/" + ROUTES.productionPlan.index}
          onClick={() => {
            productionPlanClear();
            productionSimulationClear();
          }}
        >
          <Button>Ir Para Planos de Produção</Button>
        </Link>
      </div>
    </div>
  );
}

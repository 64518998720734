import { useForm } from "react-hook-form";
import { ResourceTypeFormType, schema } from "../zod";
import { zodResolver } from "@hookform/resolvers/zod";
import ResourceTypeForm from "..";
import useConfigurationMutation from "@/hooks/useConfigurationMutation";
import { CONFIGURATION_TABLES_CODE } from "@/constants";
import { validationError } from "@/utils/api";
import { toast } from "sonner";
import { DialogProps } from "@/types";

export default function CreateResourceTypeForm({ handleClose }: DialogProps) {
  const { mutateAsync, isPending } = useConfigurationMutation({
    tabela_codigo: CONFIGURATION_TABLES_CODE.dim_tipo_recursos.codigo,
  });
  const form = useForm<ResourceTypeFormType>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
  });

  async function handleSubmit(formData: ResourceTypeFormType) {
    if (isPending) return;
    try {
      await mutateAsync({
        tabela_codigo: CONFIGURATION_TABLES_CODE.dim_tipo_recursos.codigo,
        tabela_nome: CONFIGURATION_TABLES_CODE.dim_tipo_recursos.nome,
        chave: formData.valor.toUpperCase(),
        valor: formData.valor.toUpperCase(),
      });
      toast.success("Tipo de recurso cadastrado com sucesso!");
      return handleClose();
    } catch (error) {
      return validationError(error);
    }
  }

  return (
    <ResourceTypeForm
      form={form}
      onSubmit={handleSubmit}
      button={{ disabled: isPending, title: "Cadastrar" }}
    />
  );
}

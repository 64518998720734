import { REQUIRED_FIELD } from "@/constants";
import { z } from "zod";

export const schema = z.object({
  FK_fabrica_recurso: z.string({ required_error: REQUIRED_FIELD }),
  vigencia_inicio: z.date({ required_error: REQUIRED_FIELD }),
  vigencia_fim: z.date({ required_error: REQUIRED_FIELD }),
  motivo: z.string({ required_error: REQUIRED_FIELD }).max(200),
  hora_inicio: z.number().optional(),
  minuto_inicio: z.number().optional(),
  hora_fim: z.number().optional(),
  minuto_fim: z.number().optional(),
  dia_todo: z.boolean().optional(),
});

export type IndisponibilityResourceFormType = z.infer<typeof schema>;

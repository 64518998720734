import instance from "@/api/business";
import { ApiResponse } from "./useEstimates";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

export type ResourcesType = {
  SK_recurso: string | number;
  codigo_unidade_fabril?: string;
  codigo_linha_de_producao?: string;
  codigo_centro_de_custo?: string;
  tipo_de_embalagem?: string;
  tipo?: string;
  descricao?: string;
  definicao?: string;
  pode_editar?: string;
  pode_excluir?: string;
};

export const resourceKeys = {
  all: ["resources"] as const,
  detail: (id: number) => [...resourceKeys.all, "show", id] as const,
};

async function fetchResources() {
  const response = await instance.post<ApiResponse<ResourcesType>>(
    "/planningbeer/resource/"
  );
  return response.data;
}

export default function useResources() {
  return useQuery({
    queryKey: resourceKeys.all,
    queryFn: () => fetchResources(),
    placeholderData: keepPreviousData,
  });
}

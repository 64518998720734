import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  factoryResourceKey,
  FactoryResourceType,
} from "./useFactoriesResources";

export default function useFactoryResourcePatchMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: FactoryResourceType) => {
      return instance.patch("/planningbeer/factoryResource", { ...data });
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: factoryResourceKey.all });
    },
  });
}

import { DemandEstimateResponseType } from "@/hooks/useProductionSimulationMutation";
import {
  FindProductInventoryType,
  GetProductInventoryType,
  ProductGridSaleType,
} from "../types";

export function getProductInventory(
  previsaoDemanda: Array<DemandEstimateResponseType>
) {
  const inventoryList: Array<GetProductInventoryType> = [];

  previsaoDemanda.forEach((item) => {
    const product = item.FK_produto.toString();
    const demandPlanningQuantity = item.quantidades_por_mes;

    const inventory = item.inventario.map((product) => {
      return {
        dia: product.vigencia,
        quantidade: product.quantidade.toString(),
      };
    });

    const inventoryItem = {
      produto: product,
      saldo_inicial: inventory,
      previsao_demanda: demandPlanningQuantity,
    };
    inventoryList.push(inventoryItem);
  });

  return inventoryList;
}

export function findInventoryProduct({
  array,
  product,
}: {
  array?: Array<FindProductInventoryType>;
  product: string;
}) {
  const inventoryProduct = array?.find(
    (item) => item?.produto == product
  ) as FindProductInventoryType;
  return inventoryProduct;
}

export function getInventoryProduct({
  inventory,
  product,
  day,
}: {
  inventory?: FindProductInventoryType[];
  product: string;
  day: string;
}) {
  const inventoryProduct = findInventoryProduct({
    array: inventory,
    product: product,
  });
  if (!inventoryProduct) return { atual: 0, restante: 0 };
  const inventoryDays = inventoryProduct.inventario.find((item) => item[day]);
  const inventoryForDay = inventoryDays?.[day] || { atual: 0, restante: 0 };

  return inventoryForDay;
}

export type AccumulatedSaleType = {
  produto: string;
  venda_acumulada: Array<{ [key: string]: { valor: number } }>;
};

export function calculateDailyAccumulatedGridSale({
  dates,
  gridSale,
  demandPlanning,
}: {
  dates: Array<string>;
  gridSale: Array<ProductGridSaleType>;
  demandPlanning: Array<DemandEstimateResponseType>;
}): Array<AccumulatedSaleType> {
  const accumulatedProductGridSalePerDay = demandPlanning.map(
    ({ FK_produto }) => {
      let accumulatedGridSale = 0;
      const accumulatedGridSalePerDay = dates.map((date) => {
        const gridSaleValue = gridSale.find(
          (sale) => sale.dia === date && sale.FK_produto == FK_produto
        )?.valor;
        accumulatedGridSale += Number(gridSaleValue) || 0;
        const accumulated = {
          [date]: {
            valor: accumulatedGridSale,
          },
        };
        return accumulated;
      });
      return {
        produto: FK_produto,
        venda_acumulada: accumulatedGridSalePerDay,
      };
    }
  );
  return accumulatedProductGridSalePerDay;
}

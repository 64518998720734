import { useQueryClient } from "@tanstack/react-query";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { SelectProps } from "@radix-ui/react-select";
import Each from "../Each";
import {
  productionSimulationsKey,
  ProductionSimulationType,
} from "@/hooks/useProductionSimulators";
import dayjs from "dayjs";

export type ProductionSimulatorData = {
  data: Array<ProductionSimulationType>;
};

export default function ProductionSimulatorSelect(props: SelectProps) {
  const queryClient = useQueryClient();

  const productionSimulator: ProductionSimulatorData | undefined =
    queryClient.getQueryData(productionSimulationsKey.all);
  const { data: factory } = productionSimulator || {};
  if (!factory) return null;

  return (
    <Select
      onValueChange={props.onValueChange}
      defaultValue={props.defaultValue}
      value={props.value}
    >
      <SelectTrigger>
        <SelectValue placeholder="" />
      </SelectTrigger>
      <SelectContent>
        <Each
          array={factory}
          renderItem={(props) => {
            return (
              <SelectItem value={String(props.SK_simulacao)}>
                {props.SK_simulacao} {props.descricao}
                {" - "}
                {props.codigo}
                {", "}
                {props.abreviatura_empresa}{" "}
                {dayjs(props.periodo_inicio, "YYYY-MM-DD").format("DD/MM/YYYY")}
                {" - "}
                {dayjs(props.periodo_fim, "YYYY-MM-DD").format("DD/MM/YYYY")}
              </SelectItem>
            );
          }}
        />
      </SelectContent>
    </Select>
  );
}

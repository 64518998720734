import { useForm } from "react-hook-form";
import FactoryResourceForm from "..";
import { z } from "zod";
import { schema } from "../zod";
import { zodResolver } from "@hookform/resolvers/zod";
import useFactoryResourceMutation from "@/hooks/useFactoryResourceMutation";
import { validationError } from "@/utils/api";
import { toast } from "sonner";
import dayjs from "dayjs";

export type FactoryResourceFormType = z.infer<typeof schema>;

type CreateFactoryResourceFormProps = {
  dialogVisiblity: () => void;
};

export default function CreateFactoryResourceForm({
  dialogVisiblity,
}: CreateFactoryResourceFormProps) {
  const { mutateAsync, isPending } = useFactoryResourceMutation();
  const form = useForm<FactoryResourceFormType>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
  });

  async function onSubmit(data: FactoryResourceFormType) {
    if (isPending) return;
    try {
      await mutateAsync({
        ...data,
        vigencia_inicio: data.vigencia_inicio
          ? dayjs(data.vigencia_inicio).format("YYYY-MM-DD")
          : undefined,
        vigencia_fim: data.vigencia_inicio
          ? dayjs(data.vigencia_fim).format("YYYY-MM-DD")
          : undefined,
      });
      dialogVisiblity();
      return toast.success("Recurso da fábrica criado com sucesso!");
    } catch (error) {
      return validationError(error);
    }
  }

  return (
    <FactoryResourceForm
      form={form}
      onSubmit={onSubmit}
      button={{ disabled: isPending, title: "Cadastrar" }}
    />
  );
}

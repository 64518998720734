export default function DotsLoading() {
  return (
    <div className="flex space-x-2 justify-center items-center bg-white dark:invert h-full">
      <span className="sr-only">Carregando...</span>
      <div className="h-8 w-8 bg-gray-400 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
      <div className="h-8 w-8 bg-gray-400 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
      <div className="h-8 w-8 bg-gray-400 rounded-full animate-bounce"></div>
    </div>
  );
}

import { IndisponibilityResourceType } from "@/hooks/useIndisponibilityResources";
import { ReactChildrenType } from "@/types";
import { createContext, useContext, useState } from "react";

type DialogTypeType = "edit" | "delete" | "detail" | "nonSelected";

type IndisponibilityResourceContextProps = {
  visibleDialog: boolean;
  handleDialog: () => void;
  dialogType: DialogTypeType;
  handleDialogType: (type: DialogTypeType) => void;
  indisponibilityResource: IndisponibilityResourceType;
  handleIndisponibilityResource: (factory: IndisponibilityResourceType) => void;
  clear: () => void;
};

const IndisponibilityResourceContext = createContext(
  {} as IndisponibilityResourceContextProps
);

export default function IndisponibilityResourceProvider({
  children,
}: ReactChildrenType) {
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [indisponibilityResource, setIndisponibilityResource] =
    useState<IndisponibilityResourceType>({} as IndisponibilityResourceType);
  const [dialogType, setDialogType] = useState<DialogTypeType>("nonSelected");

  function handleDialog() {
    setVisibleDialog((prev) => !prev);
  }

  function handleDialogType(value: DialogTypeType) {
    setDialogType(value);
  }

  function handleIndisponibilityResource(value: IndisponibilityResourceType) {
    setIndisponibilityResource(value);
  }

  function clear() {
    setIndisponibilityResource({} as IndisponibilityResourceType);
    setDialogType("nonSelected");
    setVisibleDialog(false);
  }

  return (
    <IndisponibilityResourceContext.Provider
      value={{
        clear,
        handleDialogType,
        handleDialog,
        handleIndisponibilityResource,
        indisponibilityResource,
        visibleDialog,
        dialogType,
      }}
    >
      {children}
    </IndisponibilityResourceContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useIndisponibilityResourceContext() {
  return useContext(IndisponibilityResourceContext);
}

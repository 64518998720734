import Text from "@/components/Text/Text";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { UseFormReturn } from "react-hook-form";
import { ProductInventoryPolicyFormType } from "./zod";
import NumberMaskInput from "@/components/Input/NumberMaskInput";

type ProductInventoryPolicyFormProps = {
  form: UseFormReturn<ProductInventoryPolicyFormType, any, undefined>;
  onSubmit: (data: ProductInventoryPolicyFormType) => void;
};

export default function ProductInventoryPolicyForm({
  form,
  onSubmit,
}: ProductInventoryPolicyFormProps) {
  return (
    <Form {...form}>
      <form
        className="flex flex-row gap-4 items-center"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name="min"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <Text>Estoque Mínimo *</Text>
              </FormLabel>
              <FormControl>
                <NumberMaskInput
                  onChange={field.onChange}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="max"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <Text>Estoque Máximo *</Text>
              </FormLabel>
              <FormControl>
                <NumberMaskInput
                  onChange={field.onChange}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="refill"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <Text>Ponto de Ressuprimento *</Text>
              </FormLabel>
              <FormControl>
                <NumberMaskInput
                  onChange={field.onChange}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
    </Form>
  );
}

export type DiffType = {
  type: "added" | "removed" | "modified";
  item: any;
  changes?: Record<string, { from: any; to: any }>;
};

export function diffArrays(array1, array2): DiffType[] {
  const diffs: DiffType[] = [];

  const generateKey = (item) =>
    `${item.dia}_${item.FK_fabrica_recurso}_${item.FK_produto}`;

  const mapArray2 = new Map(array2.map((item) => [generateKey(item), item]));

  array1.forEach((item1) => {
    const key = generateKey(item1);
    const item2 = mapArray2.get(key);

    if (!item2) {
      diffs.push({ type: "removed", item: item1 });
    } else if (JSON.stringify(item1) !== JSON.stringify(item2)) {
      const changes = {};
      Object.keys(item1).forEach((key) => {
        if (item1[key] !== item2[key]) {
          changes[key] = { from: item1[key], to: item2[key] };
        }
      });
      diffs.push({ type: "modified", item: item1, changes });
    }
    mapArray2.delete(key);
  });
  mapArray2.forEach((item) => {
    diffs.push({ type: "added", item });
  });

  return diffs;
}

import Row from "@/components/Row";
import Text from "@/components/Text/Text";

type ProgrammedRealProductionTableHeaderProps = {
  title: React.ReactNode;
  rowTitle?: {
    left?: string;
    right?: string;
  };
};

export default function ProgrammedRealProductionTableHeader({
  title,
  rowTitle = {
    left: "Quantidade",
    right: "HL",
  },
}: ProgrammedRealProductionTableHeaderProps) {
  return (
    <>
      <Row className="mb-4" justify="center" align="center">
        <Text>{title}</Text>
      </Row>
      <Row align="center" className="border-1 border w-full">
        <Text className="w-1/2 text-sm text-center font-bold">
          {rowTitle.left}
        </Text>
        <Text className="w-1/2 text-sm text-center font-bold">
          {rowTitle.right}
        </Text>
      </Row>
    </>
  );
}

import ChangePasswordForm from "@/pages/AlterarSenha/components/Form/ChangePasswordForm";
import Card from "./Card";

export default function ChangePasswordCard() {
  return (
    <Card width={450}>
      <ChangePasswordForm />
    </Card>
  );
}

import TableContainer from "@/components/Container/TableContainer";
import { usePackagingSimulationContext } from "../../context";
import { createColumnHelper } from "@tanstack/react-table";
import { TableData } from "@/components/NewTable/type";
import Table from "@/components/NewTable";
import { getPeriodDays } from "@/pages/Simulacoes/utils/date";
import dayjs from "dayjs";
import Text from "@/components/Text/Text";
import { useMemo } from "react";
import mappingRows from "../../utils/getRows";
import { OptionsType } from "@/hooks/usePackagingSimulationMutation";
import Cell from "../Cell";
import { calculateInventory } from "../../utils/inventory";
import Row from "@/components/Row";
import ProductionSimulationRow from "../Row/ProductionSimulationRow";
import { quantityFormater } from "@/utils/number";

export type PackagingSimulationTableType = {
  composicao: string;
  descricao: string;
  st: string;
  sk_embalagem_fornecedor?: number;
  total?: string;
} & OptionsType;

type SeePackagingSimulationTableType = TableData<PackagingSimulationTableType>;
const columnHelper = createColumnHelper<SeePackagingSimulationTableType>();

export default function PackagingSimulationTable() {
  const {
    packagingSimulationData,
    packagingSupplier: { packagingSupplierCellValue },
  } = usePackagingSimulationContext();
  const { simulacao_producao, embalagens, meta } = packagingSimulationData;
  const {
    saldo_inicial,
    previsao_consumo,
    consumo_real,
    consumo_acumulado,
    coleta_real,
    coleta_acumulado,
  } = meta || {};
  const { periodo_fim, periodo_inicio } = simulacao_producao || {};

  const periodDays = getPeriodDays({
    finalPeriod: periodo_fim,
    initialPeriod: periodo_inicio,
  });

  const inventory = calculateInventory({
    consumptionForecast: previsao_consumo,
    packagings: embalagens,
    initialBalance: saldo_inicial,
    packagingSupplierCellValues: packagingSupplierCellValue,
    realConsumption: consumo_real,
    realCollection: coleta_real,
    periodDays,
  });

  const columns = [
    columnHelper.accessor("composicao", {
      header: "Código Componente",
      id: "composicao",
      size: 150,
      meta: {
        enableColumnOrdering: true,
        row: {
          isGrouped: true,
        },
      },
    }),
    columnHelper.accessor("descricao", {
      header: "Descrição Componente",
      id: "descricao",
      size: 300,
    }),
    columnHelper.accessor("total", {
      header: "Total",
      id: "total",
      size: 120,
    }),
    columnHelper.accessor("st", {
      header: "Status",
      id: "st",
      size: 200,
    }),
    ...periodDays.map((day) => {
      return columnHelper.display({
        id: day,
        meta: {
          enableMenu: false,
          enableColumnOrdering: false,
        },
        header: () => (
          <div className="flex flex-col p-0 justify-center align-middle w-full">
            <Text className="font-bold">
              {dayjs(day).locale("pt-BR").format("DD/MM/YYYY")}
            </Text>
            <Text>{dayjs(day).locale("pt-BR").format("dddd")}</Text>
          </div>
        ),
        cell: ({ row }) => {
          const { depth } = row;
          if (depth == 0) return;
          const {
            options: { isEditable = false },
            sk_embalagem_fornecedor,
            composicao,
            st,
          } = row.original;

          return (
            <Cell
              inventory={inventory}
              composition={composicao}
              st={st}
              day={day}
              isEditable={isEditable}
              cell={{
                packagingSupplierRow: {
                  skPackagingSupplier: sk_embalagem_fornecedor,
                },
                consumptionForecastRow: {
                  consumption: previsao_consumo,
                },
                realConsumptionRow: {
                  realConsumption: consumo_real,
                },
                realConsumptionAccumulatedRow: {
                  realConsumptionAccumulated: consumo_acumulado,
                },
                realCollectionRow: {
                  realCollection: coleta_real,
                },
                realCollectionAccumulatedRow: {
                  realCollectionAccumulated: coleta_acumulado,
                },
              }}
            />
          );
        },
      });
    }),
  ];

  const dataSubRows = useMemo(
    () =>
      embalagens?.map(
        ({
          composicao,
          descricao,
          meta: {
            fornecedores = [],
            previsao_consumo = [],
            saldo_inicial = [],
            coleta_programada = [],
            coleta_real = [],
            coleta_acumulada = [],
            consumo_acumulado = [],
            consumo_real = [],
            saldo_final = [],
          },
        }) => {
          return {
            composicao: composicao,
            descricao: descricao,
            total: previsao_consumo.map((consumption) =>
              quantityFormater(Number(consumption.options.total))
            ),
            subRows: [
              mappingRows({
                row: saldo_inicial,
                composicao: composicao,
                descricao: descricao,
              }),
              mappingRows({
                row: previsao_consumo,
                composicao: composicao,
                descricao: descricao,
              }),
              mappingRows({
                row: consumo_real,
                composicao: composicao,
                descricao: descricao,
              }),
              mappingRows({
                row: consumo_acumulado,
                composicao: composicao,
                descricao: descricao,
              }),
              ...fornecedores.map((fornecedor) => {
                return {
                  composicao: composicao,
                  descricao: descricao,
                  sk_embalagem_fornecedor: fornecedor.SK_embalagem_fornecedor,
                  st: fornecedor.nome_reduzido,
                  options: fornecedor.options,
                };
              }),
              mappingRows({
                row: coleta_programada,
                composicao: composicao,
                descricao: descricao,
              }),
              mappingRows({
                row: coleta_real,
                composicao: composicao,
                descricao: descricao,
              }),
              mappingRows({
                row: coleta_acumulada,
                composicao: composicao,
                descricao: descricao,
              }),
              mappingRows({
                row: saldo_final,
                composicao: composicao,
                descricao: descricao,
              }),
            ],
          };
        }
      ),
    [embalagens]
  );

  if (!packagingSimulationData.simulacao_producao) return;
  return (
    <TableContainer className="max-h-[calc(100vh-200px)] h-full">
      <Row>
        <ProductionSimulationRow />
      </Row>
      <Table<SeePackagingSimulationTableType>
        virtualize={true}
        data={dataSubRows as unknown as PackagingSimulationTableType[]}
        columns={columns}
        meta={{
          layout: "stretch",
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
        tableState={{
          columnPinning: {
            left: ["composicao", "descricao", "st", "total"],
          },
        }}
      />
    </TableContainer>
  );
}

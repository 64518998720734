import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { estimates } from "./useEstimates";

export type EstimateStatusPatchMutationType = {
  id: number;
  status: "RASCUNHO" | "APROVADO" | "PUBLICADO" | "ACEITO";
  isRejection?: boolean;
};

export default function useEstimateStatusMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: EstimateStatusPatchMutationType) => {
      return instance.patch("/planningbeer/forecast/status", { ...data });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: estimates.all });
    },
  });
}

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { useAlertDialogContext } from "../../context/AlertDialogContext";
import useEstimateDeleteMutation from "@/hooks/useEstimateDeleteMutation";
import { toast } from "sonner";
import i18n from "@/i18n";
import { validationError } from "@/utils/api";

export default function DeleteDialogAlert() {
  const { mutateAsync } = useEstimateDeleteMutation();
  const { handleDialogVisibility, clear, visibleDialog, sheet } =
    useAlertDialogContext();

  function handleDialogAlert() {
    handleDialogVisibility();
    clear();
  }

  async function handleDelete() {
    try {
      await mutateAsync({ id: sheet.SK_planejamento_demanda });
      handleDialogAlert();
      return toast.success(i18n.exclusionSuccess);
    } catch (error) {
      return validationError(error);
    }
  }

  if (sheet.tipo !== "delete") return null;
  return (
    <AlertDialog open={visibleDialog} onOpenChange={handleDialogAlert}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Exclusão</AlertDialogTitle>
          <AlertDialogDescription>
            Confirma a exclusão da planilha "{sheet.nome_planejamento}"? Essa
            ação é irreversível.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={handleDialogAlert}>
            Cancelar
          </AlertDialogCancel>
          <AlertDialogAction onClick={handleDelete}>Excluir</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

import instance from "@/api/business";
import { UpdatedValuesType } from "@/pages/Inventario/Visualizar";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { inventoryKeys } from "./useInventory";

export type InventoryItemPatchMutation = {
  rows: Array<UpdatedValuesType>;
  skInventoryCharge: number;
};

export default function useInventoryItemPatchMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: InventoryItemPatchMutation) => {
      return instance.patch("/planningbeer/inventory/detail", { ...data });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: inventoryKeys.all });
    },
  });
}

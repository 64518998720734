import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { ApiResponse } from "./useEstimates";
import { inventoryKeys } from "./useInventory";
import instance from "@/api/business";

export type InventoryItemTableType = InventoryItemResponseType;

export type InventoryItemResponseType = {
  SK_inventario: string;
  FK_produto: string;
  FK_inventario_carga: number;
  descricao: string;
  filial_origem: number;
  quantidade: string;
  vigencia: string;
  cnpj_fabrica: string;
  nome_fabrica: string;
  codigo_fabrica: string;
};

async function fetchInventoryItems({ id }: { id: string }) {
  if (!id) return;
  const response = await instance.post<ApiResponse<InventoryItemResponseType>>(
    "/planningbeer/inventory/detail",
    { id }
  );
  return response.data;
}

export function useInventoryItems({ id }: { id: string }) {
  return useQuery({
    queryFn: () => fetchInventoryItems({ id }),
    queryKey: inventoryKeys.detail(id),
    placeholderData: keepPreviousData,
  });
}

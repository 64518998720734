import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { schema } from "../../form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import Text from "@/components/Text/Text";
import { changePassword } from "@/api/auth/user";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/constants";
import { transformApiError } from "@/utils/api";
import { toast } from "sonner";
import { useLoadingContext } from "@/context/LoadingContext";

export type ChangePasswordFormDataType = z.infer<typeof schema>;

export default function ChangePasswordForm() {
  const navigate = useNavigate();
  const { handleLoading, isLoading } = useLoadingContext();
  const form = useForm<ChangePasswordFormDataType>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
  });
  async function handleForm(data: ChangePasswordFormDataType) {
    if (isLoading) return;
    handleLoading(true);
    try {
      await changePassword(data.senha);
      form.reset();
      toast.success("Senha alterada com sucesso!");
      return navigate("/" + ROUTES.initial);
    } catch (error) {
      transformApiError(error);
    } finally {
      handleLoading(false);
    }
  }

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(handleForm)}
      >
        <FormField
          control={form.control}
          name="senha"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input label="Senha *" type="password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="confirmar_senha"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input label="Confirmar Senha *" type="password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button variant="default" type="submit">
          <Text color="white">Alterar</Text>
        </Button>
      </form>
    </Form>
  );
}

import { REQUIRED_FIELD } from "@/constants";
import { z } from "zod";

export const schema = z.object({
  // codigo_unidade_fabril: z.string({ required_error: REQUIRED_FIELD }).max(255),
  descricao: z.string({ required_error: REQUIRED_FIELD }).max(255),
  tipo: z.string({ required_error: REQUIRED_FIELD }).max(255),
  definicao: z.string({ required_error: REQUIRED_FIELD }).max(255),
  // codigo_linha_de_producao: z.string().max(255).optional(),
  codigo_centro_de_custo: z.string().max(255).optional(),
  tipo_de_embalagem: z.string({ required_error: REQUIRED_FIELD }).max(255),
});

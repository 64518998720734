import { SelectProps } from "@radix-ui/react-select";
import Each from "../Each";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

export default function YearSelect(props: SelectProps) {
  const currentYear = new Date().getFullYear();
  const yearsAhead = Array.from(
    { length: 11 },
    (_, index) => currentYear + index,
  );

  function handleYearSelect(year: number) {
    return <SelectItem value={year.toString()}>{year}</SelectItem>;
  }

  return (
    <Select
      onValueChange={props.onValueChange}
      defaultValue={props.defaultValue}
    >
      <SelectTrigger>
        <SelectValue defaultValue={currentYear} placeholder={currentYear} />
      </SelectTrigger>
      <SelectContent>
        <Each array={yearsAhead} renderItem={handleYearSelect} />
      </SelectContent>
    </Select>
  );
}

import DeleteDropdownItem from "@/components/DropdownItem/DeleteDropdownItem";
import EditDropdownItem from "@/components/DropdownItem/EditDropdownItem";
import React from "react";
import { useIndisponibilityResourceContext } from "../../context";
import { IndisponibilityResourceType } from "@/hooks/useIndisponibilityResources";

export default function ActionDropdown(props: IndisponibilityResourceType) {
  const { handleDialog, handleDialogType, handleIndisponibilityResource } =
    useIndisponibilityResourceContext();

  function handleEdit() {
    handleDialog();
    handleDialogType("edit");
    handleIndisponibilityResource({ ...props });
  }

  function handleDelete() {
    handleDialog();
    handleDialogType("delete");
    handleIndisponibilityResource({ ...props });
  }
  return (
    <React.Fragment>
      <EditDropdownItem onClick={handleEdit} />
      <DeleteDropdownItem onClick={handleDelete} />
    </React.Fragment>
  );
}

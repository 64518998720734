import instance from "@/api/business";
import { useMutation } from "@tanstack/react-query";
import { ProductionSimulatorType } from "./useProductionSimulatorMutation";

type ProdutionSimulatiorPatchType = {
  SK_simulacao: number;
} & ProductionSimulatorType;

export default function useProductionSimulatorPatchMutation() {
  return useMutation({
    mutationFn: (data: ProdutionSimulatiorPatchType) => {
      return instance.patch("/planningbeer/simulator", { ...data });
    },
  });
}

import { z } from "zod";
import Row from "../Row";
import Text from "../Text/Text";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import Card from "./Card";
import { schema } from "@/pages/Login/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../ui/form";
import { authenticateUser } from "@/api/auth/user";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MAX_AGE, ROUTES } from "@/constants";
import Cookies from "js-cookie";
import { setItem } from "@/utils/storage";
import { transformApiError } from "@/utils/api";

export type LoginFormDataType = z.infer<typeof schema>;

export default function LoginCard() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  async function handleSubmit(data: LoginFormDataType) {
    if (isLoading) return;
    try {
      setIsLoading(true);
      const { token, nomeUsuario } = await authenticateUser(
        data.usuario,
        data.senha
      );
      Cookies.set("token", token, { expires: MAX_AGE });
      setItem(localStorage, "user", JSON.stringify({ name: nomeUsuario }));
      return navigate("/" + ROUTES.initial);
    } catch (error) {
      transformApiError(error);
    } finally {
      setIsLoading(false);
    }
  }

  const form = useForm<LoginFormDataType>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
  });

  return (
    <Card width={400}>
      <Form {...form}>
        <form
          className="flex flex-col gap-4"
          onSubmit={form.handleSubmit(handleSubmit)}
        >
          <FormField
            control={form.control}
            name="usuario"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input label="Usuário *" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="senha"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input label="Senha *" type="password" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Row justify="end">
            <Text
              className="text-sm"
              color="primary"
              onClick={() => {
                navigate("/" + ROUTES.resetPassword);
              }}
            >
              esqueceu sua senha?
            </Text>
          </Row>
          <Button variant="default" type="submit" disabled={isLoading}>
            <Text color="white">Entrar</Text>
          </Button>
        </form>
      </Form>
      <Row align="center" justify="center">
        <Text className="text-[10px] mt-4" color="text">
          {import.meta.env.VITE_VERSION}
        </Text>
      </Row>
    </Card>
  );
}

import { GetConsumptionRowProps } from "../types";
import { getRealConsumption } from "./realConsumption";

export function getConsumption({
  composition,
  consumptionForecast,
  realConsumption,
  day,
}: GetConsumptionRowProps) {
  const { realConsumptionValue } = getRealConsumption({
    composition,
    day,
    realConsumption,
  });

  if (realConsumptionValue) {
    return {
      consumption: realConsumptionValue,
      isSimulated: false,
      production: 0,
    };
  }

  const consumptionRow = consumptionForecast.find(
    (consumption) =>
      consumption.composicao == composition && consumption.dia == day
  );

  const consumption = consumptionRow?.previsao_consumo_total_componentes || 0;

  return {
    consumption,
    isSimulated: consumptionRow?.meta?.simulado || false,
    productionTotal: consumptionRow?.producao_total || 0,
  };
}

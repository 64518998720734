import { SHEET_INVENTORY_HASH } from "@/constants";
import { read, utils, writeFile } from "xlsx";

export type SheetColumnsType = {
  Código: string;
  Quantidade: number | string;
};

export function handleSheetModel() {
  const workbook = utils.book_new();
  const worksheet = utils.aoa_to_sheet([[]]);

  utils.book_append_sheet(workbook, worksheet, "Modelo");
  utils.book_append_sheet(workbook, worksheet, SHEET_INVENTORY_HASH);

  const id = workbook.SheetNames.indexOf(SHEET_INVENTORY_HASH);
  if (!workbook.Workbook) workbook.Workbook = {};
  if (!workbook.Workbook?.Sheets) workbook.Workbook.Sheets = [];
  if (!workbook.Workbook?.Sheets?.[id]) workbook.Workbook.Sheets[id] = {};
  workbook.Workbook.Sheets[id].Hidden = 2;

  const modeloData = [
    ["Código", "Quantidade"],
    ["", 0],
  ];

  utils.sheet_add_aoa(worksheet, modeloData, { origin: 0 });
  writeFile(workbook, "Modelo_planilha_inventario.xlsx");
}

export async function validateSheet(file: File) {
  const fileReader = await file.arrayBuffer();
  const workbook = read(fileReader, { cellDates: true });

  const hiddenSheetId = workbook.SheetNames.indexOf(SHEET_INVENTORY_HASH);
  if (hiddenSheetId == -1) throw new Error("Planilha inválida");

  const sheet = workbook.Sheets[workbook.SheetNames[0]];
  const sheetJson = utils.sheet_to_json<SheetColumnsType>(sheet);

  const keys = Object.keys(sheetJson[0] || {});

  const acceptedKeys = ["Código", "Quantidade"];

  if (!keys || JSON.stringify(keys) !== JSON.stringify(acceptedKeys)) {
    throw new Error("Planilha inválida");
  }
  return { sheetJson };
}

export function validateSheetRows(sheetRows: SheetColumnsType[]) {
  const validatedRows = sheetRows.map((row, index) => {
    if (!row) throw new Error("Inválido");
    const { Código: codigo, Quantidade: quantidade } = row;
    const rowIndex = 2;

    if (!codigo)
      throw new Error(`Código inválido na linha ${index + rowIndex}`);

    if (Number.isNaN(Number(quantidade)))
      throw new Error(`Quantidade inválida na linha ${index + rowIndex}`);

    return {
      ...row,
    };
  });

  return validatedRows;
}

import { SHEET_HASH } from "@/constants";
import { utils, writeFile } from "xlsx";

export function handleSheetModel(
  date: string,
  quantidade: number,
  tipoPlano: string
) {
  const workbook = utils.book_new();
  const worksheet = utils.aoa_to_sheet([[]]);
  utils.book_append_sheet(workbook, worksheet, "Modelo");
  utils.book_append_sheet(workbook, worksheet, SHEET_HASH);

  const id = workbook.SheetNames.indexOf(SHEET_HASH);
  if (!workbook.Workbook) workbook.Workbook = {};
  if (!workbook.Workbook?.Sheets) workbook.Workbook.Sheets = [];
  if (!workbook.Workbook?.Sheets?.[id]) workbook.Workbook.Sheets[id] = {};
  workbook.Workbook.Sheets[id].Hidden = 2;

  const modeloData = [
    [
      "Vigencia",
      "Filial_Origem",
      "Destino",
      "Código",
      "Quantidade",
      "Tipo_Plano",
    ],
    [date, "", "", "", quantidade, tipoPlano],
  ];
  utils.sheet_add_aoa(worksheet, modeloData, { origin: 0 });
  writeFile(workbook, "Modelo_planilha_padrao.xlsx");
}

export function codeErrorSheet(codes: Array<string>) {
  const arrayToJson = codes?.map((code = "") => {
    return { Códigos: code };
  });

  const worksheet = utils.json_to_sheet(arrayToJson);
  const workbook = {
    Sheets: { data: worksheet },
    SheetNames: ["data"],
  };
  writeFile(workbook, `codigos_invalidos.xlsx`);
}

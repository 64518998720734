import ForgotPasswordCard from "@/components/Card/ForgotPasswordCard";
import DefaultLayout from "@/components/Layout/DefaultLayout";

export default function ForgotPassword() {
  return (
    <DefaultLayout>
      <div className="flex w-full h-full items-center justify-center">
        <ForgotPasswordCard />
      </div>
    </DefaultLayout>
  );
}

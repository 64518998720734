import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { inventoryKeys } from "./useInventory";

export default function useInventoryDeleteMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }: { id: string }) => {
      return instance.delete("/planningbeer/inventory", {
        data: { id },
      });
    },

    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: inventoryKeys.all,
      });
    },
  });
}

import { UserType } from "@/types";
import { getPermissionCookie } from "./cookies";

type AllowedUserType = UserType["role"];

export function isAllowed(userRoles: AllowedUserType[]) {
  const role = getPermissionCookie();

  if (role.length == 0) return true;

  if (userRoles.includes(role)) {
    return true;
  }
  return false;
}

import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import React, { useState } from "react";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import TableRenderers from "react-pivottable/TableRenderers";
import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import useEstimateProductDetail from "@/hooks/useEstimateProductDetail";
import { useNavigate, useParams } from "react-router-dom";
import Row from "@/components/Row";
import { Button } from "@/components/ui/button";
import { ROUTES } from "@/constants";
import ProductTable from "./components/Table";
import TableVisionButton, {
  TableVisionButtonProps,
} from "./components/Button/TableVisionButton";
import Can from "@/components/Can";
import { motion } from "framer-motion";
import { utils, writeFile } from "xlsx";

export default function SeeEstimate() {
  const navigate = useNavigate();
  const param = useParams();
  const { id } = param;
  const { data } = useEstimateProductDetail({ id: Number(id) });
  const { data: productDetail = [] } = data || {};
  const PlotlyRenderers = createPlotlyRenderers(Plot);
  const [state, setState] = useState({});
  const [tableVision, setTableVision] =
    useState<TableVisionButtonProps["type"]>("pivot");

  function exportReport() {
    const pvtTable = document.querySelector(".pvtTable");
    const worksheet = utils.table_to_sheet(pvtTable);
    const workbook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    writeFile(workbook, `Estimativa_demanda_${id}.xlsx`);
  }

  return (
    <React.Fragment>
      <AuthenticatedLayout>
        <Row justify="end" className="gap-4">
          <Can
            condition={tableVision == "pivot"}
            onTrue={<Button onClick={exportReport}>Gerar Excel</Button>}
          />
          <TableVisionButton onChange={setTableVision} type={tableVision} />
        </Row>
        <Can
          condition={tableVision == "table"}
          onTrue={<ProductTable data={productDetail} />}
          onFalse={
            <motion.div
              className="box max-w-full max-h-[calc(100vh-190px)] overflow-scroll py-2"
              initial={{ opacity: 0, scale: 1 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <PivotTableUI
                data={productDetail as any}
                rows={["Código", "Descrição"]}
                aggregatorName="Sum"
                vals={["Quantidade"]}
                cols={["Destino"]}
                plotlyConfig={{ locale: "pt-br", autosizable: true }}
                onChange={(e) => {
                  setState(e as any);
                }}
                hiddenAttributes={["SK_planejamento_demanda_detalhes"]}
                renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                {...state}
              />
            </motion.div>
          }
        />
        <Row className="mt-4" justify="end">
          <Button
            variant="ghost"
            onClick={() => {
              navigate("/" + ROUTES.forecast.index);
            }}
          >
            Voltar
          </Button>
        </Row>
      </AuthenticatedLayout>
    </React.Fragment>
  );
}

import { useForm } from "react-hook-form";
import PackagingSimulationForm from "..";
import { PackagingSimulationFormType, schema } from "../zod";
import { zodResolver } from "@hookform/resolvers/zod";
import usePackagingSimulationMutation from "@/hooks/usePackagingSimulationMutation";
import { validationError } from "@/utils/api";
import { DialogProps } from "@/types";
import { usePackagingSimulationContext } from "@/pages/SimulacoesEmbalagem/context";

export default function CreatePackagingSimulation({
  handleClose,
}: DialogProps) {
  const { handlePackagingSimulationData, clear } =
    usePackagingSimulationContext();
  const { mutateAsync, isPending } = usePackagingSimulationMutation();
  const form = useForm<PackagingSimulationFormType>({
    reValidateMode: "onChange",
    mode: "onChange",
    resolver: zodResolver(schema),
  });

  async function handleSimulation(formData: PackagingSimulationFormType) {
    try {
      const { data } = await mutateAsync({
        SK_simulacao: Number(formData.SK_simulacao),
      });
      clear();
      handlePackagingSimulationData(data);
      return handleClose();
    } catch (error) {
      return validationError(error);
    }
  }

  return (
    <PackagingSimulationForm
      form={form}
      onSubmit={handleSimulation}
      button={{ disabled: isPending, title: "Gerar" }}
    />
  );
}

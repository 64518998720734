import { PackagingSupplierCellValueType } from "@/hooks/usePackagingSimulationMutation";
import { useState } from "react";

export type GetProgrammedCollectionCellValueProps = {
  day: string;
  composition: string;
};

export function usePackagingSupplier() {
  const [packagingSupplierCellValue, setPackagingSupplierCellValue] = useState<
    Array<PackagingSupplierCellValueType>
  >([]);

  function handlePackagingSupplierCellValue(
    cellData: PackagingSupplierCellValueType | PackagingSupplierCellValueType[]
  ) {
    if (Array.isArray(cellData)) {
      return setPackagingSupplierCellValue(cellData);
    }
    setPackagingSupplierCellValue((prev) => {
      const newPackagingSupplierCellValue = prev.filter(
        (cell) =>
          cell.FK_fornecedor_embalagem !== cellData.FK_fornecedor_embalagem ||
          cell.dia !== cellData.dia ||
          cell.FK_fabrica !== cellData.FK_fabrica ||
          cell.FK_simulacao_producao !== cellData.FK_simulacao_producao
      );
      return [...newPackagingSupplierCellValue, cellData];
    });
  }

  function getPackagingSupplierCellValue(
    data: Omit<PackagingSupplierCellValueType, "valor">
  ) {
    const { valor } = packagingSupplierCellValue.find(
      (cell) =>
        cell.FK_fornecedor_embalagem == data.FK_fornecedor_embalagem &&
        cell.dia == data.dia &&
        cell.FK_fabrica == data.FK_fabrica &&
        cell.FK_simulacao_producao == data.FK_simulacao_producao
    ) || { valor: "0" };

    return valor;
  }

  function getProgrammedCollectionCellValue({
    composition,
    day,
  }: GetProgrammedCollectionCellValueProps) {
    return packagingSupplierCellValue.reduce((acc, cell) => {
      if (cell.dia == day && cell.composicao == composition) {
        return acc + Number(cell.valor);
      }
      return acc;
    }, 0);
  }

  function clear() {
    setPackagingSupplierCellValue([]);
  }

  return {
    packagingSupplierCellValue,
    handlePackagingSupplierCellValue,
    getPackagingSupplierCellValue,
    getProgrammedCollectionCellValue,
    clear,
  };
}

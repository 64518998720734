import { PackagingSimulatorsType } from "@/hooks/usePackagingSimulators";
import { ReactChildrenType } from "@/types";
import { createContext, useContext, useState } from "react";

type DialogTypeType = "edit" | "delete" | "detail" | "nonSelected";

type SimulatorContextProps = {
  visibleDialog: boolean;
  handleDialog: () => void;
  dialogType: DialogTypeType;
  handleDialogType: (type: DialogTypeType) => void;
  simulator: PackagingSimulatorsType;
  handleSimulator: (factory: PackagingSimulatorsType) => void;
  clear: () => void;
};

const SimulatorContext = createContext({} as SimulatorContextProps);

export default function PackagingSimulatorProvider({
  children,
}: ReactChildrenType) {
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [simulator, setSimulator] = useState<PackagingSimulatorsType>(
    {} as PackagingSimulatorsType
  );
  const [dialogType, setDialogType] = useState<DialogTypeType>("nonSelected");

  function handleDialog() {
    setVisibleDialog((prev) => !prev);
  }

  function handleDialogType(value: DialogTypeType) {
    setDialogType(value);
  }

  function handleSimulator(value: PackagingSimulatorsType) {
    setSimulator(value);
  }

  function clear() {
    setDialogType("nonSelected");
    setVisibleDialog(false);
  }

  return (
    <SimulatorContext.Provider
      value={{
        clear,
        handleDialogType,
        handleDialog,
        handleSimulator,
        simulator,
        visibleDialog,
        dialogType,
      }}
    >
      {children}
    </SimulatorContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function usePackagingSimulatorContext() {
  return useContext(SimulatorContext);
}

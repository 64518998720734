const ptBr = {
  hello: "bem vindo",
  code: "código",
  description: "descrição",
  productType: "tipo de produto",
  productClass: "classe do produto",
  classification: "classificação",
  liters: "litros",
  editSuccess: "Edição feita com sucesso!",
  editStatus: "Status alterado com sucesso!",
  exclusionSuccess: "Exclusão feita com sucesso!",
  liquidType: "tipo de líquido",
  volume: "volume",
  formation: "formação",
  layers: "camadas",
  group: "grupo",
  ballast: "lastro",
  soldQuantity: "quantidade vendida",
  validity: "vigência",
  january: "Janeiro",
  february: "Fevereiro",
  march: "Março",
  april: "Abril",
  may: "Maio",
  june: "Junho",
  july: "Julho",
  august: "Agosto",
  september: "Setembro",
  october: "Outubro",
  november: "Novembro",
  december: "Dezembro",
  defaultApiError: "Erro inesperado, tente novamente mais tarde!",
  unauthorized: "Você não tem permissão para realizar essa ação!",
} as const;

type PTBR = { [key: string]: any } & typeof ptBr;

export default ptBr as PTBR;

import instance from "@/api/business";
import { FactoryColumnsType } from "@/pages/Factory";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const factoryKeys = {
  all: ["factories"] as const,
  detail: (id: number) => [...factoryKeys.all, "show", id] as const,
};

type FactoryMutationType = Omit<FactoryColumnsType, "SK_fabrica">;

export default function useFactoryMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: FactoryMutationType) => {
      return instance.put("/planningbeer/factory/", { ...data });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: factoryKeys.all });
    },
  });
}

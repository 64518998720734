import { quantityFormater } from "@/utils/number";
import { usePackagingSimulationContext } from "../../context";

type ProgrammedCollectionCellProps = {
  composition: string;
  day: string;
};

export default function ProgrammedCollectionCell({
  composition,
  day,
}: ProgrammedCollectionCellProps) {
  const {
    packagingSupplier: { getProgrammedCollectionCellValue },
  } = usePackagingSimulationContext();

  const value = getProgrammedCollectionCellValue({ composition, day });

  return quantityFormater(value);
}

import React from "react";
import EditDropdownItem from "./Items/EditDropdownItem";
import DeleteDropdownItem from "./Items/DeleteDropdownItem";
import { FactoryColumnsType } from "../..";
import ManagerFactoryDropdownItem from "./Items/ManagerFactoryDropdownItem";

export default function ActionCellDropdown(props: FactoryColumnsType) {
  return (
    <React.Fragment>
      <ManagerFactoryDropdownItem {...props} />
      <EditDropdownItem {...props} />
      <DeleteDropdownItem {...props} />
    </React.Fragment>
  );
}

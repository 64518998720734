import instance from "@/api/business";
import { useQuery } from "@tanstack/react-query";
import { ApiResponse } from "./useEstimates";

type PackagingType = {
  SK_embalagem_fornecedor: number;
  FK_fabrica: number;
  FK_fornecedor: number;
  composicao: string;
  descricao_composicao: string;
  nome_fornecedor: string;
  codigo_fornecedor: string;
  abreviatura_empresa: string;
};

export type PackagingSupplierType = {
  nome_fornecedor: string;
  codigo_fornecedor: string;
  embalagens: Array<PackagingType>;
};

export const packagingSupplierKey = {
  all: ["packagingSuppliers"] as const,
};

async function fetchPackagingSuppliers() {
  const response = await instance.post<ApiResponse<PackagingSupplierType>>(
    "/planningbeer/packagingSupplier"
  );

  return response.data;
}

export function usePackagingSuppliers() {
  return useQuery({
    queryKey: packagingSupplierKey.all,
    queryFn: () => fetchPackagingSuppliers(),
  });
}

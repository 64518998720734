import { EstimateDetailType } from "../Editar";

export function getKeys(data: Array<any>) {
  if (data.length === 0) return [];

  const firstRow = data[0];
  return Object.keys(firstRow);
}

export function getColumnOldLastValue({
  rowId,
  columnName,
  data,
}: {
  rowId: string | number;
  columnName: string;
  data: Array<EstimateDetailType>;
}) {
  const rowsWithOldValues = data.filter((row) => {
    if (!row.colunas) return;
    if (!row.old_valores) return;

    return row;
  });
  if (!rowsWithOldValues) return;

  const columns = rowsWithOldValues.map((row) => row.colunas?.split(","));
  const oldValues = rowsWithOldValues.map((row) => row.old_valores?.split(","));

  const rowOld = {
    rowId: rowsWithOldValues.map((row) => row.SK_planejamento_demanda_detalhes),
    columns,
    oldValues,
  };

  const rowIndex = rowOld.rowId.indexOf(rowId);
  if (rowIndex == -1) return;

  const oldColumn = rowOld.columns[rowIndex];
  const oldValue = rowOld.oldValues[rowIndex];

  if (!oldColumn) return;

  function getLastColumnPosition(array: Array<string>, columnName: string) {
    for (let i = array.length - 1; i >= 0; i--) {
      if (array[i] == columnName) {
        return i;
      }
    }
    return -1;
  }

  const columnPosition = getLastColumnPosition(oldColumn, columnName);
  if (columnPosition == -1) return;
  const lastValue = oldValue?.[columnPosition];

  return lastValue || null;
}

export function getColumnOldValues({
  rowId,
  columnName,
  data,
}: {
  rowId: string | number;
  columnName: string;
  data: Array<EstimateDetailType>;
}) {
  const rowsWithOldValues = data.filter((row) => {
    if (!row.colunas) return;
    if (!row.old_valores) return;

    return row;
  });
  if (!rowsWithOldValues) return;

  const columns = rowsWithOldValues.map((row) => row.colunas?.split(","));
  const oldValues = rowsWithOldValues.map((row) => row.old_valores?.split(","));

  const rowOld = {
    rowId: rowsWithOldValues.map((row) => row.SK_planejamento_demanda_detalhes),
    columns,
    oldValues,
  };

  const rowIndex = rowOld.rowId.indexOf(rowId);
  if (rowIndex == -1) return;

  const oldColumn = rowOld.columns[rowIndex];
  const oldValue = rowOld.oldValues[rowIndex];

  if (!oldColumn) return;

  // function getLastColumnPosition(array: Array<string>, columnName: string) {
  //   for (let i = array.length - 1; i >= 0; i--) {
  //     if (array[i] == columnName) {
  //       return i;
  //     }
  //   }
  //   return -1;
  // }

  // const columnPosition = getLastColumnPosition(oldColumn, columnName);
  // if (columnPosition == -1) return;
  // const lastValue = oldValue?.[columnPosition];

  function getColumnPositionsByName(array: Array<string>, columnName: string) {
    const positions: Array<number> = [];
    for (let i = 0; i < array.length; i++) {
      if (array[i] === columnName) {
        positions.push(Number(i));
      }
    }
    return positions;
  }

  const columnPositions = getColumnPositionsByName(oldColumn, columnName);
  if (columnPositions.length === 0) return;
  const lastValues = columnPositions.map((position) => {
    if (oldValue?.[position]) return oldValue[position];
    return "";
  });

  return lastValues;
}

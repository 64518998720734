import { useQueryClient } from "@tanstack/react-query";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { FactoryColumnsType } from "@/pages/Factory";
import { factoryKeys } from "@/hooks/useFactoryMutation";
import Each from "../Each";
import { SelectProps } from "@radix-ui/react-select";
import { MultiSelect } from "../ui/multiple-selector";
import { useState } from "react";

type FactoryData = {
  data: Array<FactoryColumnsType>;
};

type FactorySelectProps = {
  isMultiple?: boolean;
  handleSelected?: (selected: string) => void;
  selected?: string[];
} & SelectProps;

export default function FactorySelect({
  isMultiple,
  handleSelected = () => {},
  selected = [],
  disabled = false,
  ...props
}: FactorySelectProps) {
  const [visible, setVisible] = useState(false);
  const queryClient = useQueryClient();
  const factoryData: FactoryData | undefined = queryClient.getQueryData(
    factoryKeys.all
  );
  const { data: factory } = factoryData || {};
  if (!factory) return null;

  if (isMultiple)
    return (
      <MultiSelect
        options={factory.map((item) => ({
          label: `${item.SK_fabrica} - ${item.abreviatura_empresa}`,
          value: String(item.SK_fabrica),
        }))}
        selectedValues={selected}
        isOpen={visible}
        onOpen={() => {
          setVisible(true);
        }}
        onClose={() => {
          setVisible(false);
        }}
        onChange={handleSelected}
      />
    );

  return (
    <Select
      disabled={disabled}
      onValueChange={props.onValueChange}
      defaultValue={props.defaultValue}
      value={props.value}
    >
      <SelectTrigger>
        <SelectValue placeholder="" />
      </SelectTrigger>
      <SelectContent>
        <Each
          array={factory}
          renderItem={(props) => {
            return (
              <SelectItem value={String(props.SK_fabrica)}>
                {props.SK_fabrica}
                {" - "}
                {props.abreviatura_empresa}
              </SelectItem>
            );
          }}
        />
      </SelectContent>
    </Select>
  );
}

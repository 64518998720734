import { REQUIRED_FIELD } from "@/constants";
import { z } from "zod";

export const productInventoryPolicySchema = z.object({
  refill: z
    .string({ required_error: REQUIRED_FIELD })
    .min(1, { message: REQUIRED_FIELD }),
  max: z
    .string({ required_error: REQUIRED_FIELD })
    .min(1, { message: REQUIRED_FIELD }),
  min: z
    .string({ required_error: REQUIRED_FIELD })
    .min(1, { message: REQUIRED_FIELD }),
});

export type ProductInventoryPolicyFormType = z.infer<
  typeof productInventoryPolicySchema
>;

import { REQUIRED_FIELD } from "@/constants";
import { z } from "zod";

const object = z.object({}, { required_error: REQUIRED_FIELD });

export const schema = z.object({
  description: z.string({ required_error: REQUIRED_FIELD }),
  classification: z.enum(["mensal", "semestral", "anual"], {
    required_error: REQUIRED_FIELD,
  }),
  month: z.string({ required_error: REQUIRED_FIELD }).optional(),
  year: z.string({ required_error: REQUIRED_FIELD }).optional(),
  semester: z.string({ required_error: REQUIRED_FIELD }).optional(),
  sheet: object,
});

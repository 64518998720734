import instance from "@/api/business";
import { IdParamType, ResponseDataType } from "@/types";
import { useQuery } from "@tanstack/react-query";
import { PackagingSimulationResponseType } from "./usePackagingSimulationMutation";
import { packagingSimulatorKeys } from "./usePackagingSimulators";

async function fetchPackagingSimulator({ id }: IdParamType) {
  if (!id) return;
  const response = await instance.post<
    ResponseDataType<PackagingSimulationResponseType>
  >("/planningbeer/packagingSimulator/detail", {
    id,
  });

  return response.data;
}

export function usePackagingSimulator({ id }: IdParamType) {
  return useQuery({
    queryKey: packagingSimulatorKeys.show(Number(id)),
    queryFn: () => fetchPackagingSimulator({ id }),
    staleTime: 0,
    gcTime: 0,
  });
}

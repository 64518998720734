import { GetCompositionInitialBalance } from "../types";

export function getCompositionInitialBalance({
  composition,
  day,
  initialBalance,
  currentInventory,
}: GetCompositionInitialBalance) {
  const initialBalanceComposition = initialBalance.filter(
    (balance) => balance.FK_produto == composition
  );

  if (!initialBalanceComposition) {
    return { inventory: currentInventory };
  }

  const initialBalanceDay = initialBalanceComposition.find(
    (balance) => balance.vigencia === day
  );

  const inventory = Number(initialBalanceDay?.quantidade) || currentInventory;

  return { inventory };
}

import instance from "@/api/business";
import { useQuery } from "@tanstack/react-query";
import { ApiResponse } from "./useEstimates";

type ProductPackagingProps = {
  fkFactory?: number;
  fkProduct: string;
};

type SupplierType = {
  codigo: string;
  nome_completo: string;
  nome_reduzido: string;
};

export type ProductPackagingResponseType = {
  SK_embalagem: string;
  FK_produto: string;
  composicao: string;
  descricao: string;
  filial: string;
  fornecedores: Array<SupplierType>;
};

export const productPackagingKeys = {
  all: ["productPackaging"] as const,
  show: (fkProduct: string, fkFactory: number) =>
    [...productPackagingKeys.all, fkProduct, fkFactory] as const,
};

async function fetchProductPackaging(props: ProductPackagingProps) {
  if (!props.fkFactory || !props.fkProduct) return;
  const response = await instance.post<
    ApiResponse<ProductPackagingResponseType>
  >("/planningbeer/productPackaging", {
    ...props,
  });
  return response.data;
}

export function useProductPackaging(props: ProductPackagingProps) {
  return useQuery({
    queryKey: ["productPackaging", props.fkProduct, props.fkFactory],
    queryFn: () => fetchProductPackaging(props),
    gcTime: 0,
    staleTime: 0,
  });
}

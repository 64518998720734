import ActionCell from "@/components/Cell/ActionCell";
import TableContainer from "@/components/Container/TableContainer";
import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Table from "@/components/NewTable";
import { TableData } from "@/components/NewTable/type";
import Row from "@/components/Row";
import { getTableColumnOrder } from "@/utils/table";
import { ColumnOrderState, createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import CreateResourceDialog from "./components/Dialog/CreateResourceDialog";
import useResources, { ResourcesType } from "@/hooks/useResources";
import Can from "@/components/Can";
import ActionDropdown from "./components/Dropdown/ActionDropdown";
import { DeleteDialogAlert } from "@/components/DialogAlert/DeleteDialogAlert";
import { useResourceContext } from "./context";
import useResourceDeleteMutation from "@/hooks/useResourceDeleteMutation";
import { validationError } from "@/utils/api";
import { toast } from "sonner";
import EditResourceDialog from "./components/Dialog/EditResourceDialog";
import { useConfigurations } from "@/hooks/useConfiguration";
import { CONFIGURATION_TABLES_CODE } from "@/constants";

const columnHelper = createColumnHelper<ResourcesType>();
const TABLE_ORDER_KEY = "resourceColumnOrder";

type ResourceTable = TableData<ResourcesType>;

export default function Resources() {
  useConfigurations({
    tabela_codigo: CONFIGURATION_TABLES_CODE.dim_tipo_recursos.codigo,
  });
  const { mutateAsync, isPending } = useResourceDeleteMutation();
  const { clear, resource, visibleDialog, dialogType } = useResourceContext();
  const { data } = useResources();
  const { data: resources = [] } = data || {};

  const columns = [
    columnHelper.accessor("SK_recurso", {
      header: "ID",
      id: "SK_recurso",
      size: 120,
    }),
    columnHelper.accessor("descricao", {
      header: "Descrição",
      id: "descricao",
      size: 450,
    }),
    columnHelper.accessor("tipo_de_embalagem", {
      header: "Tipo de Embalagem",
      id: "tipo_de_embalagem",
      size: 200,
    }),
    columnHelper.accessor("definicao", {
      header: "Definição",
      id: "definicao",
      size: 250,
    }),
    columnHelper.accessor("tipo", {
      header: "Tipo",
      id: "tipo",
      size: 200,
    }),
    columnHelper.accessor("codigo_centro_de_custo", {
      header: "Código Centro de Custo",
      id: "codigo_centro_de_custo",
      size: 250,
    }),
    columnHelper.display({
      id: "Ações",
      header: "Ações",
      minSize: 100,
      maxSize: 100,
      cell: ({ row }) => {
        const { pode_editar } = row.original;

        function canChange(value?: string) {
          if (!value) return false;
          return true;
        }
        return (
          <Can
            condition={canChange(pode_editar)}
            onTrue={<ActionCell items={<ActionDropdown {...row.original} />} />}
            onFalse={<div className="py-5" />}
          />
        );
      },
    }),
  ];

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    getTableColumnOrder({ column: columns, key: TABLE_ORDER_KEY })
  );

  function handleColumnOrder(column: ColumnOrderState) {
    setColumnOrder(column);
    localStorage.setItem(TABLE_ORDER_KEY, JSON.stringify(column));
  }

  async function deleteResource() {
    if (isPending) return;
    try {
      await mutateAsync({ id: Number(resource?.SK_recurso) });
      clear();
      return toast.success("Recurso excluído com sucesso.");
    } catch (error) {
      return validationError(error);
    }
  }

  return (
    <AuthenticatedLayout>
      <Row justify="end">
        <CreateResourceDialog />
      </Row>
      <TableContainer>
        <Table<ResourceTable>
          columns={columns}
          data={resources}
          onColumnOrderStateChange={handleColumnOrder}
          columnOrderState={columnOrder}
          meta={{
            layout: "stretch",
          }}
          getRowId={(row) => row.SK_recurso.toString()}
        />
      </TableContainer>
      <DeleteDialogAlert
        type={dialogType}
        onConfirm={deleteResource}
        handleVisibleDialog={clear}
        onCancel={clear}
        visibleDialog={visibleDialog}
        alertDialogTitle={`Deseja excluir o recurso "${resource?.SK_recurso}"? Essa ação é irreversível.`}
      />
      <EditResourceDialog />
    </AuthenticatedLayout>
  );
}

import { REQUIRED_FIELD } from "@/constants";
import { z } from "zod";

export const schema = z.object({
  FK_fabrica: z.string({ required_error: REQUIRED_FIELD }),
  periodo_inicio: z.date({ required_error: REQUIRED_FIELD }),
  periodo_fim: z.date({ required_error: REQUIRED_FIELD }),
  descricao: z.string({ required_error: REQUIRED_FIELD }),
});

export type ProductionPlanFormType = z.infer<typeof schema>;

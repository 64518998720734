import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { TEXT_COLOR } from "@/constants";
import { EstimatesColumnProps } from "@/pages/Estimativas";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";

export type ItemProps = EstimatesColumnProps;

export default function EditItem({ id }: { id: string | number }) {
  return (
    <DropdownMenuItem>
      <Link to={"editar/" + id}>
        <Row>
          <FaEdit className="mr-2" color={TEXT_COLOR} />
          <Text>Editar</Text>
        </Row>
      </Link>
    </DropdownMenuItem>
  );
}

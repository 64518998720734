import { useQueryClient } from "@tanstack/react-query";
import { ProductType } from "@/hooks/useProducts";
import { MultiSelect } from "../ui/multiple-selector";
import { useEffect, useState } from "react";
import { filterItems, Filter } from "@/utils/select";

type ProductDataType = {
  data: Array<ProductType>;
};

type ProductSelectProps = {
  selected: Array<string>;
  handleSelected: (value: string) => void;
} & Partial<Filter<ProductType>>;

export default function ProductSelect({
  selected,
  handleSelected,
  valueToFilter,
}: ProductSelectProps) {
  const [optionsToSelect, setOptionsToSelect] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);
  const [visible, setVisible] = useState(false);
  const queryClient = useQueryClient();

  const produtData: ProductDataType | undefined = queryClient.getQueryData([
    "products",
  ]);
  const { data: product } = produtData || {};

  const formatedProduct = product?.map((item) => {
    item.fabricas_recursos_array = item.fabricas_recursos?.split(",");
    return item;
  });

  const filteredActiveProducts = filterItems(formatedProduct, {
    fieldToFilter: "ativo",
    valueToFilter: 1,
  });

  const filterProducts = filteredActiveProducts.filter((item) => {
    if (Array.isArray(valueToFilter)) {
      if (valueToFilter.length == 0) return item;
      return valueToFilter?.some((value) =>
        item.fabricas_recursos_array?.includes(String(value))
      );
    }
    return item;
  });

  const options = filterProducts.map(({ SK_produto, descricao }) => {
    return { label: SK_produto + " - " + descricao, value: SK_produto };
  });

  function customFilter(search: string) {
    if (!search) return options;
    const filteredOptions = options.filter((item) =>
      item.value.includes(search)
    );
    return filteredOptions;
  }

  function handleCustomFilter(_: string, search: string) {
    const filtered = customFilter(search);
    setOptionsToSelect(filtered.length > 0 ? filtered : optionsToSelect);
    return 1;
  }

  useEffect(() => {
    setOptionsToSelect(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [produtData]);

  return (
    <MultiSelect
      options={optionsToSelect}
      selectedValues={selected}
      isOpen={visible}
      onOpen={() => {
        setVisible(true);
      }}
      onClose={() => {
        setVisible(false);
      }}
      customFilter={handleCustomFilter}
      onChange={handleSelected}
    />
  );
}

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { UseFormReturn } from "react-hook-form";
import { ResourceTypeFormType } from "./zod";
import { Input } from "@/components/ui/input";
import Row from "@/components/Row";
import { Button } from "@/components/ui/button";
import Text from "@/components/Text/Text";

type ResourceTypeFormProps = {
  form: UseFormReturn<ResourceTypeFormType, any, undefined>;
  onSubmit: (data: ResourceTypeFormType) => void;
  button?: {
    disabled?: boolean;
    title?: string;
  };
};

export default function ResourceTypeForm({
  form,
  onSubmit,
  button = { disabled: false, title: "Cadastrar" },
}: ResourceTypeFormProps) {
  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name="valor"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Nome</FormLabel>
              <FormControl>
                <Input {...field} maxLength={255} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Row justify="end" align="center" className="gap-2 mt-4">
          <Button type="submit" disabled={button.disabled}>
            <Text color="white">{button.title}</Text>
          </Button>
        </Row>
      </form>
    </Form>
  );
}

import instance from "@/api/business";
import { ApiResponse, estimates } from "./useEstimates";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

export type SeeEstimateDetailType = {
  SK_planejamento_demanda_detalhes: string | number;
  Camadas?: string | number;
  Classe_Produto?: string | number;
  Classificação?: string | number;
  Código?: string | number;
  Descrição?: string | number;
  Destino?: string | number;
  Filial_Origem?: string | number;
  Formação?: string | number;
  Grupo?: string | number;
  Lastro?: string | number;
  Litragem?: string | number;
  Quantidade?: string | number;
  Status?: string | number;
  Tipo_Líquido?: string | number;
  Tipo_Plano?: string | number;
  Tipo_Produto?: string | number;
  Vigência?: string | number;
  Volume?: string | number;
};

export async function fetchEstimateProductDetail({ id }: { id: number }) {
  const response = await instance.post<ApiResponse<SeeEstimateDetailType>>(
    "/planningbeer/forecast/product/detail",
    {
      id,
    }
  );
  return response.data;
}

export default function useEstimateProductDetail({ id }: { id: number }) {
  return useQuery({
    queryKey: estimates.detail(id),
    queryFn: () => fetchEstimateProductDetail({ id }),
    placeholderData: keepPreviousData,
  });
}

import { Column } from '@tanstack/react-table'
import {
    ArrowLeft,
    ArrowRight,
    Eye,
    EyeOff,
    GripHorizontal,
    PinOff,
} from 'lucide-react'
import { cn } from '@/lib/utils'
import { DropdownMenuItem } from '@/components/ui/dropdown-menu'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { CSSProperties } from 'react'

interface ItemProps<T> {
    column: Column<T>
}

const Item = <T,>({ column }: ItemProps<T>) => {
    const { attributes, isDragging, listeners, transform, setNodeRef } =
        useSortable({
            id: column.id,
        })

    const style: CSSProperties = {
        opacity: isDragging ? 0.8 : 1,
        position: 'relative',
        transform: CSS.Translate.toString(transform),
        transition: 'width transform 0.2s ease-in-out',
        whiteSpace: 'nowrap',
        zIndex: isDragging ? 1 : 0,
    }

    return (
        <DropdownMenuItem
            key={column.id}
            className="flex items-center justify-between gap-2"
            asChild
        >
            <div
                id={column.id}
                key={column.id}
                style={{ ...style }}
                ref={setNodeRef}
                onClick={(e) => e.preventDefault()}
            >
                <div className="flex items-center gap-2">
                    <button
                        {...attributes}
                        {...listeners}
                        disabled={!!column.getIsPinned()}
                    >
                        <GripHorizontal
                            size={16}
                            className={cn(
                                column.getIsPinned()
                                    ? 'text-neutral-400'
                                    : 'text-neutral-500'
                            )}
                        />
                    </button>
                    <p>
                        {column.accessorFn
                            ? column.columnDef.header?.toString()
                            : column.id}
                    </p>
                </div>

                <div className="flex items-center gap-2">
                    <button
                        className="p-1 rounded-md hover:bg-slate-200"
                        onClick={() => column.toggleVisibility()}
                    >
                        {column.getIsVisible() ? (
                            <Eye size={14} />
                        ) : (
                            <EyeOff size={14} />
                        )}
                    </button>
                    <button
                        className={cn(
                            'p-1 rounded-md hover:bg-slate-200',
                            column.getIsPinned() === 'left' &&
                                'text-primary-500 bg-primary-50'
                        )}
                        onClick={() => column.pin('left')}
                    >
                        <ArrowLeft size={14} />
                    </button>
                    <button
                        className={cn(
                            'p-1 rounded-md hover:bg-slate-200',
                            column.getIsPinned() === 'right' &&
                                'text-primary-600 bg-primary-50'
                        )}
                        onClick={() => column.pin('right')}
                    >
                        <ArrowRight size={14} />
                    </button>
                    <button
                        className={cn(
                            'p-1 rounded-md text-orange-400 hover:bg-slate-200',
                            !column.getIsPinned() && 'text-neutral-400'
                        )}
                        onClick={() => column.pin(false)}
                    >
                        <PinOff size={14} />
                    </button>
                </div>
            </div>
        </DropdownMenuItem>
    )
}

export default Item

import { useForm } from "react-hook-form";
import PackagingSupplierForm from "..";
import { PackagingSupplierFormType, packagingSupplierSchema } from "../zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "sonner";
import { validationError } from "@/utils/api";
import { DialogProps } from "@/types";
import { usePackagingSupplierMutation } from "@/hooks/usePackagingSupplierMutation";

export default function CreatePackagingSupplierForm({
  handleClose,
}: DialogProps) {
  const { mutateAsync, isPending } = usePackagingSupplierMutation();
  const form = useForm<PackagingSupplierFormType>({
    reValidateMode: "onChange",
    mode: "onChange",
    resolver: zodResolver(packagingSupplierSchema),
  });

  async function createPackagingSupplier(data: PackagingSupplierFormType) {
    if (isPending) return;
    try {
      await mutateAsync({ ...data });
      toast.success("Fornecedor de embalagem cadastrado com sucesso!");
      return handleClose();
    } catch (error) {
      validationError(error);
    }
  }

  return (
    <PackagingSupplierForm
      form={form}
      button={{ disabled: false, title: "Cadastrar" }}
      onSubmit={createPackagingSupplier}
    />
  );
}

import { quantityFormater } from "@/utils/number";

type RealCollectionCellProps = {
  value: number;
};

export default function RealCollectionCell({ value }: RealCollectionCellProps) {
  if (value == 0) return <span>{value}</span>;
  return <span className="font-bold">{quantityFormater(value)}</span>;
}

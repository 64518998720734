import { PRIMARY_COLOR, TEXT_COLOR } from "@/constants";
import { cn } from "@/lib/utils";

type ColorsType = {
  primary: string;
  text: string;
  white: string;
  black: string;
  transparent: string;
};

const COLORS: ColorsType = {
  primary: PRIMARY_COLOR,
  text: TEXT_COLOR,
  white: "#FFFFFF",
  black: "#000000",
  transparent: "transparent",
};

type TextProps = {
  children: string | React.ReactNode;
  color?: "primary" | "text" | "white" | "black" | "transparent";
} & React.HTMLAttributes<HTMLParagraphElement>;

export default function Text({
  color = "text",
  children,
  ...props
}: TextProps) {
  return (
    <p
      className={cn("text-sm", props.className)}
      style={{ color: COLORS[color] }}
      {...props}
    >
      {children}
    </p>
  );
}

import Can from "@/components/Can";
import { Button } from "@/components/ui/button";
import { MdPivotTableChart, MdTableChart } from "react-icons/md";

export type TableVisionButtonProps = {
  type: "table" | "pivot";
  onChange: (type: "table" | "pivot") => void;
};

export default function TableVisionButton({
  type,
  onChange,
}: TableVisionButtonProps) {
  return (
    <>
      <Can
        condition={type == "table"}
        onTrue={
          <Button variant="ghost" size="icon" onClick={() => onChange("pivot")}>
            <MdPivotTableChart size={20} />
          </Button>
        }
      />
      <Can
        condition={type == "pivot"}
        onTrue={
          <Button variant="ghost" size="icon" onClick={() => onChange("table")}>
            <MdTableChart size={20} />
          </Button>
        }
      />
    </>
  );
}

import { EstimatesColumnProps } from "@/pages/Estimativas";
import dayjs from "dayjs";
import { writeFile, utils } from "xlsx";

export function exportToExcel<T>(
  filename: string,
  columnsToOmit: Array<string>,
  props: EstimatesColumnProps,
  data?: Array<T>
) {
  if (!data) return;

  const modifiedData = data.map((entry) => {
    const modifiedEntry = { ...entry };
    columnsToOmit.forEach((column) => delete modifiedEntry[column]);
    return modifiedEntry;
  });

  const wb = utils.book_new();
  const ws = utils.aoa_to_sheet([[]]);

  ws["A1"] = { t: "s", v: "Nome da Carga: " + props.nome_planejamento };
  ws["A2"] = { t: "s", v: "Descrição da carga: " + props.descricao };
  ws["A3"] = { t: "s", v: "Enviado por: " + props.nome_usuario };
  ws["A4"] = {
    t: "s",
    v:
      "Data de envio: " +
      dayjs(props.created_at, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm"),
  };
  ws["A5"] = { t: "s", v: "Status: " + props.status };

  const columnTitles = [
    "Código",
    "Descrição",
    "Filial Origem",
    "Destino",
    "Quantidade",
    "Tipo Plano",
    "Status",
    "Vigência",
    "Tipo Produto",
    "Grupo",
    "Classe Produto",
    "Classificação",
    "Litragem",
    "Tipo Líquido",
    "Volume",
    "Formação",
    "Camadas",
    "Lastro",
  ];

  // Definindo os títulos das colunas na linha 6
  columnTitles.forEach((title, index) => {
    ws[utils.encode_cell({ r: 7, c: index })] = { t: "s", v: title };
  });

  // Adicionando os dados modificados a partir da linha 7
  utils.sheet_add_json(ws, modifiedData, {
    origin: { r: 8, c: 0 }, // Começa na linha 7
    skipHeader: true,
  });

  utils.book_append_sheet(wb, ws, "Sheet1");
  writeFile(wb, `${filename}.xlsx`);
}

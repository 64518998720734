import Col from "@/components/Col";
import Each from "@/components/Each";
import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import useProducts, { ProductType } from "@/hooks/useProducts";
import useProductsMutation from "@/hooks/useProductsMutation";
import { validationError } from "@/utils/api";
import React, { useEffect, useState } from "react";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";
import { toast } from "sonner";
import SelectPlanningTypeDialog from "./components/Dialog/SelectPlanningTypeDialog";
import { FaPlus } from "react-icons/fa6";
import { TEXT_COLOR } from "@/constants";
import AdditionalInformationModal from "./components/Modal/AdditionalInformationModal";
import useFactories from "@/hooks/useFactories";
import { useSuppliers } from "@/hooks/useSuppliers";

const ACTIVE = 1;
const INACTIVE = 0;

type SelectedItemsType = {
  position: "left" | "right";
  SK_produto: ProductType["SK_produto"];
};

export default function Products() {
  useSuppliers();
  useFactories();
  const { mutateAsync, isPending } = useProductsMutation();
  const { data } = useProducts();
  const { data: productsData = [] } = data || {};
  const [products, setProducts] = useState<Array<ProductType>>([]);
  const [{ product, visible }, setModal] = useState({
    product: { code: "", description: "" },
    visible: false,
  });
  //prettier-ignore
  const [selectedItems, setSelectedItems] = useState<Array<SelectedItemsType>>([]);
  const [leftInputFilter, setLeftInputFilter] = useState<string>("");
  const [rightInputFilter, setRightInputFilter] = useState<string>("");
  const productsRight = products.filter(({ ativo }) => {
    return ativo == ACTIVE;
  });
  const productsLeft = products.filter(({ ativo }) => {
    return ativo == INACTIVE;
  });
  const filteredProductsLeft = productsLeft.filter(({ SK_produto }) => {
    return SK_produto.includes(leftInputFilter);
  });
  const filteredProductsRight = productsRight.filter(({ SK_produto }) => {
    return SK_produto.includes(rightInputFilter);
  });

  function handleProductSelection({ SK_produto, position }: SelectedItemsType) {
    const isSelected = selectedItems.some(
      (item) => item.SK_produto === SK_produto
    );

    if (isSelected) {
      setSelectedItems((prev) =>
        prev.filter((item) => item.SK_produto !== SK_produto)
      );
      return;
    }

    setSelectedItems((prev) => [...prev, { SK_produto, position }]);
  }

  function handleLeftToRight(planningType: string) {
    const productsToMove = selectedItems.map(({ SK_produto }) => SK_produto);
    const updatedProducts = products.map((product) => {
      if (productsToMove.includes(product.SK_produto)) {
        return { ...product, ativo: ACTIVE, tipo_planejador: planningType };
      }
      return product;
    });

    setProducts(updatedProducts);
    setSelectedItems([]);
  }

  function handleRightToLeft() {
    const productsToMove = selectedItems.map(({ SK_produto }) => SK_produto);
    const updatedProducts = products.map((product) => {
      if (productsToMove.includes(product.SK_produto)) {
        return { ...product, ativo: INACTIVE };
      }
      return product;
    });

    setProducts(updatedProducts);
    setSelectedItems([]);
  }

  function handleAllLeftToRight(planningType: string) {
    const updatedProducts = products.map((product) => {
      return { ...product, ativo: ACTIVE, tipo_planejador: planningType };
    });

    setProducts(updatedProducts);
  }

  function handleAllRightToLeft() {
    const updatedProducts = products.map((product) => {
      return { ...product, ativo: INACTIVE };
    });

    setProducts(updatedProducts);
  }

  async function handleProducts() {
    if (isPending) return;
    const productsKey = productsRight.map(({ SK_produto, tipo_planejador }) => {
      return {
        SK_produto,
        tipo_planejador,
      };
    });

    try {
      await mutateAsync({
        skProducts: productsKey || [],
      });
      toast.success("Produtos salvos com sucesso");
    } catch (error) {
      return validationError(error);
    }
  }

  function productList({
    SK_produto,
    descricao,
    tipo_planejador,
    position,
  }: ProductType & { position: "left" | "right" }) {
    return (
      <React.Fragment>
        <Row className="p-3" align="center" justify="space-between">
          <Row align="center">
            <Checkbox
              checked={selectedItems.some(
                (item) => item.SK_produto === SK_produto
              )}
              className="mr-4"
              onClick={() => {
                handleProductSelection({ SK_produto, position });
              }}
            />
            <div>
              <Text>{SK_produto + " - " + descricao}</Text>
              <Text>Tipo de Planejador: {tipo_planejador}</Text>
            </div>
          </Row>
          <Button
            variant="outline"
            size="icon"
            onClick={() =>
              setModal({
                product: { code: SK_produto, description: descricao || "" },
                visible: true,
              })
            }
          >
            <FaPlus color={TEXT_COLOR} />
          </Button>
        </Row>
        <Separator />
      </React.Fragment>
    );
  }

  useEffect(() => {
    if (!productsData) return;
    setProducts(productsData);
  }, [productsData]);

  return (
    <AuthenticatedLayout>
      <AdditionalInformationModal
        product={product}
        handleClose={() =>
          setModal({ product: { code: "", description: "" }, visible: false })
        }
        visible={visible}
      />
      <Row
        justify="space-between"
        className="max-h-[calc(100vh-150px)] h-full"
        align="center"
      >
        <div className="border h-full overflow-y-scroll w-1/2">
          <Text className="font-bold text-center mt-2">Produtos</Text>
          <Row className="w-full p-4">
            <Input
              list="productLeft"
              onChange={(e) => {
                setLeftInputFilter(e.target.value);
              }}
              placeholder="Pesquisar produto..."
            />
            <datalist id="productLeft">
              <Each
                array={productsLeft}
                renderItem={(product) => {
                  return (
                    <option key={product.SK_produto}>
                      {product.SK_produto}
                    </option>
                  );
                }}
              />
            </datalist>
            {/* <Button size="icon" variant="default" className="ml-2">
              <IoReload size={22} />
            </Button> */}
          </Row>
          <Separator />
          <Each
            array={filteredProductsLeft}
            renderItem={({ SK_produto, descricao, tipo_planejador }) =>
              productList({
                SK_produto,
                descricao,
                position: "left",
                tipo_planejador,
              })
            }
          />
        </div>
        <Col className="mx-6 items-center">
          <MdKeyboardDoubleArrowLeft
            className="mb-2"
            size={22}
            onClick={handleAllRightToLeft}
          />
          <MdKeyboardArrowLeft size={30} onClick={handleRightToLeft} />
          <SelectPlanningTypeDialog onConfirm={handleLeftToRight}>
            <MdKeyboardArrowRight size={30} />
          </SelectPlanningTypeDialog>
          <SelectPlanningTypeDialog onConfirm={handleAllLeftToRight}>
            <MdKeyboardDoubleArrowRight className="mt-2" size={22} />
          </SelectPlanningTypeDialog>
        </Col>
        <div className="border h-full overflow-y-scroll w-1/2">
          <Text className="font-bold text-center mt-2">
            Produtos Utilizados Planning Beer
          </Text>
          <div className="w-full p-4">
            <Input
              list="productRight"
              onChange={(e) => {
                setRightInputFilter(e.target.value);
              }}
              placeholder="Pesquisar produto..."
            />
            <datalist id="productRight">
              <Each
                array={productsRight}
                renderItem={(product) => {
                  return (
                    <option key={product.SK_produto}>
                      {product.SK_produto}
                    </option>
                  );
                }}
              />
            </datalist>
          </div>
          <Separator />
          <Each
            array={filteredProductsRight}
            renderItem={({ SK_produto, descricao, tipo_planejador }) =>
              productList({
                SK_produto,
                descricao,
                position: "right",
                tipo_planejador,
              })
            }
          />
        </div>
      </Row>
      <Row justify="end" className="mt-4">
        <Button onClick={handleProducts} disabled={isPending}>
          Salvar
        </Button>
      </Row>
    </AuthenticatedLayout>
  );
}

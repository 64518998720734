import { RealCollectionType } from "@/hooks/usePackagingSimulationMutation";
import { GetRealCollectionAccumulatedProps } from "../types";

type GetRealCollectionProps = {
  composition: string;
  day: string;
  realCollection: Array<RealCollectionType>;
};

export function getRealCollection({
  composition,
  day,
  realCollection,
}: GetRealCollectionProps) {
  const realCollectionTotal =
    realCollection.find(
      (realCollectionValue) =>
        realCollectionValue.produto == composition &&
        realCollectionValue.dia === day
    )?.total || 0;

  return { realCollectionTotal };
}

export function getRealCollectionAccumulated({
  composition,
  day,
  realCollectionAccumulated,
}: GetRealCollectionAccumulatedProps) {
  const realCollectionAccumulatedComposition =
    realCollectionAccumulated[composition];

  if (!realCollectionAccumulatedComposition) {
    return { realCollectionAccumulatedValue: 0 };
  }

  const realCollectionAccumulatedDay =
    realCollectionAccumulatedComposition.dias[day];

  return { realCollectionAccumulatedValue: realCollectionAccumulatedDay };
}

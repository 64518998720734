import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { resourceKeys, ResourcesType } from "./useResources";

export default function useResourcePatchMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ResourcesType) => {
      return instance.patch("/planningbeer/resource", { ...data });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: resourceKeys.all });
    },
  });
}

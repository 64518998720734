import DeleteDropdownItem from "@/components/DropdownItem/DeleteDropdownItem";
import EditDropdownItem from "@/components/DropdownItem/EditDropdownItem";
import React from "react";
import { useSimulatorContext } from "../../context";
import { ProductionSimulationType } from "@/hooks/useProductionSimulators";
import { Link } from "react-router-dom";
import { OperationsType, userStatusAction } from "../../utils/userActions";
import Each from "@/components/Each";
import ApproveItem from "./Items/ApproveItem";
import PublishItem from "./Items/PublishItem";
import RejectItem from "./Items/RejectItem";
import Row from "@/components/Row";
import { FaEye } from "react-icons/fa6";
import Text from "@/components/Text/Text";
import { TEXT_COLOR } from "@/constants";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";

export default function ActionDropdown(props: ProductionSimulationType) {
  const actions = userStatusAction({ status: props.status?.toLowerCase() });
  const { handleSimulator, handleDialog, handleDialogType } =
    useSimulatorContext();

  function handleDelete() {
    handleDialog();
    handleDialogType("delete");
    handleSimulator({ ...props });
  }

  function renderAction(action: OperationsType) {
    const items: { [key in OperationsType]: React.ReactNode } = {
      edit: (
        <Link
          to={"editar/" + props.SK_simulacao}
          onClick={() => {
            handleSimulator({ ...props });
          }}
        >
          <EditDropdownItem onClick={() => {}} />
        </Link>
      ),
      publish: <PublishItem {...props} />,
      approve: <ApproveItem {...props} />,
      reject: <RejectItem {...props} />,
      delete: <DeleteDropdownItem onClick={handleDelete} />,
      accept: <React.Fragment />,
      download: <React.Fragment />,
      status: <React.Fragment />,
      see: (
        <Link
          to={"visualizar/" + props.SK_simulacao}
          onClick={() => {
            handleSimulator({ ...props });
          }}
        >
          <DropdownMenuItem>
            <Row>
              <FaEye className="mr-2" color={TEXT_COLOR} />
              <Text>Visualizar</Text>
            </Row>
          </DropdownMenuItem>
        </Link>
      ),
    };

    return items[action];
  }

  return (
    <React.Fragment>
      <Each array={actions} renderItem={renderAction} />
    </React.Fragment>
  );
}

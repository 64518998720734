import DefaultLayout from "@/components/Layout/DefaultLayout";
import Text from "@/components/Text/Text";

export default function NotFound() {
  return (
    <DefaultLayout>
      <div className="flex justify-center items-center h-full">
        <Text className="text-2xl font-bold">Página não encontrada</Text>
      </div>
    </DefaultLayout>
  );
}

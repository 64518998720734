import { Input } from "@/components/ui/input";

function getHours(hours: number) {
  if (!hours) return;
  if (hours >= 24) return 23;
  if (hours < 0) return 0;
  return hours;
}

type HourTimePickerInputProps = {
  onChange: (value) => void;
  value: string | number;
  disabled?: boolean;
};

export default function HourTimePickerInput({
  onChange,
  value,
  disabled = false,
}: HourTimePickerInputProps) {
  return (
    <div className="w-fit">
      <Input
        min={0}
        max={24}
        defaultValue=""
        value={value}
        disabled={disabled}
        type="number"
        onChange={({ target: { value } }) => {
          return onChange(getHours(Number(value)));
        }}
      />
    </div>
  );
}

import instance from "@/api/business";
import { ApiResponse } from "./useEstimates";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

export type FactoryResourceType = {
  SK_fabrica_recurso: string | number;
  FK_fabrica?: string | number;
  FK_recurso?: string | number;
  vigencia_inicio?: string;
  vigencia_fim?: string;
  turno_de_trabalho?: string;
  abreviatura_empresa?: string;
  codigo?: string;
  cnpj?: string;
  descricao?: string;
  tipo?: string;
  tipo_de_embalagem?: null;
  codigo_centro_de_custo?: string;
};

export const factoryResourceKey = {
  all: ["factoriesResources"] as const,
  show: (id: number) => [...factoryResourceKey.all, "show", id] as const,
  factoryResources: (factoryKey: number) =>
    [...factoryResourceKey.all, "factory", factoryKey] as const,
};

export async function fetchFactoriesResources() {
  const response = await instance.post<ApiResponse<FactoryResourceType>>(
    "/planningbeer/factoryResource"
  );
  return response.data;
}

export function useFactoriesResources() {
  return useQuery({
    queryKey: factoryResourceKey.all,
    queryFn: fetchFactoriesResources,
    placeholderData: keepPreviousData,
  });
}

import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";

export default function CreateFactoryButton() {
  return (
    <Button asChild>
      <Text color="white">Cadastrar Fábrica</Text>
    </Button>
  );
}

import instance from "@/api/business";
import { ApiResponse } from "./useEstimates";
import {
  indisponibilityResourceKey,
  IndisponibilityResourceType,
} from "./useIndisponibilityResources";
import { useQuery } from "@tanstack/react-query";

async function fetchIndisponibilityResource({ id }: { id: number }) {
  if (!id) return;
  const response = await instance.post<
    ApiResponse<IndisponibilityResourceType>
  >("/planningbeer/indisponibilityResource/detail", { id });

  return response.data;
}

export function useIndisponibilityResource({ id }: { id: number }) {
  return useQuery({
    queryKey: indisponibilityResourceKey.show(id),
    queryFn: () => fetchIndisponibilityResource({ id }),
  });
}

import instance from "@/api/business";
import { ApiResponse, estimates } from "./useEstimates";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

export type EstimateStatusLogType = {
  FK_planejamento_demanda: number;
  status: string;
  nome_usuario: string;
  codigo_usuario: string | number;
  created_at: string;
  criado_por_usuario_nome: string;
  data_da_carga: string;
  criado_por_usuario_codigo: string | number;
};

async function fetchEstimateStatusLog({ id }: { id: number }) {
  if (!id) return;
  const response = await instance.post<ApiResponse<EstimateStatusLogType>>(
    "/planningbeer/forecast/status",
    {
      id,
    }
  );
  return response.data;
}

export default function useEstimateStatusLog({ id }: { id: number }) {
  return useQuery({
    queryKey: estimates.log(id),
    queryFn: () => fetchEstimateStatusLog({ id }),
    placeholderData: keepPreviousData,
  });
}

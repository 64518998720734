import TableContainer from "@/components/Container/TableContainer";
import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Table from "@/components/NewTable";
import { TableData } from "@/components/NewTable/type";
import Row from "@/components/Row";
import { getTableColumnOrder } from "@/utils/table";
import { ColumnOrderState, createColumnHelper } from "@tanstack/react-table";
import { useState } from "react";
import CreateFabricDialog from "./components/Dialog/CreateFabricDialog";
import ActionCell from "@/components/Cell/ActionCell";
import ActionCellDropdown from "./components/Dropdown/ActionCellDropdown";
import EditFabricDialog from "./components/Dialog/EditFabricDialog";
import useFactories from "@/hooks/useFactories";
import DeleteDialogAlert from "./components/DialogAlert/DeleteDialogAlert";
import ManagerFactoryModal from "./components/Modal/ManagerFactoryModal";
import useResources from "@/hooks/useResources";

export type FactoryColumnsType = {
  SK_fabrica: string | number;
  codigo: string;
  cnpj: string;
  abreviatura_empresa: string;
  endereco?: string;
  numero?: string;
  bairro?: string;
  estado?: string;
  cidade?: string;
  contato?: string;
  email?: string;
};

const columnHelper = createColumnHelper<FactoryColumnsType>();
const TABLE_ORDER_KEY = "factoryColumnOrder";

type FactoryTable = TableData<FactoryColumnsType>;

export default function Factory() {
  useResources();
  const { data } = useFactories();
  const { data: factories = [] } = data || {};

  const columns = [
    columnHelper.accessor("SK_fabrica", {
      header: "ID",
      id: "SK_fabrica",
      size: 100,
    }),
    columnHelper.accessor("codigo", {
      header: "Código",
      id: "codigo",
      size: 100,
    }),
    columnHelper.accessor("cnpj", {
      header: "CNPJ",
      id: "cnpj",
      size: 200,
    }),
    columnHelper.accessor("abreviatura_empresa", {
      header: "Abreviatura Empresa",
      id: "abreviatura_empresa",
      size: 300,
    }),
    columnHelper.accessor("endereco", {
      header: "Endereço",
      id: "endereco",
      size: 300,
      cell: ({ row }) => {
        const { endereco, numero, bairro } = row.original;
        if (!endereco && !numero && !bairro) return "";
        return `${endereco} ${numero}  ${bairro}`;
      },
    }),
    columnHelper.accessor("estado", {
      header: "Estado",
      id: "estado",
      size: 120,
    }),
    columnHelper.accessor("cidade", {
      header: "Cidade",
      id: "cidade",
      size: 200,
    }),
    columnHelper.accessor("contato", {
      header: "Contato",
      id: "contato",
      size: 130,
    }),
    columnHelper.accessor("email", {
      header: "Email",
      id: "email",
      size: 300,
    }),
    columnHelper.display({
      id: "Ações",
      header: "Ações",
      size: 100,
      cell: ({ row }) => {
        return <ActionCell items={<ActionCellDropdown {...row.original} />} />;
      },
    }),
  ];

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    getTableColumnOrder({ column: columns, key: TABLE_ORDER_KEY })
  );

  function handleColumnOrder(column: ColumnOrderState) {
    setColumnOrder(column);
    localStorage.setItem(TABLE_ORDER_KEY, JSON.stringify(column));
  }

  return (
    <AuthenticatedLayout>
      <Row justify="end" align="center">
        <CreateFabricDialog />
      </Row>
      <TableContainer>
        <Table<FactoryTable>
          columns={columns}
          getRowId={(row) => row.SK_fabrica.toString()}
          data={factories}
          onColumnOrderStateChange={handleColumnOrder}
          columnOrderState={columnOrder}
          meta={{
            layout: "stretch",
          }}
        />
      </TableContainer>
      <EditFabricDialog />
      <DeleteDialogAlert />
      <ManagerFactoryModal />
    </AuthenticatedLayout>
  );
}

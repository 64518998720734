import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { ROUTES } from "@/constants";
import { Link } from "react-router-dom";

export default function InvalidProductionPlanError() {
  return (
    <div className="flex h-full items-center justify-center">
      <div className="flex flex-col items-center">
        <Text className="text-lg font-bold">
          Não foi encontrado nenhum plano de produção
        </Text>
        <Link className="mt-4" to={"/" + ROUTES.productionPlan.index}>
          <Button>Ir Para Planos de Produção</Button>
        </Link>
      </div>
    </div>
  );
}

import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { useState } from "react";
import CreateResourceTypeForm from "../Form/CreateResourceTypeForm";

export default function CreateResourceTypeDialog() {
  const [open, setOpen] = useState(false);

  function handleDialog() {
    setOpen((prev) => !prev);
  }

  return (
    <Dialog onOpenChange={handleDialog} open={open}>
      <DialogTrigger>
        <Button>Cadastrar Tipo de Recurso</Button>
      </DialogTrigger>
      <DialogContent>
        <CreateResourceTypeForm handleClose={handleDialog} />
      </DialogContent>
    </Dialog>
  );
}

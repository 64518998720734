import { quantityFormater } from "@/utils/number";

type RealCollectionAccumulatedCellProps = {
  value: number;
};

export default function RealCollectionAccumulatedCell({
  value,
}: RealCollectionAccumulatedCellProps) {
  return <span>{quantityFormater(value)}</span>;
}

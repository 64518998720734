import { InventoryTableType } from "@/hooks/useInventory";
import { useInventoryContext } from "../../context";
import React from "react";
import DeleteDropdownItem from "@/components/DropdownItem/DeleteDropdownItem";
import EditLinkDropdownItem from "@/components/DropdownItem/EditLinkDropdownItem";

export default function ActionDropdown(props: InventoryTableType) {
  const { handleDialog, handleDialogType, handleInventory } =
    useInventoryContext();

  function handleDelete() {
    handleDialog();
    handleDialogType("delete");
    handleInventory({ ...props });
  }
  return (
    <React.Fragment>
      <EditLinkDropdownItem id={props.SK_inventario_carga} />
      <DeleteDropdownItem onClick={handleDelete} />
    </React.Fragment>
  );
}

import { ConfigurationsType } from "@/hooks/useConfiguration";
import { DialogTypeType, ReactChildrenType } from "@/types";
import { createContext, useContext, useState } from "react";

type ResourceFactoryProductContextProps = {
  visibleDialog: boolean;
  handleDialog: () => void;
  dialogType: DialogTypeType;
  handleDialogType: (type: DialogTypeType) => void;
  resourceType: ConfigurationsType;
  handleResourceType: (data: ConfigurationsType) => void;
  clear: () => void;
};

const ResourceTypeContext = createContext(
  {} as ResourceFactoryProductContextProps
);

export default function ResourceTypeProvider({ children }: ReactChildrenType) {
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [resourceType, setResourceType] = useState<ConfigurationsType>(
    {} as ConfigurationsType
  );
  const [dialogType, setDialogType] = useState<DialogTypeType>("nonSelected");

  function handleDialog() {
    setVisibleDialog((prev) => !prev);
  }

  function handleDialogType(value: DialogTypeType) {
    setDialogType(value);
  }

  function handleResourceType(value: ConfigurationsType) {
    setResourceType(value);
  }

  function clear() {
    setResourceType({} as ConfigurationsType);
    setDialogType("nonSelected");
    setVisibleDialog(false);
  }

  return (
    <ResourceTypeContext.Provider
      value={{
        visibleDialog,
        dialogType,
        resourceType,
        clear,
        handleResourceType,
        handleDialog,
        handleDialogType,
      }}
    >
      {children}
    </ResourceTypeContext.Provider>
  );
}

export function useResourceTypeContext() {
  return useContext(ResourceTypeContext);
}

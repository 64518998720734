import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Row from "@/components/Row";
import { Button } from "@/components/ui/button";
import { ROUTES } from "@/constants";
import { useNavigate } from "react-router-dom";
import IncludeSimulatorModal from "../components/Modal/IncludeSimulatorModal";
import { useFactoriesResources } from "@/hooks/useFactoriesResources";
import useProducts from "@/hooks/useProducts";
import { useProductionPlanContext } from "../context";
import { useSimulationProductionContext } from "@/pages/Simulacoes/context";
import useFactories from "@/hooks/useFactories";
import EditProductionPlanSimulatorModal from "../components/Modal/EditProductionPlanSimulatorModal";
import SimulationTable from "@/pages/Simulacoes/components/Table/SimulationTable";
import dayjs from "dayjs";
import { useProductionSimulation } from "@/hooks/useProductionSimulation";
import { useEffect } from "react";
import DotsLoading from "@/components/Loading/DotsLoading";
import Can from "@/components/Can";
import FilterDropdown, {
  CURRENT_DAY,
  FORTY_FIVE_DAYS,
} from "../components/Dropdown/FilterDropdown";
import ProductionPlanError from "../components/Error/ProductionPlanError";
import VersionModal from "../components/Modal/VersionModal";
import ProgrammedRealProductionModal from "../components/Modal/ProgrammedRealProductionModal";

export default function SeeProductionPlan() {
  useFactories();
  useFactoriesResources();
  useProducts();
  const navigate = useNavigate();
  const {
    productionPlan: { SK_plano_producao, FK_fabrica, revisao, valores_linhas },
    clear: clearProductionPlan,
    filterProductionPlan: {
      periodo_fim = FORTY_FIVE_DAYS,
      periodo_inicio = CURRENT_DAY,
      produtos,
      recursos,
    },
  } = useProductionPlanContext();
  const {
    productionPlan: { handleProductionPlan },
    clear: clearSimulationProduction,
    lines: { handleLineValuesArray },
    handleSimulationData,
  } = useSimulationProductionContext();

  const { data, isFetching, refetch, isRefetching, isError } =
    useProductionSimulation({
      FK_fabrica: String(FK_fabrica),
      valores_linhas: valores_linhas || "[]",
      produtos_selecionados: produtos,
      recursos_selecionados: recursos,
      periodo_inicio: dayjs(periodo_inicio).format("YYYY-MM-DD"),
      periodo_fim: dayjs(periodo_fim).format("YYYY-MM-DD"),
      is_visualizacao: true,
    });
  const { data: productionSimulation } = data || {};

  function handleSimulationPage(route: string) {
    handleProductionPlan({
      SK_plano_producao,
      FK_fabrica,
      revisao,
    });
    navigate("/" + route);
  }

  useEffect(() => {
    if (!productionSimulation) return;
    handleSimulationData(productionSimulation);
    handleLineValuesArray(
      productionSimulation.simulador_producao.valores_linhas || []
    );
  }, [productionSimulation]);

  useEffect(() => {
    refetch();
  }, [periodo_fim, periodo_inicio, produtos, recursos]);

  useEffect(() => {
    clearSimulationProduction();
  }, []);

  if (!SK_plano_producao) {
    navigate("/" + ROUTES.productionPlan.index);
    return <></>;
  }

  return (
    <AuthenticatedLayout
      className="max-h-[calc(100vh-150px)] h-full"
      title="Visualizando Plano de Produção"
    >
      <Can
        condition={isError}
        onTrue={<ProductionPlanError />}
        onFalse={
          <>
            <Row justify="end" className="gap-2 h-fit">
              <ProgrammedRealProductionModal
                fkFactory={Number(FK_fabrica)}
                fkProductionPlan={Number(SK_plano_producao)}
              />
              <VersionModal />
              <FilterDropdown fkFactory={String(FK_fabrica)} />
              <EditProductionPlanSimulatorModal />
              <IncludeSimulatorModal />
              <Button
                onClick={() => handleSimulationPage(ROUTES.simulations.index)}
              >
                Cadastrar Simulador
              </Button>
            </Row>
            <div className="h-full">
              <Can
                condition={isFetching || isRefetching}
                onTrue={<DotsLoading />}
              />
              <Can
                condition={!isFetching || !isRefetching}
                onTrue={<SimulationTable disabled={true} />}
              />
            </div>
            <Row className="mt-4" justify="end">
              <Button
                variant="outline"
                onClick={() => {
                  clearProductionPlan();
                  clearSimulationProduction();
                  navigate("/" + ROUTES.productionPlan.index);
                }}
              >
                Voltar
              </Button>
            </Row>
          </>
        }
      />
    </AuthenticatedLayout>
  );
}

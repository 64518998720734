import { ReactChildrenType } from "@/types";
import { createContext, useContext, useState } from "react";
import { EstimatesColumnProps } from "..";

type SheetProps = EstimatesColumnProps & {
  tipo: "approve" | "accept" | "publish" | "delete" | "status" | "reject";
};

type AlertDialogContextProps = {
  visibleDialog: boolean;
  handleDialogVisibility: () => void;
  sheet: SheetProps;
  actualStatus: string;
  handleActualStatus: (status: string) => void;
  clear: () => void;
  handleSheet: (sheet: SheetProps) => void;
};

const AlertDialogContext = createContext({} as AlertDialogContextProps);

export function AlertDialogProvider({ children }: ReactChildrenType) {
  const [actualStatus, setActualStatus] = useState("");
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [sheet, setSheet] = useState<SheetProps>({} as SheetProps);

  function handleDialogVisibility() {
    setVisibleDialog((prev) => !prev);
  }

  function handleSheet(sheet: SheetProps) {
    setSheet(sheet);
  }

  function handleActualStatus(status: string) {
    setActualStatus(status);
  }

  function clear() {
    setVisibleDialog(false);
    setActualStatus("");
    setSheet({} as SheetProps);
  }

  return (
    <AlertDialogContext.Provider
      value={{
        handleActualStatus,
        actualStatus,
        handleSheet,
        handleDialogVisibility,
        visibleDialog,
        sheet,
        clear,
      }}
    >
      {children}
    </AlertDialogContext.Provider>
  );
}

export function useAlertDialogContext() {
  return useContext(AlertDialogContext);
}

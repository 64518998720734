import { SimulationResponseType } from "@/hooks/useProductionSimulationMutation";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";

dayjs.extend(isoWeek);

export function getDates({
  initialPeriod,
  finalPeriod,
}: {
  initialPeriod?: string;
  finalPeriod?: string;
}) {
  if (!initialPeriod || !finalPeriod) return [];
  const days: Array<string> = [];
  while (dayjs(initialPeriod).isBefore(finalPeriod)) {
    days.push(dayjs(initialPeriod).format("YYYY-MM-DD"));
    initialPeriod = dayjs(initialPeriod).add(1, "day").format("YYYY-MM-DD");
  }
  if (!days.includes(dayjs(finalPeriod).format("YYYY-MM-DD"))) {
    days.push(dayjs(finalPeriod).format("YYYY-MM-DD"));
  }

  return days;
}

export function getDatesWithHours({
  initialPeriod,
  finalPeriod,
}: {
  initialPeriod?: string;
  finalPeriod?: string;
}) {
  if (!initialPeriod || !finalPeriod) return [];
  const days: Array<string> = [];
  while (dayjs(initialPeriod).isBefore(finalPeriod)) {
    days.push(dayjs(initialPeriod).format("YYYY-MM-DD HH:mm:ss"));
    initialPeriod = dayjs(initialPeriod)
      .add(1, "day")
      .format("YYYY-MM-DD HH:mm");
  }
  if (!days.includes(dayjs(finalPeriod).format("YYYY-MM-DD HH:mm:ss"))) {
    days.push(dayjs(finalPeriod).format("YYYY-MM-DD HH:mm"));
  }

  return days;
}

export function getPeriodDays({
  initialPeriod,
  finalPeriod,
}: {
  finalPeriod: string;
  initialPeriod: string;
}) {
  return getDates({
    finalPeriod: finalPeriod,
    initialPeriod: initialPeriod,
  });
}

export function getIndisponibilityDays({
  indisponibility,
}: {
  indisponibility: SimulationResponseType["indisponibilidade"];
}) {
  return (
    indisponibility?.flatMap(({ vigencia_inicio, vigencia_fim }) => {
      return getDates({
        finalPeriod: vigencia_fim,
        initialPeriod: vigencia_inicio,
      });
    }) || []
  );
}

export function getIndisponibilityLine({
  indisponibilityLine,
}: {
  indisponibilityLine: SimulationResponseType["indisponibilidade_linhas"];
}) {
  return (
    indisponibilityLine?.map(
      ({ vigencia_inicio, vigencia_fim, FK_fabrica_recurso, motivo }) => {
        const dates = getDatesWithHours({
          finalPeriod: vigencia_fim,
          initialPeriod: vigencia_inicio,
        });

        return {
          dates,
          motivo,
          FK_fabrica_recurso,
        };
      }
    ) || []
  );
}

import Each from "@/components/Each";
import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { TEXT_COLOR } from "@/constants";
import { FactoriesAndResourcesType } from "@/hooks/useProduct";
import React from "react";
import { IoLayers } from "react-icons/io5";
import { MdFactory } from "react-icons/md";

type FactoryCardProps = FactoriesAndResourcesType;

export default function FactoryCard(props: FactoryCardProps) {
  if (!props) return;
  const { abreviatura_empresa, codigo, recursos } = props;
  return (
    <React.Fragment>
      <Row align="center" className="mb-2">
        <MdFactory color={TEXT_COLOR} size={30} />
        <Text className="text-sm ml-2 font-bold mt-1">
          {codigo} - {abreviatura_empresa}
        </Text>
      </Row>
      <Each
        array={recursos}
        renderItem={({ codigo_linha_de_producao, descricao }) => {
          return (
            <Row className="ml-8 mt-4">
              <IoLayers color={TEXT_COLOR} size={18} />
              <Text className="ml-2 text-sm font-bold">
                {codigo_linha_de_producao}, {descricao}
              </Text>
            </Row>
          );
        }}
      />
    </React.Fragment>
  );
}

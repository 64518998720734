import TableContainer from "@/components/Container/TableContainer";
import Empty from "@/components/Empty";
import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Table from "@/components/NewTable";
import { TableData } from "@/components/NewTable/type";
import Row from "@/components/Row";
import { Button } from "@/components/ui/button";
import { ROUTES } from "@/constants";
import useInventoryItemPatchMutation from "@/hooks/useInventoryItemPatchMutation";
import {
  InventoryItemTableType,
  useInventoryItems,
} from "@/hooks/useInventoryItems";
import i18n from "@/i18n";
import { validationError } from "@/utils/api";
import { quantityFormater } from "@/utils/number";
import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

const columnHelper = createColumnHelper<InventoryItemTableType>();

type InventoryItemTablePropsType = TableData<InventoryItemTableType>;

export type UpdatedValuesType = {
  id: number | string;
  columnId: string;
  value: string;
};

export default function SeeInventory() {
  const navigate = useNavigate();
  const param = useParams();
  const { id } = param;
  const { data } = useInventoryItems({ id: String(id) });
  const { data: inventory = [] } = data || {};
  const { mutateAsync, isPending } = useInventoryItemPatchMutation();
  const [inventoryItems, setInventoryItems] =
    useState<InventoryItemTableType[]>(inventory);
  const [updatedData, setUpdatedData] = useState<Array<UpdatedValuesType>>([]);

  const columns = [
    columnHelper.accessor("FK_produto", {
      header: "Código Produto",
      id: "FK_produto",
    }),
    columnHelper.accessor("descricao", {
      header: "Descrição do Produto",
      id: "descricao",
      size: 600,
    }),
    columnHelper.accessor("codigo_fabrica", {
      header: "Filial de Origem",
      id: "codigo_fabrica",
      size: 200,
    }),
    columnHelper.accessor("quantidade", {
      header: "Quantidade",
      id: "quantidade",
      meta: {
        enableColumnEditable: true,
        inputType: "number",
        cell: {
          formatterFn: ({ row: { original } }) => {
            if (!original.quantidade) return <Empty />;
            return quantityFormater(Number(original.quantidade));
          },
        },
      },
    }),
    columnHelper.accessor("vigencia", {
      header: "Vigência",
      id: "vigencia",
      cell: ({ row: { original } }) => {
        if (!original.vigencia) return <Empty />;
        const date = dayjs(original.vigencia).format("DD/MM/YYYY");
        return <span>{date}</span>;
      },
    }),
  ];

  function handleUpdateRows({ columnId, id, value }: UpdatedValuesType) {
    setInventoryItems((prev) => {
      const updatedIndex = prev.findIndex((row) => row.SK_inventario == id);
      if (updatedIndex === -1) {
        return prev;
      }
      const updatedRow = { ...prev[updatedIndex] };
      updatedRow[columnId] = value;

      const newRows = [...prev];
      newRows[updatedIndex] = updatedRow;

      return newRows;
    });
  }

  function handleUpdateData({ columnId, id, value }: UpdatedValuesType) {
    setUpdatedData((prev) => {
      const existingIndex = prev.findIndex(
        (item) => item.id === id && item.columnId === columnId
      );
      if (existingIndex !== -1) {
        const updatedData = [...prev];
        updatedData[existingIndex] = { columnId, id, value };
        return updatedData;
      }
      return [...prev, { columnId, id, value }];
    });
  }

  async function handleUpdate() {
    if (isPending) return;
    try {
      await mutateAsync({ rows: updatedData, skInventoryCharge: Number(id) });
      toast.success(i18n.editSuccess);
      setUpdatedData([]);
      return;
    } catch (error) {
      validationError(error);
    }
  }

  useEffect(() => {
    setInventoryItems(inventory);
  }, [inventory]);

  return (
    <AuthenticatedLayout>
      <TableContainer>
        <Table<InventoryItemTablePropsType>
          columns={columns}
          data={inventoryItems}
          getRowId={(row) => row.SK_inventario.toString()}
          meta={{
            layout: "stretch",
            updatedData: updatedData,
            updateData: (id, columnId, value) => {
              handleUpdateRows({ id, columnId, value });
              handleUpdateData({ id, columnId, value });
            },
          }}
        />
      </TableContainer>
      <Row className="mt-4" justify="end">
        <Button
          variant="ghost"
          onClick={() => {
            navigate("/" + ROUTES.inventory.index);
          }}
        >
          Voltar
        </Button>
        <Button
          className="ml-4"
          onClick={handleUpdate}
          disabled={isPending || updatedData.length == 0}
        >
          Salvar
        </Button>
      </Row>
    </AuthenticatedLayout>
  );
}

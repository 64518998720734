import instance from "@/api/business";
import { useQuery } from "@tanstack/react-query";

type PermissionResponse = {
  data: {
    role: string;
  };
};

async function fetchPermission() {
  const response = await instance.post<PermissionResponse>(
    "/planningbeer/permission"
  );
  return response.data;
}

export default function usePermission() {
  return useQuery({
    queryKey: ["permission"],
    queryFn: () => fetchPermission(),
  });
}

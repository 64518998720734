import DeleteDropdownItem from "@/components/DropdownItem/DeleteDropdownItem";
import EditDropdownItem from "@/components/DropdownItem/EditDropdownItem";
import { PackagingSimulatorsType } from "@/hooks/usePackagingSimulators";
import React from "react";
import { Link } from "react-router-dom";
import { usePackagingSimulatorContext } from "../../context";

type ActionDropdownProps = PackagingSimulatorsType;

export default function ActionDropdown(props: ActionDropdownProps) {
  const { handleDialogType, handleSimulator, handleDialog } =
    usePackagingSimulatorContext();

  function handleDelete() {
    handleDialog();
    handleDialogType("delete");
    handleSimulator({ ...props });
  }

  return (
    <React.Fragment>
      <Link to={"editar/" + props.SK_simulacao_embalagem}>
        <EditDropdownItem onClick={() => {}} />
      </Link>
      <DeleteDropdownItem onClick={handleDelete} />
    </React.Fragment>
  );
}

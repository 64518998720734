import React, { useEffect } from "react";
import {
  OperationsType,
  StatusType,
  userStatusAction,
} from "../../utils/userActions";
import Each from "@/components/Each";
import EditItem from "./Items/EditItem";
import VisualizeItem from "./Items/VisualizeItem";
import DownloadItem from "./Items/DownloadItem";
import PublishItem from "./Items/PublishItem";
import DeleteItem from "./Items/DeleteItem";
import AcceptItem from "./Items/AcceptItem";
import ApproveItem from "./Items/ApproveItem";
import { EstimatesColumnProps } from "../..";
import { useAlertDialogContext } from "../../context/AlertDialogContext";
import StatusItem from "./Items/StatusItem";
import RejectItem from "./Items/RejectItem";

type ActionDropdownItemsProps = StatusType & EstimatesColumnProps;

export default function ActionDropdownItems(props: ActionDropdownItemsProps) {
  const { handleActualStatus } = useAlertDialogContext();
  const actions = userStatusAction({ status: props.status?.toLowerCase() });

  function renderAction(action: OperationsType) {
    const items: { [key in OperationsType]: React.ReactNode } = {
      edit: <EditItem id={props.SK_planejamento_demanda} />,
      see: <VisualizeItem id={props.SK_planejamento_demanda} />,
      accept: <AcceptItem {...props} />,
      approve: <ApproveItem {...props} />,
      download: <DownloadItem {...props} />,
      publish: <PublishItem {...props} />,
      delete: <DeleteItem {...props} />,
      status: <StatusItem {...props} />,
      reject: <RejectItem {...props} />,
    };

    return items[action];
  }

  useEffect(() => {
    handleActualStatus(props.status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <React.Fragment>
      <Each array={actions} renderItem={renderAction} />
    </React.Fragment>
  );
}

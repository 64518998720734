import { Table } from "@tanstack/react-table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
  DropdownMenuPortal,
  DropdownMenuGroup,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { RotateCcw, Eye, Settings2 } from "lucide-react";
import ColumnList from "./components/ColumnList";
// import TableColumnOrderDialog from '../TableColumnOrderDialog'
// import useDisclosure from '@/hooks/useDisclosure'

interface TableSettingsProps<TData> {
  table: Table<TData>;
}

const TableSettings = <T,>({ table }: TableSettingsProps<T>) => {
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            className="gap-2 p-0 cursor-pointer w-9 h-9 hover:bg-slate-100 hover:text-slate-500"
            variant="ghost"
          >
            <Settings2 size={14} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Configurações</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuSub>
              <DropdownMenuSubTrigger className="gap-2">
                <Eye size={14} />
                Colunas
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <ColumnList table={table} />
              </DropdownMenuPortal>
            </DropdownMenuSub>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuItem
              className="gap-2"
              onClick={() => {
                table.resetColumnOrder();
                table.reset();
              }}
            >
              <RotateCcw size={14} />
              Resetar tabela
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

export default TableSettings;

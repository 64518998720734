import Each from "@/components/Each";
import Item from "./Item";
import { FaBox, FaFileAlt, FaQuestion } from "react-icons/fa";
import {
  IoBeer,
  IoExtensionPuzzleSharp,
  IoHome,
  IoLayers,
  IoLayersOutline,
} from "react-icons/io5";
import { PiPlugsFill } from "react-icons/pi";
import { TEXT_COLOR, USER_ACCESS } from "@/constants";
import { AiFillProject } from "react-icons/ai";
import { MdFactory, MdInventory, MdSchema } from "react-icons/md";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import Text from "@/components/Text/Text";
import Row from "@/components/Row";
import { FaMicrochip, FaStore } from "react-icons/fa6";
import usePermission from "@/hooks/usePermission";
import { BiPackage } from "react-icons/bi";
import { IoMdPricetag } from "react-icons/io";

export type SidebarItemProps = {
  label?: React.ReactNode;
  path?: string;
  key: number;
  icon?: React.ReactNode;
  visible?: boolean;
  onClick?: () => void;
  render?: React.ReactNode;
};

export default function SidebarItems() {
  const { data } = usePermission();
  const { data: permission } = data || {};
  function renderItems(item: SidebarItemProps) {
    if (!item.visible) return null;
    if (item.render) return item.render;
    return <Item {...item} key={item.key} />;
  }

  const isLogistics = USER_ACCESS.logistics == permission?.role;

  const items: Array<SidebarItemProps> = [
    {
      key: 1,
      label: "Inicio",
      path: "/inicio",
      icon: <IoHome color={TEXT_COLOR} size={14} />,
      visible: true,
    },
    {
      key: 3,
      label: "Rotinas",
      visible: true,
      render: (
        <Accordion type="single" collapsible className="w-full border-none">
          <AccordionItem value="item-1">
            <AccordionTrigger className="pb-0">
              <Row>
                <FaMicrochip color={TEXT_COLOR} size={16} className="mr-2" />
                <Text>Rotinas</Text>
              </Row>
            </AccordionTrigger>
            <AccordionContent className="ml-4 mt-4">
              <Item
                key={2}
                label="Estimativa de Demandas"
                path="/estimativaDemandas"
                icon={<FaFileAlt color={TEXT_COLOR} size={14} />}
              />
            </AccordionContent>
            <AccordionContent className="ml-4">
              <Item
                key={9}
                label="Simuladores Produto Acabado"
                path="/simuladores"
                icon={<AiFillProject color={TEXT_COLOR} size={18} />}
                visible={isLogistics}
              />
            </AccordionContent>
            <AccordionContent className="ml-4">
              <Item
                key={11}
                label="Plano de Produção"
                path="/planoProducao"
                icon={<MdSchema size={18} color={TEXT_COLOR} />}
                visible={isLogistics}
              />
            </AccordionContent>
            <AccordionContent className="ml-4">
              <Item
                key={10}
                label="Simuladores Embalagem"
                path="/simuladoresEmbalagem"
                icon={<AiFillProject color={TEXT_COLOR} size={18} />}
                visible={isLogistics}
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      ),
    },
    {
      key: 5,
      visible: isLogistics,
      render: (
        <Accordion type="single" collapsible className="w-full border-none">
          <AccordionItem value="item-1">
            <AccordionTrigger className="pb-0">
              <Row>
                <IoExtensionPuzzleSharp
                  color={TEXT_COLOR}
                  size={18}
                  className="mr-2"
                />
                <Text>Dados Mestre</Text>
              </Row>
            </AccordionTrigger>
            <AccordionContent className="mt-4 ml-4">
              <Item
                icon={<FaStore size={16} color={TEXT_COLOR} />}
                key={12}
                label="Fornecedores"
                path="/fornecedores"
              />
            </AccordionContent>
            <AccordionContent className="ml-4">
              <Item
                icon={<BiPackage size={18} color={TEXT_COLOR} />}
                key={11}
                label="Embalagens"
                path="/embalagens"
              />
            </AccordionContent>
            <AccordionContent className="ml-4">
              <Item
                icon={<IoMdPricetag size={18} color={TEXT_COLOR} />}
                key={20}
                label="Embalagens x Fornecedores"
                path="/embalagensFornecedores"
              />
            </AccordionContent>
            <AccordionContent className="ml-4">
              <Item
                key={5}
                label="Produtos"
                visible={true}
                path="/produtos"
                icon={<FaBox color={TEXT_COLOR} size={14} />}
              />
            </AccordionContent>
            <AccordionContent className="ml-4">
              <Item
                key={6}
                label="Inventário"
                visible={true}
                path="/inventario"
                icon={<MdInventory color={TEXT_COLOR} size={16} />}
              />
            </AccordionContent>
            <AccordionContent className="ml-4">
              <Item
                key={1}
                label="Recursos"
                visible={true}
                path="/recursos"
                icon={<IoLayers color={TEXT_COLOR} size={18} />}
              />
            </AccordionContent>
            <AccordionContent className="ml-4">
              <Item
                key={6}
                label="Tipo de Recursos"
                visible={true}
                path="/tipoRecursos"
                icon={<IoLayersOutline color={TEXT_COLOR} size={18} />}
              />
            </AccordionContent>
            <AccordionContent className="ml-4">
              <Item
                key={2}
                label="Fábricas"
                visible={true}
                path="/fabricas"
                icon={<MdFactory color={TEXT_COLOR} size={18} />}
              />
            </AccordionContent>
            <AccordionContent className="ml-4">
              <Item
                key={3}
                label="Recursos x Fábricas"
                visible={true}
                path="/fabricasRecursos"
                icon={<MdFactory color={TEXT_COLOR} size={18} />}
              />
            </AccordionContent>
            <AccordionContent className="ml-4">
              <Item
                key={3}
                label="Recursos Fábricas x Produtos"
                visible={true}
                path="/fabricaRecursoProdutos"
                icon={<IoBeer color={TEXT_COLOR} size={18} />}
              />
            </AccordionContent>
            <AccordionContent className="ml-4">
              <Item
                key={4}
                label="Indisponibilidade Programada"
                visible={true}
                path="/indisponibilidadeRecursos"
                icon={<PiPlugsFill color={TEXT_COLOR} size={18} />}
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      ),
    },
    {
      key: 7,
      icon: <FaQuestion color={TEXT_COLOR} />,
      visible: true,
      label: "Ajuda",
      path: "/ajuda",
    },
  ];

  if (!permission) return null;
  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <Each
          wrapper={{ className: "flex mb-4" }}
          array={items}
          renderItem={renderItems}
        />
      </div>
    </div>
  );
}

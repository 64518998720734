import Col from "@/components/Col";
import Each from "@/components/Each";
import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import { useEffect, useRef, useState } from "react";
import { SupplierType, useSuppliers } from "@/hooks/useSuppliers";
import { useVirtualizer } from "@tanstack/react-virtual";
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import { useSupplierPatchMutation } from "@/hooks/useSupplierPatchMutation";
import { validationError } from "@/utils/api";
import { toast } from "sonner";

const ACTIVE = 1;
const INACTIVE = 0;

type SelectedItemsType = {
  position: "left" | "right";
  SK_fornecedor: SupplierType["SK_fornecedor"];
};

function filteredItems(items: Array<SupplierType>, filter: string) {
  return items.filter((item) =>
    item.nome_reduzido.toLowerCase().includes(filter.toLowerCase())
  );
}

export default function Suppliers() {
  const { mutateAsync, isPending } = useSupplierPatchMutation();
  const inactiveDivParent = useRef<HTMLDivElement>(null);
  const activeDivParent = useRef<HTMLDivElement>(null);
  const { data } = useSuppliers();
  const { data: suppliersData = [] } = data || {};
  const [suppliers, setSuppliers] = useState<Array<SupplierType>>([]);
  const [leftInputFilter, setLeftInputFilter] = useState<string>("");
  const [rightInputFilter, setRightInputFilter] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<Array<SelectedItemsType>>(
    []
  );

  const inactiveItems = suppliers.filter((item) => item.ativo === INACTIVE);
  const activeItems = suppliers.filter((item) => item.ativo === ACTIVE);

  const filteredInactiveItems = filteredItems(inactiveItems, leftInputFilter);
  const filteredActiveItems = filteredItems(activeItems, rightInputFilter);

  const inactiveRowVirtualizer = useVirtualizer({
    count: filteredInactiveItems.length,
    estimateSize: () => 65,
    overscan: 5,
    getScrollElement: () => inactiveDivParent?.current,
  });

  const activeRowVirtualizer = useVirtualizer({
    count: filteredActiveItems.length,
    estimateSize: () => 65,
    overscan: 5,
    getScrollElement: () => activeDivParent?.current,
  });

  function handleProductSelection({
    SK_fornecedor,
    position,
  }: SelectedItemsType) {
    const isSelected = selectedItems.some(
      (item) => item.SK_fornecedor === SK_fornecedor
    );

    if (isSelected) {
      setSelectedItems((prev) =>
        prev.filter((item) => item.SK_fornecedor !== SK_fornecedor)
      );
      return;
    }

    setSelectedItems((prev) => [...prev, { SK_fornecedor, position }]);
  }

  function handleLeftToRight() {
    const productsToMove = selectedItems.map(
      ({ SK_fornecedor }) => SK_fornecedor
    );
    const updatedProducts = suppliers.map((product) => {
      if (productsToMove.includes(product.SK_fornecedor)) {
        return { ...product, ativo: ACTIVE };
      }
      return product;
    });

    setSuppliers(updatedProducts);
    setSelectedItems([]);
  }

  function handleRightToLeft() {
    const productsToMove = selectedItems.map(
      ({ SK_fornecedor }) => SK_fornecedor
    );
    const updatedProducts = suppliers.map((product) => {
      if (productsToMove.includes(product.SK_fornecedor)) {
        return { ...product, ativo: INACTIVE };
      }
      return product;
    });

    setSuppliers(updatedProducts);
    setSelectedItems([]);
  }

  function handleAllLeftToRight() {
    const updatedProducts = suppliers.map((product) => {
      return { ...product, ativo: ACTIVE };
    });

    setSuppliers(updatedProducts);
  }

  function handleAllRightToLeft() {
    const updatedProducts = suppliers.map((product) => {
      return { ...product, ativo: INACTIVE };
    });

    setSuppliers(updatedProducts);
  }

  async function handleSuppliers() {
    if (isPending) return;
    try {
      const suppliersKey = activeItems.map(
        ({ SK_fornecedor }) => SK_fornecedor
      );
      await mutateAsync({ skSupplier: suppliersKey });
      return toast.success("Fornecedores salvos com sucesso");
    } catch (error) {
      validationError(error);
    }
  }

  useEffect(() => {
    if (!suppliersData) return;
    setSuppliers(suppliersData);
  }, [suppliersData]);

  return (
    <AuthenticatedLayout>
      <Row
        justify="space-between"
        className="max-h-[calc(100vh-150px)] h-full"
        align="center"
      >
        <div
          className="border h-full w-1/2 min-h-full overflow-y-scroll"
          ref={inactiveDivParent}
        >
          <Text className="font-bold text-center mt-2">Fornecedores</Text>
          <Row className="w-full p-4">
            <Input
              list="productLeft"
              onChange={(e) => {
                setLeftInputFilter(e.target.value);
              }}
              placeholder="Pesquisar fornecedor..."
            />
            <datalist id="productLeft">
              <Each
                array={inactiveItems}
                renderItem={({ nome_reduzido }) => {
                  return <option key={nome_reduzido}>{nome_reduzido}</option>;
                }}
              />
            </datalist>
          </Row>
          <Separator />
          <div
            style={{
              height: `${inactiveRowVirtualizer.getTotalSize()}px`,
              width: "100%",
              position: "relative",
            }}
          >
            {inactiveRowVirtualizer.getVirtualItems().map((virtualRow) => {
              const item = filteredInactiveItems[virtualRow.index];
              return (
                <div
                  key={virtualRow.index}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: `${filteredInactiveItems[virtualRow.index]}px`,
                    transform: `translateY(${virtualRow.start}px)`,
                  }}
                >
                  <Row className="p-3" align="center" justify="space-between">
                    <Row align="center">
                      <Checkbox
                        checked={selectedItems.some(
                          (selectedItem) =>
                            selectedItem.SK_fornecedor === item.SK_fornecedor
                        )}
                        className="mr-4"
                        onClick={() => {
                          handleProductSelection({
                            SK_fornecedor: item.SK_fornecedor,
                            position: "left",
                          });
                        }}
                      />
                      <div>
                        <Text>
                          {item?.codigo + " - " + item?.nome_reduzido}
                        </Text>
                      </div>
                    </Row>
                  </Row>
                  <Separator />
                </div>
              );
            })}
          </div>
        </div>
        <Col className="mx-6 items-center">
          <MdKeyboardDoubleArrowLeft
            className="mb-2"
            size={22}
            onClick={handleAllRightToLeft}
          />
          <MdKeyboardArrowLeft size={30} onClick={handleRightToLeft} />
          <MdKeyboardArrowRight size={30} onClick={handleLeftToRight} />
          <MdKeyboardDoubleArrowRight
            className="mt-2"
            size={22}
            onClick={handleAllLeftToRight}
          />
        </Col>
        <div
          className="border h-full overflow-y-scroll w-1/2"
          ref={activeDivParent}
        >
          <Text className="font-bold text-center mt-2">
            Fornecedores Utilizados Planning Beer
          </Text>
          <div className="w-full p-4">
            <Input
              list="productRight"
              onChange={(e) => {
                setRightInputFilter(e.target.value);
              }}
              placeholder="Pesquisar produto..."
            />
            <datalist id="productRight">
              <Each
                array={activeItems}
                renderItem={({ nome_reduzido }) => {
                  return <option key={nome_reduzido}>{nome_reduzido}</option>;
                }}
              />
            </datalist>
          </div>
          <Separator />
          <div
            style={{
              height: `${activeRowVirtualizer.getTotalSize()}px`,
              width: "100%",
              position: "relative",
            }}
          >
            {activeRowVirtualizer.getVirtualItems().map((virtualRow) => {
              const item = filteredActiveItems[virtualRow.index];
              return (
                <div
                  key={virtualRow.index}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: `${filteredActiveItems[virtualRow.index]}px`,
                    transform: `translateY(${virtualRow.start}px)`,
                  }}
                >
                  <Row className="p-3" align="center" justify="space-between">
                    <Row align="center">
                      <Checkbox
                        checked={selectedItems.some(
                          (selectedItem) =>
                            selectedItem.SK_fornecedor === item.SK_fornecedor
                        )}
                        className="mr-4"
                        onClick={() => {
                          handleProductSelection({
                            SK_fornecedor: item.SK_fornecedor,
                            position: "right",
                          });
                        }}
                      />
                      <div>
                        <Text>
                          {item?.codigo + " - " + item?.nome_reduzido}
                        </Text>
                      </div>
                    </Row>
                  </Row>
                  <Separator />
                </div>
              );
            })}
          </div>
        </div>
      </Row>
      <Row justify="end" className="mt-4">
        <Button onClick={handleSuppliers} disabled={isPending}>
          Salvar
        </Button>
      </Row>
    </AuthenticatedLayout>
  );
}

import { Link } from "react-router-dom";
import { DropdownMenuItem } from "../ui/dropdown-menu";
import Row from "../Row";
import { FaEdit } from "react-icons/fa";
import Text from "../Text/Text";
import { TEXT_COLOR } from "@/constants";

export default function EditLinkDropdownItem({ id }: { id: string | number }) {
  return (
    <DropdownMenuItem>
      <Link to={"editar/" + id}>
        <Row>
          <FaEdit className="mr-2" color={TEXT_COLOR} />
          <Text>Editar</Text>
        </Row>
      </Link>
    </DropdownMenuItem>
  );
}

import { Button, ButtonProps } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { useState } from "react";
import CreateSimulationForm from "../Form/CreateSimulationForm";

type CreateSimulationDialogProps = {
  title?: string;
  variant?: ButtonProps["variant"];
  defaultOpen?: boolean;
};

export default function CreateSimulationDialog({
  title = "Gerar Simulação",
  variant = "default",
  defaultOpen = true,
}: CreateSimulationDialogProps) {
  const [open, setOpen] = useState(defaultOpen);

  function handleDialog() {
    setOpen((prev) => !prev);
  }

  return (
    <Dialog onOpenChange={handleDialog} open={open}>
      <DialogTrigger>
        <Button variant={variant}>{title}</Button>
      </DialogTrigger>
      <DialogContent>
        <CreateSimulationForm handleClose={handleDialog} />
      </DialogContent>
    </Dialog>
  );
}

import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import CreatePackagingSimulationDialog from "./components/Dialog/CreatePackagingSimulationDialog";
import Row from "@/components/Row";
import { useProductionSimulators } from "@/hooks/useProductionSimulators";
import PackagingSimulationTable from "./components/Table";
import { useEffect } from "react";
import { usePackagingSimulationContext } from "./context";
import { useNavigate } from "react-router-dom";
import usePackagingSimulatorMutation from "@/hooks/usePackagingSimulatorMutation";
import { toast } from "sonner";
import { ROUTES } from "@/constants";
import { transformApiError } from "@/utils/api";
import Can from "@/components/Can";
import { Button } from "@/components/ui/button";
import Text from "@/components/Text/Text";
import { VisibleIconButton } from "./components/Button/VisibleIconButton";

export default function PackagingSimulations() {
  useProductionSimulators();
  const navigate = useNavigate();
  const { mutateAsync, isPending } = usePackagingSimulatorMutation();
  const {
    clear,
    packagingSimulationData,
    packagingSupplier: { packagingSupplierCellValue },
  } = usePackagingSimulationContext();
  const { simulacao_producao } = packagingSimulationData;

  async function handleCreateSimulator() {
    if (isPending) return;
    try {
      await mutateAsync({
        FK_simulacao: simulacao_producao?.SK_simulacao,
        valores_fornecedores: packagingSupplierCellValue,
      });
      toast.success("Simulação salva com sucesso!");
      navigate("/" + ROUTES.packagingSimulators.index);
      return clear();
    } catch (error) {
      return transformApiError(error);
    }
  }

  useEffect(() => {
    clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthenticatedLayout>
      <Row justify="end" className="gap-4">
        <VisibleIconButton />
        <CreatePackagingSimulationDialog />
      </Row>
      <PackagingSimulationTable />
      <Can
        condition={!!packagingSimulationData.simulacao_producao}
        onTrue={
          <Row justify="end" className="mt-4">
            <Button onClick={handleCreateSimulator}>
              <Text color="white">Salvar</Text>
            </Button>
          </Row>
        }
      />
    </AuthenticatedLayout>
  );
}

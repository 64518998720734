import { CalculatePackagingSupplierDailyTotalProps } from "../types";

export function calculatePackagingSupplierDailyTotal({
  composition,
  day,
  packagingSupplierCellValues,
  currentInventory,
  realCollection,
}: CalculatePackagingSupplierDailyTotalProps) {
  const realCollectionTotal = realCollection
    ?.filter(
      (packagingSupplierCellValue) =>
        packagingSupplierCellValue.produto == composition &&
        packagingSupplierCellValue.dia === day
    )
    .reduce((acc, curr) => acc + Number(curr.total), 0);

  if (realCollectionTotal) {
    return { total: realCollectionTotal + currentInventory };
  }

  const total = packagingSupplierCellValues
    .filter(
      (packagingSupplierCellValue) =>
        packagingSupplierCellValue.composicao == composition &&
        packagingSupplierCellValue.dia === day
    )
    .reduce((acc, curr) => acc + Number(curr.valor), 0);

  return { total: total + currentInventory };
}

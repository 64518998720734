import LoginCard from "@/components/Card/LoginCard";
import DefaultLayout from "@/components/Layout/DefaultLayout";

export default function Login() {
  return (
    <DefaultLayout>
      <div className="flex w-full h-full items-center justify-center">
        <LoginCard />
      </div>
    </DefaultLayout>
  );
}

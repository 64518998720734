import React from "react";
import { PackagingSupplierTableProps } from "../..";
import EditDropdownItem from "@/components/DropdownItem/EditDropdownItem";
import DeleteDropdownItem from "@/components/DropdownItem/DeleteDropdownItem";
import { usePackagingSupplierContext } from "../../context";

type ActionDropdownProps = PackagingSupplierTableProps;

export default function ActionDropdown(props: ActionDropdownProps) {
  const { handleDialog, handleDialogType, handlePackagingSupplier } =
    usePackagingSupplierContext();

  function handleEdit() {
    handleDialog();
    handleDialogType("edit");
    handlePackagingSupplier({ ...props });
  }

  function handleDelete() {
    handleDialog();
    handleDialogType("delete");
    handlePackagingSupplier({ ...props });
  }

  return (
    <React.Fragment>
      <EditDropdownItem onClick={handleEdit} />
      <DeleteDropdownItem onClick={handleDelete} />
    </React.Fragment>
  );
}

import Row from "@/components/Row";
import Text from "@/components/Text/Text";

export default function SimulationError() {
  return (
    <Row justify="center">
      <Row justify="center" align="center">
        <Text className="text-2xl font-bold">
          Não foi possível carregar a simulação.
        </Text>
      </Row>
    </Row>
  );
}

import { Dialog, DialogContent } from "@/components/ui/dialog";
import { diffArrays } from "@/utils/diff";
import { useProductionPlanContext } from "../../context";
import { VersionDropdownProps } from "../Dropdown/VersionDropdown";
import DiffViewer from "../Viewer/DiffViewer";

type CompareVersionDialogProps = {
  lineValues: VersionDropdownProps["valores_linhas"];
  open: boolean;
  onOpenChange: () => void;
};

export default function CompareVersionDialog({
  onOpenChange,
  open,
  lineValues,
}: CompareVersionDialogProps) {
  const {
    productionPlan: { valores_linhas },
  } = useProductionPlanContext();
  const arrayDiff = diffArrays(lineValues, JSON.parse(valores_linhas || "[]"));

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="overflow-y-scroll max-h-full">
        <DiffViewer diffs={arrayDiff} />
      </DialogContent>
    </Dialog>
  );
}

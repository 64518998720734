import Row from "@/components/Row";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { TEXT_COLOR } from "@/constants";
import { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { usePackagingSimulationContext } from "../../context";
import { quantityFormater } from "@/utils/number";
import Can from "@/components/Can";

export type UpdateCellValueProps = {
  day: string;
  skPackagingSupplier: number;
  composition: string;
};

export default function UpdateCellValue({
  day,
  composition,
  skPackagingSupplier,
}: UpdateCellValueProps) {
  const {
    icons: { visible: visibleIcons },
    packagingSimulationData: {
      simulacao_producao: { FK_fabrica, SK_simulacao },
    },
    packagingSupplier: {
      handlePackagingSupplierCellValue,
      getPackagingSupplierCellValue,
    },
  } = usePackagingSimulationContext();
  const [cellValue, setCellValue] = useState<string>();
  const [visible, setVisible] = useState<boolean>(false);

  function handlePopover() {
    setVisible((prev) => !prev);
    if (!cellValue) return;
    handlePackagingSupplierCellValue({
      dia: day,
      composicao: composition,
      valor: cellValue,
      FK_fabrica,
      FK_simulacao_producao: SK_simulacao,
      FK_fornecedor_embalagem: skPackagingSupplier,
    });
    setCellValue(undefined);
  }

  const value = getPackagingSupplierCellValue({
    dia: day,
    composicao: composition,
    FK_fabrica,
    FK_simulacao_producao: SK_simulacao,
    FK_fornecedor_embalagem: skPackagingSupplier,
  });

  if (!skPackagingSupplier) return null;
  return (
    <div className="w-full h-full flex justify-between items-center gap-2">
      <div className="overflow-x-hidden text-ellipsis">
        {quantityFormater(Number(value))}
      </div>
      <Can
        condition={!visibleIcons}
        onTrue={
          <Popover onOpenChange={setVisible} open={visible}>
            <PopoverTrigger>
              <FaEdit size={16} color={TEXT_COLOR} />
            </PopoverTrigger>
            <PopoverContent side="right">
              <Row className="gap-2">
                <Input
                  defaultValue={Number(value)}
                  type="number"
                  onChange={(e) => {
                    setCellValue(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") handlePopover();
                  }}
                />
              </Row>
            </PopoverContent>
          </Popover>
        }
      />
    </div>
  );
}

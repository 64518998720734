import Col from "@/components/Col";
import Each from "@/components/Each";
import Row from "@/components/Row";
import FactorySelect from "@/components/Select/FactorySelect";
import Text from "@/components/Text/Text";
import { Separator } from "@/components/ui/separator";
import SalesText from "../../Text/SalesText";
import {
  FaArrowDownLong,
  FaArrowUpLong,
  FaCalendarDays,
} from "react-icons/fa6";
import { EMPTY_VALUE, TEXT_COLOR } from "@/constants";
import dayjs from "dayjs";
import { MdInventory, MdOutlineSsidChart } from "react-icons/md";
import { quantityFormater } from "@/utils/number";
import useProductStockPolicy from "@/hooks/useProductStockPolicy";
import { useEffect, useState } from "react";
import Can from "@/components/Can";
import ProductInventoryPolicyForm from "../../Form/ProductInventoryPolicyForm";
import { useForm } from "react-hook-form";
import {
  ProductInventoryPolicyFormType,
  productInventoryPolicySchema,
} from "../../Form/zod";
import { zodResolver } from "@hookform/resolvers/zod";
import InventoryParameterPopover from "../../Popover/InventoryParameterPopover";
import { Button } from "@/components/ui/button";
import { calculateInventoryPolicy } from "@/pages/Products/utils/stockPolicy";
import useProductStockPatchMutation from "@/hooks/useProductStockPatchMutation";
import { toast } from "sonner";
import { validationError } from "@/utils/api";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { FaMapMarkerAlt } from "react-icons/fa";
import ProductTitleText from "../../Text/ProductTitleText";
dayjs.extend(customParseFormat);

type NewInventoryPolicyContentProps = {
  onClose: () => void;
  product: {
    code: string;
    description: string;
  };
};

function formateDate(value?: string) {
  if (!value) return "";
  return dayjs(value, "YYYYMMDD").format("DD/MM/YYYY");
}

export default function NewInventoryPolicyContent({
  product,
  onClose,
}: NewInventoryPolicyContentProps) {
  const { mutateAsync, isPending } = useProductStockPatchMutation();
  const [factoryKey, setFactoryKey] = useState<number>();
  const { data } = useProductStockPolicy({
    id: product.code,
    factoryKey: factoryKey,
  });
  const { data: productStockPolicy } = data || {};
  const {
    // inventario = [],
    inventario_atual = [],
    periodo_final,
    periodo_inicial,
    maior_venda = 0,
    media_venda = 0,
    menor_venda = 0,
    politica_estoque: {
      estoque_maximo,
      estoque_minimo,
      estoque_reabastecimento,
    } = { estoque_maximo: 0, estoque_minimo: 0, estoque_reabastecimento: 0 },
  } = productStockPolicy || {};

  const form = useForm<ProductInventoryPolicyFormType>({
    reValidateMode: "onChange",
    resolver: zodResolver(productInventoryPolicySchema),
    mode: "onChange",
  });
  const formatedPeriodInitial = dayjs(periodo_inicial, "YYYYMMDD").format(
    "DD/MM/YYYY"
  );
  const formatedPeriodFinal = dayjs(periodo_final, "YYYYMMDD").format(
    "DD/MM/YYYY"
  );

  function handleSuggestion({
    leadTime,
    replacementTime,
  }: {
    leadTime: number | string;
    replacementTime: number | string;
  }) {
    const { maximumStock, minimumStock, ressuplyStock } =
      calculateInventoryPolicy({
        averageDemand: Number(media_venda),
        leadTime: Number(leadTime),
        replacementTime: Number(replacementTime),
      });

    form.setValue("max", String(maximumStock));
    form.setValue("min", String(minimumStock));
    form.setValue("refill", String(ressuplyStock));
  }

  async function handleSubmit(data: ProductInventoryPolicyFormType) {
    if (isPending) return;
    try {
      await mutateAsync({
        FK_produto: product.code,
        FK_fabrica: String(factoryKey),
        max: data.max,
        min: data.min,
        refill: data.refill,
      });
      toast.success("Política de Estoque salva com sucesso!");
      onClose();
    } catch (error) {
      validationError(error);
    }
  }

  useEffect(() => {
    if (!data) return;
    if (
      estoque_maximo == EMPTY_VALUE ||
      estoque_minimo == EMPTY_VALUE ||
      estoque_reabastecimento == EMPTY_VALUE
    )
      return;
    form.reset({
      max: String(estoque_maximo),
      min: String(estoque_minimo),
      refill: String(estoque_reabastecimento),
    });

    return () => {
      form.reset({ max: "", min: "", refill: "" });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <div className="w-full h-full">
        <ProductTitleText product={product} />
        <Row align="center" className="w-full px-2 mt-2">
          <Col className="w-1/6 mb-4 justify-center ali">
            <Text>Fábrica</Text>
            <FactorySelect
              onValueChange={(e) => {
                setFactoryKey(Number(e));
              }}
            />
          </Col>
        </Row>
        <Separator />
        <Can
          condition={!!data}
          onTrue={
            <Col className="items-center gap-6">
              <Row justify="center">
                <Col className="w-fit mt-4 gap-6">
                  <Each
                    array={inventario_atual}
                    renderItem={({
                      quantidade = "0",
                      dia,
                      localizacao_nome,
                      localizacao_codigo,
                    }) => {
                      return (
                        <Row justify="center" className="gap-4">
                          <SalesText
                            icon={
                              <FaCalendarDays size={18} color={TEXT_COLOR} />
                            }
                            label="Data"
                            value={formateDate(dia)}
                          />
                          <SalesText
                            icon={<MdInventory size={18} color={TEXT_COLOR} />}
                            label="Inventário Atual"
                            value={quantityFormater(Number(quantidade))}
                          />
                          <SalesText
                            icon={
                              <FaMapMarkerAlt size={18} color={TEXT_COLOR} />
                            }
                            label="Localização"
                            value={
                              localizacao_codigo + " - " + localizacao_nome
                            }
                          />
                        </Row>
                      );
                    }}
                  />
                  {/* <Each
                    array={inventario}
                    renderItem={({
                      vigencia,
                      abreviatura_empresa,
                      quantidade,
                    }) => {
                      return (
                        <Row justify="center" className="gap-4 mt-4">
                          <SalesText
                            label="Data da Última Carga de Inventário"
                            icon={
                              <FaCalendarDays size={18} color={TEXT_COLOR} />
                            }
                            value={dayjs(vigencia, "YYYY-MM-DD").format(
                              "DD/MM/YYYY"
                            )}
                          />
                          <SalesText
                            label="Inventário da Carga"
                            icon={<MdInventory size={18} color={TEXT_COLOR} />}
                            value={quantityFormater(Number(quantidade))}
                          />
                          <SalesText
                            label="Fábrica"
                            icon={<MdFactory size={18} color={TEXT_COLOR} />}
                            value={abreviatura_empresa}
                          />
                        </Row>
                      );
                    }}
                  /> */}
                  <Row justify="center" className="gap-6">
                    <SalesText
                      label="Período Inicial"
                      icon={<FaCalendarDays size={18} color={TEXT_COLOR} />}
                      value={formatedPeriodInitial}
                    />
                    <SalesText
                      label="Período Final"
                      icon={<FaCalendarDays size={18} color={TEXT_COLOR} />}
                      value={formatedPeriodFinal}
                    />
                  </Row>
                  <Row justify="center" className="gap-6">
                    <SalesText
                      label="Venda Média"
                      icon={<MdOutlineSsidChart size={20} color={TEXT_COLOR} />}
                      value={quantityFormater(Number(media_venda))}
                    />
                    <SalesText
                      label="Maior Venda"
                      icon={<FaArrowUpLong size={20} color={TEXT_COLOR} />}
                      value={quantityFormater(Number(maior_venda))}
                    />
                    <SalesText
                      label="Menor Venda"
                      icon={<FaArrowDownLong size={20} color={TEXT_COLOR} />}
                      value={quantityFormater(Number(menor_venda))}
                    />
                  </Row>
                </Col>
              </Row>
              <Separator />
              <Row className="p-4 gap-4" justify="center">
                <ProductInventoryPolicyForm
                  form={form}
                  onSubmit={handleSubmit}
                />
                <Col className="justify-end">
                  <InventoryParameterPopover onGenerate={handleSuggestion} />
                </Col>
              </Row>
            </Col>
          }
        />
      </div>
      <Row justify="end" align="end" className="gap-4 mr-4">
        <Button variant="ghost" onClick={onClose}>
          Cancelar
        </Button>
        <Button
          type="submit"
          onClick={form.handleSubmit(handleSubmit)}
          disabled={false}
        >
          Salvar
        </Button>
      </Row>
    </>
  );
}

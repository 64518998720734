import AuthenticatedLayout from "@/components/Layout/AuthenticatedLayout";
import Text from "@/components/Text/Text";

export default function Initial() {
  return (
    <AuthenticatedLayout>
      <Text>Inicio</Text>
    </AuthenticatedLayout>
  );
}

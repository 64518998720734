import instance from "@/api/business";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { productionPlanKeys } from "./useProductionPlanMutation";

export function useProductionPlanDeleteMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id }: { id: number }) => {
      return instance.delete("/planningbeer/productionPlan", {
        data: { id },
      });
    },

    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: productionPlanKeys.all,
      });
    },
  });
}

import Col from "@/components/Col";
import Each from "@/components/Each";
import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Checkbox } from "@/components/ui/checkbox";
import { Separator } from "@/components/ui/separator";
import { PackagingType } from "@/hooks/usePackagings";
import { CornerDownRight } from "lucide-react";
import { SelectedItemsType } from "../..";

type PackagingItemProps = {
  position: "left" | "right";
  selectedItems: Array<string>;
  onSelect: ({ SK_embalagem, position }: SelectedItemsType) => void;
} & PackagingType;

export default function PackagingItem({
  onSelect,
  ...props
}: PackagingItemProps) {
  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="items">
        <Row align="center" justify="space-between">
          <Col className="w-full justify-center">
            <AccordionTrigger className="mr-3">
              <Row>
                <Text className="text-sm font-bold mt-3 ml-3">
                  {props.composicao}
                  {" - "}
                  {props.descricao}
                </Text>
              </Row>
            </AccordionTrigger>
            <AccordionContent>
              <Col className="w-full h-full">
                <Each
                  array={props.produtos}
                  renderItem={({
                    produto,
                    descricao,
                    SK_embalagem,
                    filial,
                  }) => (
                    <div>
                      <Row className="ml-8 py-4" align="center">
                        <Checkbox
                          checked={props.selectedItems.includes(SK_embalagem)}
                          className="mr-4"
                          onClick={() =>
                            onSelect({ position: props.position, SK_embalagem })
                          }
                        />
                        <CornerDownRight
                          className="text-neutral-500 mr-4"
                          size={12}
                        />
                        <Text className="mt-1">
                          {produto} - {descricao}
                        </Text>
                        <Text className="mt-1 text-sm font-bold">
                          , Filial: {filial}
                        </Text>
                      </Row>
                      <Separator />
                    </div>
                  )}
                />
              </Col>
            </AccordionContent>
          </Col>
        </Row>
      </AccordionItem>
      <Separator />
    </Accordion>
  );
}

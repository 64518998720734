import {
  GetRealConsumptionAccumulatedProps,
  GetRealConsumptionRowProps,
} from "../types";

export function getRealConsumption({
  composition,
  day,
  realConsumption,
}: GetRealConsumptionRowProps) {
  const realConsumptionComposition = realConsumption?.filter(
    (consumption) => consumption.componente == composition
  );

  if (!realConsumptionComposition) {
    return { realConsumptionValue: 0 };
  }

  const realConsumptionDay = realConsumptionComposition.filter(
    ({ dia }) => dia == day
  );

  const realConsumptionValue = realConsumptionDay.reduce(
    (acc, cur) => acc + Number(cur.quantidade),
    0
  );

  return { realConsumptionValue: realConsumptionValue };
}

export function getRealConsumptionAccumulated({
  composition,
  day,
  realConsumptionAccumulated,
}: GetRealConsumptionAccumulatedProps) {
  const realConsumptionAccumulatedComposition =
    realConsumptionAccumulated[composition];

  if (!realConsumptionAccumulatedComposition) {
    return { realConsumptionAccumulatedValue: 0 };
  }

  const realConsumptionAccumulatedDay =
    realConsumptionAccumulatedComposition.dias[day];

  return { realConsumptionAccumulatedValue: realConsumptionAccumulatedDay };
}

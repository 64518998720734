import instance from "@/api/business";
import { useQuery } from "@tanstack/react-query";
import { ApiResponse } from "./useEstimates";

export type PackagingProductType = {
  SK_embalagem: string;
  ativo: number;
  descricao: string;
  filial: string;
  produto: string;
};

export type PackagingType = {
  composicao: string;
  descricao: string;
  produtos: Array<PackagingProductType>;
};

export const packagingsKey = {
  all: ["packagings"] as const,
};

async function fetchPackagings() {
  const response = await instance.post<ApiResponse<PackagingType>>(
    "/planningbeer/packaging"
  );

  return response.data;
}

export default function usePackagings() {
  return useQuery({
    queryKey: packagingsKey.all,
    queryFn: () => fetchPackagings(),
  });
}

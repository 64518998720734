import { createContext, useContext, useState } from "react";
import { ReactChildrenType } from "@/types";
import {
  PackagingSimulationResponseType,
  PackagingSupplierCellValueType,
} from "@/hooks/usePackagingSimulationMutation";
import { usePackagingSimulation } from "../hooks/usePackagingSimulation";
import {
  GetProgrammedCollectionCellValueProps,
  usePackagingSupplier,
} from "../hooks/usePackagingSupplier";

type PackagingSupplierProps = {
  packagingSupplierCellValue: Array<PackagingSupplierCellValueType>;
  getPackagingSupplierCellValue: (
    cell: Omit<PackagingSupplierCellValueType, "valor">
  ) => string;
  getProgrammedCollectionCellValue: (
    cell: GetProgrammedCollectionCellValueProps
  ) => number;
  handlePackagingSupplierCellValue: (
    cell: PackagingSupplierCellValueType | PackagingSupplierCellValueType[]
  ) => void;
};

type PackagingSimulationProps = {
  packagingSimulationData: PackagingSimulationResponseType;
  handlePackagingSimulationData: (
    data: PackagingSimulationResponseType
  ) => void;
  clear: () => void;
  icons: {
    visible: boolean;
    handleVisible: () => void;
  };
};

type PackagingContextProps = {
  packagingSupplier: PackagingSupplierProps;
} & PackagingSimulationProps;

const PackagingSimulationContext = createContext({} as PackagingContextProps);

export default function PackagingSimulationProvider({
  children,
}: ReactChildrenType) {
  const [visible, setVisible] = useState(false);
  const {
    packagingSimulationData,
    handlePackagingSimulationData,
    clear: clearPackagingSimulation,
  } = usePackagingSimulation();

  const {
    packagingSupplierCellValue,
    handlePackagingSupplierCellValue,
    getPackagingSupplierCellValue,
    getProgrammedCollectionCellValue,
    clear: clearPackagingSupplier,
  } = usePackagingSupplier();

  function clear() {
    clearPackagingSimulation();
    clearPackagingSupplier();
  }

  function handleVisible() {
    setVisible((prevState) => !prevState);
  }

  return (
    <PackagingSimulationContext.Provider
      value={{
        icons: {
          handleVisible,
          visible,
        },
        packagingSimulationData,
        handlePackagingSimulationData,
        clear,
        packagingSupplier: {
          packagingSupplierCellValue,
          handlePackagingSupplierCellValue,
          getPackagingSupplierCellValue,
          getProgrammedCollectionCellValue,
        },
      }}
    >
      {children}
    </PackagingSimulationContext.Provider>
  );
}

export function usePackagingSimulationContext() {
  return useContext(PackagingSimulationContext);
}

import Text from "@/components/Text/Text";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { TEXT_COLOR } from "@/constants";
import { ArrowRightIcon, EyeIcon } from "lucide-react";
import EditEstimatePopoverContent from "../Content/EditEstimatePopoverContent";
import Each from "@/components/Each";

type EditEstimatePopoverProps = {
  oldValues: Array<number | string>;
  format?: (value: number | string) => string;
  currentValue: number | string;
};

type OrganizedValuesType = {
  from: number | string;
  to: number | string;
};

export default function EditEstimatePopover({
  oldValues,
  currentValue,
  format,
}: EditEstimatePopoverProps) {
  if (!oldValues) return;

  function organizeValues(array) {
    const organizedValuesArray: Array<OrganizedValuesType> = [];

    for (let i = 0; i < array.length; i++) {
      if (i == array.length - 1) {
        organizedValuesArray.push({
          from: array[i],
          to: currentValue,
        });
      } else {
        organizedValuesArray.push({
          from: array[i],
          to: array[i + 1],
        });
      }
    }
    return organizedValuesArray;
  }
  const organizedValues = organizeValues(oldValues);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <EyeIcon size={14} color={TEXT_COLOR} />
      </PopoverTrigger>
      <PopoverContent className="w-fit p-0">
        <div className="flex flex-col">
          <div className="flex gap-5 w-full justify-between p-2 items-center">
            <Text className="text-sm ml-2">De</Text>
            <ArrowRightIcon size={14} color={TEXT_COLOR} />
            <Text className="text-sm mr-2">Para</Text>
          </div>
          <Separator className="mb-2" />
          <Each
            array={organizedValues}
            renderItem={({ from, to }) => (
              <EditEstimatePopoverContent
                previousValue={format?.(from) || from}
                currentValue={format?.(to) || to}
              />
            )}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
}

import DeleteDropdownItem from "@/components/DropdownItem/DeleteDropdownItem";
import React from "react";
import { useResourceContext } from "../../context";
import { ResourcesType } from "@/hooks/useResources";
import EditDropdownItem from "@/components/DropdownItem/EditDropdownItem";

export default function ActionDropdown(props: ResourcesType) {
  const { handleDialogType, handleResource, handleDialog } =
    useResourceContext();

  function handleDelete() {
    handleDialog();
    handleDialogType("delete");
    handleResource({ ...props });
  }

  function handleEdit() {
    handleDialog();
    handleDialogType("edit");
    handleResource({ ...props });
  }

  return (
    <React.Fragment>
      <EditDropdownItem onClick={handleEdit} />
      <DeleteDropdownItem onClick={handleDelete} />
    </React.Fragment>
  );
}

import instance from "@/api/business";
import { useMutation } from "@tanstack/react-query";
import { ApiObjectResponse } from "./useEstimates";

type ProgrammedRealProductionMutationData = {
  initialPeriod: string;
  finalPeriod: string;
  fkFactory: number;
  fkProductionPlan: number;
  fkProductionPlanOld: string[];
};

export type Products = {
  produto: string;
  descricao: string;
  volume: string;
  plano_producao: {
    versao: string;
    quantidade: number;
    quantidade_hectolitros: number;
  }[];
  producao_real: {
    quantidade: number;
    quantidade_hectolitros: number;
  };
};

export type TotalByVersion = {
  [version: string]: {
    quantidade: number;
    quantidade_hectolitros: number;
  };
};

export type TotalRealProduction = {
  quantidade: number;
  quantidade_hectolitros: number;
};

export type ProgrammedRealProductionMutationResponse = {
  produtos: Products[];
  total_por_versao: TotalByVersion;
  total_producao_real: TotalRealProduction;
};

export function useProgrammedRealProductionMutation() {
  return useMutation({
    mutationFn: async (data: ProgrammedRealProductionMutationData) => {
      const response = await instance.post<
        ApiObjectResponse<ProgrammedRealProductionMutationResponse>
      >("/planningbeer/productionPlan/projection", { ...data });

      return response.data;
    },
  });
}

import Each from "@/components/Each";
import Row from "@/components/Row";
import Text from "@/components/Text/Text";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import ForecastStatusCell from "@/pages/Estimativas/components/Cell/StatusCell";
import dayjs from "dayjs";
import { useAlertDialogContext } from "../../context/AlertDialogContext";
import useEstimateStatusLog, {
  EstimateStatusLogType,
} from "@/hooks/useEstimateStatusLog";
import { QueryClient } from "@tanstack/react-query";
import { estimates } from "@/hooks/useEstimates";
import Can from "@/components/Can";

export default function StatusDialog() {
  const queryClient = new QueryClient();
  const { handleDialogVisibility, clear, visibleDialog, sheet } =
    useAlertDialogContext();
  const { data, isFetching } = useEstimateStatusLog({
    id: sheet.SK_planejamento_demanda,
  });

  if (sheet.tipo !== "status" || isFetching) return null;

  const { data: statusLog = [] } = data || {};
  const {
    criado_por_usuario_codigo: createdByUserCode,
    criado_por_usuario_nome: createdByUserName,
    data_da_carga: chargeDate,
  } = statusLog[0] ?? {};

  function handleDialog() {
    handleDialogVisibility();
    clear();
    queryClient.invalidateQueries({
      predicate(query) {
        return (
          query.queryKey[0] === estimates.log(sheet.SK_planejamento_demanda)
        );
      },
    });
  }

  function statusRow({
    status,
    nome_usuario,
    created_at,
    codigo_usuario,
  }: Pick<
    EstimateStatusLogType,
    "status" | "nome_usuario" | "created_at" | "codigo_usuario"
  >) {
    return (
      <Row align="start">
        <div className="w-[100px] flex flex-col justify-center items-center">
          <div className="mt-1">
            <Text>
              <ForecastStatusCell status={status} />
            </Text>
          </div>
          <Can
            condition={status !== "RASCUNHO"}
            onTrue={
              <svg
                width="100"
                height="50"
                color="#909090"
                className="mt-2 mb-2 opacity-50"
              >
                <line x1="50" y1="0" x2="50" y2="100" stroke="#909090" />
              </svg>
            }
          />
        </div>
        <div className="ml-10">
          <Text>Nome: {nome_usuario}</Text>
          <div className="mt-1" />
          <Text>Data: {dayjs(created_at).format("DD/MM/YYYY HH:mm:ss")}</Text>
          <div className="mt-1" />
          <Text>Código: {codigo_usuario}</Text>
        </div>
      </Row>
    );
  }

  return (
    <Dialog open={visibleDialog} onOpenChange={handleDialog}>
      <DialogContent className="px-0">
        <Row className="w-full border border-r-0 border-t-0 border-l-0 border-b-0">
          <div className="px-4 mb-2">
            <Text className="text-lg mb-4 font-bold">
              {sheet.SK_planejamento_demanda}
            </Text>
            <Text>Nome da Carga: {sheet.nome_planejamento}</Text>
            <div className="mt-2" />
            <Text>Enviado por: {sheet.nome_usuario}</Text>
            <div className="mt-2" />
            <Text>
              Data da Carga:
              {dayjs(sheet.created_at).format("DD/MM/YYYY HH:mm:ss")}
            </Text>
            <div className="mt-2" />
            <Text>Status: {sheet.status}</Text>
          </div>
        </Row>
        <div className="max-h-[500px] h-min-[300px] overflow-scroll overflow-x-hidden px-4">
          <div className="flex flex-col items-center ml-10">
            <div className="mt-4">
              <Each array={statusLog} renderItem={statusRow} />
              {statusRow({
                codigo_usuario: createdByUserCode,
                nome_usuario: createdByUserName,
                created_at: chargeDate,
                status: "RASCUNHO",
              })}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
